.ohs-main-layout {
    height: 80vh;
    padding: 0px 5vh;
}

.ohs-top-layout {
    height: 45vh;
    display: flex;
    padding: 5px 0;
}

.ohs-bottom-layout {
    height: 35vh;
}

.ohs-box-layout {
    height: 100%;
    
    flex: 1;
    margin: 0px 1%;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 5px;
    font-size: 12px;
    background-color: #fff;
    position: relative;
}

.ohs-box-header {
    height: 40px;
    background-color:rgb(218, 218, 218);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
}

.ohs-table-NewsListDiv{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.ohs-table-NewsSingleDiv{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 1% 0;
    border-bottom: 1px solid rgb(199, 198, 198);
}


.ohs-table-NewsSingleDiv:hover{
    background-color: whitesmoke;
}

.ohs-table-NewsSingleDiv > p{
    font-weight: 600;
    font-size: .9rem;
    padding: 0 1%;
}
.ohs-table-NewsSingleDiv > span{
    overflow: hidden;
    word-wrap: break-word;
    width: 90%;
    padding: 0 1%;
    text-overflow: ellipsis;
    margin: 1% 0;
}

.ohs-table-NewsSingleDiv > aside{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}
.ohs-table-head {
    font-weight: bold;
    color: var(--cl_blue);
}

.ohs-table-user-field {
    display: flex;
    align-items: center;
    justify-content: left;
}

.ohs-table-action-btn {
    border: 1px solid gray;
    background-color: #fff;
    padding: 3px 10px;
    font-size: 11px;
    border-radius: 20px;
    cursor: pointer;
}

.ohs-table-action-btn-read{
    border: 1px solid #fff;
    background-color: gray;
    padding: 3px 10px;
    font-size: 11px;
    border-radius: 20px;
    cursor: default;
    color: white;
}

.ohs-pagination-layout {
    display: flex;
}

.ohs-box-SearchDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    border-radius: 20px;
    background-color: white;
    /* height: 3vh; */
    border: none;
}

.ohs-box-SearchDiv input{
    border: none;
    background-color: transparent;
    width: 90%;
}

.ohs-pagi-a {
    background-color: rgb(180, 180, 180);
    border: 1px solid rgb(180, 180, 180);
    height: 18px;
    width: 18px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.ohs-pagi-b {
    border: 1px solid gray;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
    font-size: 12px;
    cursor: default;
}

.ohs-pagi-add-btn {
    border: 0;
    width: 60px;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 11px;
    cursor: pointer;
}

.ohs-pagi-add-btn:hover {
    background-color: grey;
    color: white;
}


.ohs-safety-top-btns {
    margin: 10px 0px; 
    display: flex;
    padding: 0px 20px;
    justify-content: flex-end;
}

.ohs-safety-top-btns-tab, .ohs-safety-top-btns-tabActive{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 100px;
    background-color: white;
    border: 1px solid rgba(0, 174, 255, 0.288);
    margin: 0px 10px;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
}

.ohs-safety-top-btns-tabActive{
    background: rgba(0, 174, 255, 0.288);
    pointer-events: none;
}


.ohs-safety-top-btns button:hover {
    background: rgba(0, 174, 255, 0.555);
}

.ohs-saftey-body-layout {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    font-size: 12px;
    line-height: 30px;
    width: 20vw;
    align-items: flex-start;
    justify-content: space-around;
    margin: auto;
}

.ohs-safety-tab{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
}

.ohs-safety-tabHead{
    font-weight: 600;
    width: 40%;
}

.ohs-safety-tabContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 22vw;
}

.ohs-safetyData-contentCols{
    display: flex;
    flex-direction: row;
    width: 50%;
}

.ohs-safety-tabRow{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 22vw;
}

.ohs-safety-left{
    width: 40%;
}

.ohs-safety-right{
    width: 50%;
    display: flex;
    justify-content: center;
}

.safetyDataInp{
    background-color: transparent;
    border:none;
    border-bottom: 1px solid black;
}

.notifyCommentReply{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 1%;
}

.notifyCommentReply > input{
    margin-top: 0;
    width: 90%;
}


.notifyCommentReply > svg{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--cl_blue);
    color:white;
    fill:white;
    border-radius: 50%;
    padding: 1%;
}

.notificationCommentsDiv{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background-color: rgb(181, 239, 241);
    margin: 1%;
    padding: 1%;
    border-radius: 10px;
    width: 80%;
}

.notificationCommentsDiv > p{
    font-weight: 600;
}