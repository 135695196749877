/* .main-layout {
    width: 92%;
    height: 100vh;
    background-color: var(--body_bgc);
    border-radius: 45px 0px 0px 45px;
  } */
.client-photo-header{
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 6vh;
    padding-right: 6vh;   
}
.client-photo-bottom{
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.client-photo-bottom-left{
    width: 40%;
    height: 90%;
    background-color: white;
    border: solid rgb(204, 204, 204) 1px;
}
.client-photo-table{
    height:max-content;
    width: 100%;
    border: none;
    color: gray;
}
.client-photo-table-head{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
}
.head1{
    width: 25%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.head2{
    width: 25%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.head3{
    width: 25%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.head4{
    width: 25%;
    height: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.head5{
    width: 25%;
    height: 30px;
    height: 30px;
    display: flex;
}
.img-responsive{
    width: 30%;
    height: 100%;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-left: 15%;
}
.name-responsive{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
}
.client-photo-table-body{
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
}
.client-photo-bottom-right{
    width: 45%;
    height: 80%;
}
.client-photo-bottom-right-top{
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.client-photo-bottom-right-top1{
    width: 90%;
    height: 90%;
    background-color:rgb(180, 180, 180);
}
.client-photo-bottom-right-bottom{
    width: 60%;
    height: 20%;
    margin-left: 20%;
    display: flex;
    align-items: center;
    justify-content: space-around;
   
}
.client-photo-bottom-right-bottom1{
    width:30%;
    height: 90%;
    background-color:rgb(180, 180, 180);
}
.client-photo-bottom-right-bottom1:hover{
  border: solid 2px rgb(0, 117, 185);
}