.home-main-layout {
  max-height: 82vh;
  display: flex;
  flex-direction: column;
  padding: 0px 2vw;
  overflow-y: auto;
  
}

.skeltonLoaderTable{
    background: linear-gradient(to right,
        rgba(255, 255, 255, 0),
        rgba(0, 0, 0, 0.1) 50%,
        rgba(255, 255, 255, 0) 80%
        ),
        rgb(238, 238, 236);
    background-repeat: repeat-y;
    background-size: 150px;
    background-position: 0 0;
    animation: shineTable 2s linear infinite;
    background-color: var(--cl_ivery);
    border-radius: 10px;
}

@keyframes shineTable {
    to{
        background-position: 100% 0;
    }
}

.home-box-layout {
    flex: 2;
    display: flex;
}

.home-box-component {
    flex: 3;
    border: 1px solid grey;
    margin: 15px;
    background-color: #fff;
    border-radius: 10px;
    padding-bottom: 20px;
    overflow: hidden;
}

.home-box-header {
    height: 30px;
    background-color: rgb(216, 213, 213);
    font-size: 14px;
    font-weight: 600;
    color: rgb(53, 53, 53);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    justify-content: space-between;
}

.home-table-head {
    font-size: 12px;
    font-weight: bold;
    color: var(--cl_blue);
    border: 1.5px solid lightgrey;
    background-color: white;
}

.home-box-schedule-main {
    display: flex;
    height: 92%;
    flex-direction: column;
    padding: 5px;
}

.home-box-schedule-row {
    display: grid;
    height: 100%;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    overflow-x: auto;
}

.home-schedule-single-comp {
    flex: 2;
    margin: 1%;
    background-color: rgba(224, 241, 70, 0.877);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2% 5px;
    border-radius: 10px;
    font-size: 12px;
    line-height: 20px;
    cursor: pointer;
    min-height: 90%;
    flex-wrap: wrap;
    -webkit-animation: scale-in-hor-center 2s ease-in-out both;
	        animation: scale-in-hor-center 2s ease-in-out both;
}
@-webkit-keyframes scale-in-hor-center {
    0% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-hor-center {
    0% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      opacity: 1;
    }
  }
  
.home-schedule-single-comp-vec {
    flex: 2;
    margin: 1%;
    background-color: rgba(224, 241, 70, 0.877);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2% 5px;
    border-radius: 10px;
    font-size: 12px;
    line-height: 20px;
    cursor: pointer;
    min-height: 90%;
    flex-wrap: wrap;
    -webkit-animation: scale-in-ver-center 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-ver-center 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes scale-in-ver-center {
    0% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-ver-center {
    0% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      opacity: 1;
    }
  }
  

.home-schedule-single-comp-img{
    flex : 1 1 30%;
    max-width: 50%;
    border-radius: 10px;
}
.home-schedule-single-comp img {
    border-radius: 10px;
    min-width: 2vw;
    max-width: 5vw;
}

.home-schedule-single-comp > div{
    width: 30%;
    flex : 1 1 30%;
    margin-left: 2%;
    word-wrap: break-word;
    word-break: break-all;
}

.home-schedule-vecant {
    height: 70px;
    width: 70px;
    background-color: rgba(230, 230, 230, 0.795);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 10px;
    color: grey;
    flex : 1 1 30%;
}


.home-box-sales-graph-main {
    height:240px;
    padding: 10px;
    display: flex;
    font-size: 12px;
}

.home-graph-index {
    height: 100%;
    width: 50px;
    border-right: 1px solid gray;
}

.home-graph-index-body {
    height: 85%;
    display: flex;
    align-items: center;
}

.home-graph-index-base {
    height: 15%;
    border-top: 1px solid gray;
    margin-left: 20px;
}

.home-graph-bars-main {
    height: 100%;
    flex: 2;
}

.home-graph-bar-body {
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.home-graph-bar-line {
    width: 20px;
    background-color: orange;
    font-size: small;
    display: flex;
    align-items: center;
    justify-content: center;

    animation : bar-anime 2s both;
    -webkit-animation: bar-anime 2s both;
}

.home-graph-vertical-text {
    color: white;
    font-weight: 500;
    transform: rotate(-90deg);
    font-size: 11px;
}

.home-graph-bar-lineB {
    width: 20px;
    background-color: var(--cl_blue);
    font-size: small;
    display: flex;
    align-items: center;
    justify-content: center;

    animation : bar-anime 2s both;
    -webkit-animation: bar-anime 2s both;

}


@-webkit-keyframes bar-anime {
    0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  }
  @keyframes bar-anime {
    0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  }
  

.home-graph-bar-base {
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid grey;
}

.sales-graph-main-indicator {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
}
.sales-graph-indicatior-layout {
    display: flex;
    flex-direction: column;
}

.sales-graph-indicatior-actions {
    display: flex;
    align-items: center;
}

.sales-graph-indicatior {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.sales-weather-item-box {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.sales-weather-item-box > img{
    background: white;
}

/* 
@media only screen and (max-width:1200px)  {
    
    
    }
     */