.jobCardModalBgDiv{
    display: none; /* Hidden by default */
position: fixed; /* Stay in place */
z-index: 1; /* Sit on top */
padding-top: 1px; /* Location of the box */
left: 0;
top: 0;
width: 100%; /* Full width */
height: 100%; /* Full height */
overflow: auto; /* Enable scroll if needed */
background-color: rgb(0,0,0); /* Fallback color */
background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}


.jobCardModalBgDivClose{
    display: block;
}
.pumpJobCardLayout{
    outline: none;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 70vw;
    height: 80vh;
    margin: auto;
    font-size: 16px;
    -webkit-box-shadow: 0px 4px 26px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 4px 26px -2px rgba(0,0,0,0.75);
    box-shadow: 0px 4px 26px -2px rgba(0,0,0,0.75);
}
.jobCardContentDiv{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}   

.jobCardSelectTabDiv{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    height: 4vh;
    background-color: var(--cl_blue);
    color: white;
}

.jobCardSelectedTab{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--cl_blue);
    background-color: white;
    padding: 0 2%;
    height: 100%;
    border-bottom: 1px solid var(--cl_blue);
}
.jobCardDiv1{
    display: flex;
    flex-direction: column;
}

.jobCardDivHead{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4vh;
    background-color: var(--cl_blue);
    color: white;
    margin: 5px 0;
}

.jobCardInactiveTab{
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.jobCardsCheckBox {
    height: 8px;
    width: 8px;
    padding-left: 3px;
    padding-bottom: 3px;
    background-color: white;
    border: .5px solid grey;
    border-radius: 3px;
    margin-right: 4px;
  }
  
  /* On mouse-over, add a grey background color */
  .jobCardsCheckBox:hover {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .jobCardsCheckBoxChecked {
    background-color: red;
  }
  
  /* Create the jobCardsCheckBox/indicator (hidden when not checked) */
  .jobCardsCheckBox:after {
    content: "";
    display: none;
  }
  
  /* Show the jobCardsCheckBox when checked */
  .jobCardsCheckBox:after {
    display: block;
  }
  
  /* Style the jobCardsCheckBox/indicator */
  .jobCardsCheckBox:after {
    
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  

.jobCardTextAreaDiv{
    display: flex;
    flex-direction: column;
    height: 22vh;
    width: 100%;
    background-color: skyblue;
    margin: 10px 0;
    border-radius: 10px;
    padding: 5px;
}
.jobCardTextAreaDiv > div{
    padding: 5px 0;
}
.jobCardTextAreaDiv > div > div > textarea{
    outline: none;
    background-color: transparent;
    padding: .5em;
    border: none;
    font-family: inherit;
    resize: none;
    height: 90%;
    width: 93%;
    margin-left: 5%;
}


.jobCardDivInpContent{
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
}
.jobCardInputDivSec{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1px 2px;
}
.jobCardInputDivSec > div{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.jobCardInputDivSec > div > span{
    margin: 0 10px;
}
.jobCardDivInp{
    border: none;
    border-bottom: 1px solid black;
    width: 100%;
    height: 5vh;
}
.jobCardDivTableContent{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 10px;
}
.jobCardTableStyle{
    border: .5px solid var(--cl_blue);
}
.jobCardDivTHead{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.jobCardDivTHead > div{
    width: 20%;
}
.jobCardFooterContent{
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
    align-items: center;
}
.jobCardFooterContent > div{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.jobCardFooterContent > div > span{
    margin-right: 10px;
}

.jobCardTableWidth50, .jobCardTableWidth50 > tbody, .jobCardTableWidth50 > tbody > tr{
    width: 100%;
}

.jobCardTableWidth50 > tbody > th, .jobCardTableWidth50 > tbody > td{
    width: 50%;
    text-align: center;
}

.jobCardDiv3JSA{
    display: flex;
    flex-wrap: wrap;
}

.jobCardDiv3JSASubTab{
    display: flex;
    flex-direction: column;
}

.jobCardDiv3JSA > table, .jobCardDiv3JSASubTab{
    flex:50%;
}

.jobCardDiv3JSA > table > tbody, .jobCardDiv3JSA > table > tbody > tr , .jobCardDiv3JSA > table > tbody > tr > td,
.jobCardDiv3JSASubTab > table > tbody, .jobCardDiv3JSASubTab > table > tbody > tr , .jobCardDiv3JSASubTab > table > tbody > tr > td
{
    font-size: 14px;
    width: 100%;
}

.jobCardJSAIdentifyAdjust > div > div{
    width: 15%;
}

.riskAssesmentTableDivHead{
    width: 100%;
    height: 5vh;
}

.riskAssesmentTableDivHead > div{
    text-align: center;
    padding: 2px;
    width: 20%;
    margin: .5%;
}

#thDiv{
    border: 1px solid grey;
    font-size: 12px;
    word-break: break-all;
}

#riskRate1, .riskRate1{ text-align: center; background-color: red; color: white; }
#riskRate2, .riskRate2{ text-align: center; background-color: orange; color: white; }
#riskRate3, .riskRate3{ text-align: center; background-color: yellow; color: black; }
#riskRate4, .riskRate4{ text-align: center; background-color: rgb(247, 247, 131); color: black; }
#riskRate5, .riskRate5{ text-align: center; background-color: rgb(252, 252, 177); color: black; }
#riskRate6, .riskRate6{ text-align: center; background-color: white; color: black; }


.selectBoxHead{
    background-color: var(--cl_blue);
    color: white;
    align-items: center;
}
.selectBoxHead select{
    margin-bottom: 0;
    color: white;
}

.selectBoxHead select > option{
    color: black;
}
