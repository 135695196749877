:root {
  --cl_black: #000;
  --cl_white: #fff;
  --cl_grey: #707070;
  --cl_blue: #0f9ad1;
  --cl_green: #13CD6A;
  --cl_brown: #774835;
  --cl_red: #FF0000;
  --cl_magenta: #CD1396;
  --cl_orange: #FF8A23;
  --cl_dark_orange: #EF871F;
  --cl_light_grey: #F5F5F5;
  --cl_light_ivery: #F5F7F7;
  --cl_ivery: #E6E6E6;

  --sidebar_bgc: #2B2C30;
  --body_bgc: #F7F8FA;
}

.App {
  background-color: var(--sidebar_bgc);
  min-height: 100vh;
  width: 100vw;
  /* min-width: 1024px; */
  display: flex;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  position: relative;
}

.sidebar-layout {
  width: 8vw;
  height: 100vh;
  background-color: var(--sidebar_bgc);
  margin: 0 10px;
  
}

.sidebar-profile-layout {
  height: 12vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--cl_white);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}

.sidebar-profile-layoutName{
  padding: 0;
  margin: 0;
  word-break: break-all;
  word-wrap: break-word;
}

.sidebar-profile-img {
  height: 65px;
  width: 65px;
  border-radius: 10px;
  padding-bottom: 5px;
  object-fit: contain;
  border: .5px solid rgba(255, 255, 255, 0.5);
}

.sidebar-menu-layout {
  height: 10vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-menu {
  height: 8vh;
  width: 75%;
  background-color: var(--cl_grey);
  color: var(--cl_white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}

.sidebar-menu:hover {
  height: 8vh;
  width: 75%;
  background-color: var(--cl_white);
  color: var(--cl_black);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}

.sidebar-icon {
  font-size: 30px;
}

.sidebar-menu-active {
  height: 8vh;
  width: 75%;
  background-color: var(--cl_white);
  color: var(--cl_black);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}

.sidebar-menu-inactive {
  height: 8vh;
  width: 75%;
  background-color: var(--cl_grey);
  color: var(--cl_white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}

.sidebar-text {
  font-size: 12px;
  margin: 0;
  padding: 0;
  padding-top: 5px;
}

.main-layout {
  width: 99vw;
  height: 100vh;
  background-color: var(--body_bgc);
  border-radius: 45px 0px 0px 45px;
}

.common-header {
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 6vh;
  padding-right: 6vh;
}

.common-brand-layout {
  height: 9vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.common-brand-img {
 height: 50px;
 padding: 0;
 margin: 0;
 height: 100%;
}

.header-button-layout {
  display: flex;
}

.mobile-alert-layout {
  height: 100vh;
  background-image: linear-gradient(to right,rgb(163, 235, 245), rgb(70, 136, 167));
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}


.mobile-alert-box {
  height: 300px;
  background-color: rgba(255, 255, 255, 0.411);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
}

/* .common-back-btn-layout {
  height: 50px;
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
}

.common-back-btn {
  height: 35px;
  width: 80px;
  border: 1px solid gray;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: var(--cl_grey);
  cursor: pointer;
}

.common-back-btn:hover {
  color: var(--cl_white);
  background-color: var(--cl_grey);
  border: 0
}
.common-back-btn-enviro{
  height: 33px;
  width: 150px;
  border: 1px solid gray;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  color: var(--cl_grey);
  cursor: pointer;  
  margin-right: 14px;
}

.common-back-btn-enviro:hover {
  color: var(--cl_white);
  background-color: var(--cl_grey);
  border: 0
} */

.p-0 {
  padding: 0px;
}

.m-0 {
  margin: 0px;
}

.text-white {
  color: var(--cl_white);
}

.text-bold {
  font-weight: bold;
}

.text-small {
  font-size: 14px;
}

.inactive {
  opacity: 0.4;
}

.inactive-btn {
  opacity: 0.6;
  cursor: help;
}

.disabled-btn {
  opacity: 0.5;
  pointer-events: none;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.bg-red {
  background-color: red;
}

.bg-blue {
  background-color: blue;
}

.bg-green {
  background-color: green;
}

.bg-white {
  background-color: #fff;
}

.bg-brown {
  background-color: #774835;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea{
  font-family: sans-serif;
}

*::-webkit-scrollbar {
  background: var(--cl_ivery);
}

.App > *::-webkit-scrollbar-{
  background: var(--cl_ivery);
}

*::-webkit-scrollbar-thumb, .customScrollBarThumbGrey  {
  background: var(--cl_grey);
  border-radius: 10px;
}

.customScrollBarThumbGrey{
  border: 6px solid var(--cl_grey);
  border-radius: 10px;
  background-clip: content-box;
  z-index: 2;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.blurEffct{
  filter: blur(9px);
  -webkit-filter: blur(9px);
  background-image: url('./app/assets/Dummy/DummyEnviroScreen.png');
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 0;
  border: none;
  border-radius: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.permissionInfoModalBg{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
 
}


.permissionInfoModal{
  margin: auto;
  width: 40vw;
  height: 20vw;
  border-radius: 20px;
  background-color: whitesmoke;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 10;
}

.permissionInfoModalTitle{
  text-align: center;
  height: 20%;
  width: 100%;
  padding-top: 2%;
  border-radius: 20px 20px 0 0;
  background-color: black;
  color: white;
  font-weight: 700;
}

.permissionInfoModalContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  font-size: .7em;
}

.permissionInfoModalBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background-color: var(--cl_blue);
  color: white;
  padding: 15px;
  margin: 5%;
  width: 30%;
  cursor: pointer;
}


.permissionInfoModalBtn:hover{
  background-color: var(--cl_grey);
}

#custom-target {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.skeleton{
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    rgba(245, 245, 246, 1);
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
.swal2-container{
  z-index: 10600 !important;
}
@keyframes shine {
  to {
    background-position: 100% 0;
  }
}

.se-toolbar{
  z-index: 1 !important;
}
.se-wrapper{
  z-index: 0 !important;
}

.newVerticalTrack{
  background-color: transparent;
  /* backdrop-filter: invert(80%); */
  height: 100%;
  width: .9rem !important;
  right: 0;
  transition: all 1s;
}

.newVerticalTrackActive{
  background-color: rgba(255,255,255,0.1);
  height: 100%;
  width: 1.2rem !important;
  right: 0;
  transition: all 1s;
}

.newVerticalTrackThumb{
  background-color: rgba(245, 245, 246, 0.1);
  backdrop-filter: invert(80%);
  border-radius: 10px;
}



.newHorizontalTrack{
  background-color: transparent;
  /* backdrop-filter: invert(80%); */
  width: 100%;
  height: .9rem !important;
  bottom: 0;
  transition: all 1s;
}

.newHorizontalTrackActive{
  background-color: rgba(255,255,255,0.1);
  width: 100%;
  height: 1.2rem !important;
  bottom: 0;
  transition: all 1s;
}

.newHorizontalTrackThumb{
  background-color: rgba(245, 245, 246, 0.1);
  backdrop-filter: invert(80%);
  border-radius: 10px;
}
#improvedScroll > div:first-child{
  padding-right: 1rem !important;
}

#improvedScroll:has(table) > div:first-child{
  padding:unset;
}
#improvedScroll:has(table) > .newVerticalTrack, 
#improvedScroll:has(table) > .newVerticalTrackActive, 
#improvedScroll:has(table) > .newHorizontalTrack, 
#improvedScroll:has(table) > .newHorizontalTrackActive{
  z-index: 2;
}

.suppressScrollZindex > #improvedScroll:has(table) > .newVerticalTrack, 
.suppressScrollZindex > #improvedScroll:has(table) > .newVerticalTrackActive, 
.suppressScrollZindex > #improvedScroll:has(table) > .newHorizontalTrack, 
.suppressScrollZindex > #improvedScroll:has(table) > .newHorizontalTrackActive{
  z-index: 1;
}

.jobCardDivHeadButtonSection{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 1%;
  padding-bottom: 0;
  background-color: var(--cl_blue);
  color : white;
  flex-wrap: wrap;
  cursor: pointer;
}
.jobCardDivHeadButtonSection > div{
  padding: 1%;
  
}
.jobCardDivHeadButtonSection > div:hover{
  background-color: rgba(255,255,255,0.5);
  padding: 1%;
}
.jobCardDivHeadButtonSectionDiv{
  border-radius: 20px 20px 0 0;
  padding: 1%;
  border : 1px solid white;
  background-color: white;
  color:black;
}

@media screen and (max-width:1024px) {
	.main-layout{
		width : 90%;
    overflow-y: auto;
	}
  .sidebar-layout{
    width: 10%;
    overflow-y: auto;
  }
  }