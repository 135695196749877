.client-add-layout {
    height: 81vh;
    width: 85vw;
    padding-left: 6vh;
    padding-right: 6vh;
    display: flex;
}
.add-client-left {
    height: 100%; 
    width: 45%; 
    
}
.add-client-left-top{
    width: 100%;
    height: 7.5%;
}
.add-client-left-center-main{
    border:solid 0.5px rgb(214, 214, 214);
    width: 100%;
    border-radius: 10px;
    height: 92%;
    background-color: white;
}
.add-client-left-center{
    width: 100%;
    height: 51%;
}

.add-client-right {
    height: 100%;
    width: 55%;
    margin-left: 15px;
}


