
.accountsTableDiv{
    padding: 0 2%;
    display: flex;
    flex-direction: column;
}

.accountsTableHeading{
    font-weight: 500;
    font-size: 18px;
    padding: 10px 0;
}
.accountsTableSec{
    height: auto;
}
.accountsTableSec > th{
    font-size: 16px;
    padding: 10px 0;
}
.accountsTableSec > tbody > tr > td{
    font-size: 14px;
    padding: 5px 0;
    cursor: pointer;
    text-align: center;
}
.accountTableStatusBtn, .accountTableStatusBtnDis{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    color: white;
    font-weight: 500;
    background-color: red;
    width: 90%;
    height: 3vh;
    border-radius: 20px;
    cursor: pointer;
    margin: 0 5%;
}

.accountTableStatusBtn:hover{
    background-color: rgb(250, 116, 116);
}

.jobRescheduleDivLayout{
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
    width: 60%;
    height: 70%;
}

.jobRescheduleDividedSection{
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-radius: 20px;
    width: 95%;
    height: 90%;
}

.jobRescheduleContentDiv{
    width: 50%;
}

.jobRescheduleTopDiv{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: whitesmoke;
}

.jobRescheduleJobCodeList{
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.jobRschdJobCodeListBtn, .jobCodeChipDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: var(--cl_blue);
    color:white;
    width: 60%;
    height: 3vh;
    margin: 5% 0;
    cursor: pointer;
    padding: 5px 0;
}



.jobCodeChipDiv{
    width: 90%;
    margin: 5%;
}

.jobRschdJobCodeListBtn:hover, .jobRschdJobCodeListBtnSelected{
    background-color: var(--cl_ivery);
    color:var(--cl_blue);
}

.jobRescheduleBtn, .jobRescheduleBtnDisable{
    background-color: var(--cl_blue);
    color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    border-radius: 20px;
}

.jobRescheduleBtnDisable{
    opacity: 0.6;
    cursor: none;
}

.shdl-Leave-emp-body {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    width: 100%;
    height: 100%;
}

.jobRescheduleTeamList{
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, 70px);
    padding: 20px;
}

.shdl-single-team-circle {
    height: 60px;
    width: 60px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shdl-single-team-circle img {
    height: 50px;
    width: 50px;
    background-color: #fff;
    border-radius: 50%;
}

.inactiveCircle{
    opacity: 0.3;
    cursor: default;
}

.selectJobWarn{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 5%;
}

.accountsTableNavDiv{
    display: flex;
    flex-direction: row;
    margin: .7rem;
    font-size: 1rem;
}

.accountsTableNavBtn{
    display:flex;
    align-items: center;
    justify-content: center;
    padding: .8rem;
    border-radius: 30px;
    border: 1px solid var(--cl_grey);
    margin: 0 .5rem;
    background-color: white;
    font-weight: 500;
    cursor: pointer;
}

.accountsTableNavBtnActive{
    background-color: var(--cl_blue);
    color: white;
}
.printableWeekBeg{
    display: none;
}
.timeSheetLayout{
    position: relative;
}
.timesheetprintLogo{
    position: absolute;
    right: 1%;
    bottom : 0;
    height: 5vh;
    width: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2%;
}
.timesheetprintLogo > img{
    object-fit: contain;
    height: 100%;
    width: 100%;
    
}
@media print {
    body * {
      visibility:hidden;
    }
    @page { size: landscape;  }
    #printSection, #printSection * {
      visibility:visible;
      font-size: .7rem;
    }
    .printableWeekBeg{
        display: block;
    }
    .nonprintableWeekBeg{
        display: none;
    }
    #printSection {
      position:absolute;
      left:0;
      top:0;
      width: 90%;
      padding: 0 5%;
    }
    
    
  }