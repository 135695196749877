.report-modal {
    max-width: 40vw;
    min-width:40vw;
    background-color: #fff;
    outline: none;
    border-radius: 10px;
    padding: 20px;
    height: 80vh;
    overflow: hidden;
    -webkit-box-shadow: 0px 4px 26px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 26px -2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 26px -2px rgba(0, 0, 0, 0.75);
    position: relative;
}

.report-head{
    
    display: flex;
    flex-direction: row-reverse;
}

.report-body{
    padding: 10px;
}
.report-body-head{
    text-align: center;
    margin-bottom: 5vh;
}

.before-images{
    display: flex;
    flex-direction: column;
    
}

.image-div{
    padding-top: 20px;
    display: flex;
    align-items: center;
    
}

.image-div-element{
    margin:0 20px 0 20px ;
    height:50%;
    
    

}

.image-description{
    height: 75px;
    width: 250px;
}

.generate-button-div{
    margin-top:20px;
    display: flex;
    justify-content: center;
    padding:10px
}

.generate-button{
    border-radius: 25px;
    border-color: #fff;
    padding:10px;
    background-color: var(--cl_green);
    color:white;
    

}

.image-element{
    cursor: pointer;
    width: 100%; 
    height: 100%; 
    object-fit: cover
}

.after-image-header-div{
    margin-top:30px;
}



.download-button{
    border-radius: 25px;
    border-color: #fff;
    padding:10px;
    background-color: var(--cl_green);
    color:white;
    margin-left: 20px;
}