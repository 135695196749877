.shd-new-App {
    height: 90vh;
    width: 64.4vw;
    margin: 1em;
    border: 1px solid rgb(179, 178, 178);
    font-family: 'Roboto', sans-serif;
  }
  .shd-new-scheduler-header {
    height: 40px;
    background-color: rgb(179, 178, 178);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1em;
  }
  
  .shd-new-scheduler-header h3 {
    font-size: 1em;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }
  
  .shd-new-scheduler-controls button {
    font-size: 1rem;
    font-weight: bold;
    padding: .3em;
  }
  
  
  .shd-new-shceduler-container {
    height: 85vh;
    overflow: scroll;
  }
  
  .shd-new-scheduler-col-layout {
    display: inline-grid;
    grid-template-columns: repeat(20, 200px);
  }  
  .shd-new-scheduler-col-layout-head {
    background-color: var(--cl_grey);
    color: #fff;
    position: sticky;
    top: 0;
    z-index: 3;
  }
  
  .shd-new-scheduler-item-head {
    height: 40px;
    width: 100%;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .55rem;
    font-weight: bold;
    text-align: center;
    background-color: var(--cl_grey);
    
  }
  
  .shd-new-scheduler-item-container {
    width: 100%;
    font-size: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: #ffffff;
background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23e1e1e1' fill-opacity='0.29'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

  .shd-new-scheduler-item-container > table{
    /* height: 0; */
    width: 100%;
  }
  
  .shd-new-scheduler-item {
    height: 50px;
    width: 100%;
    border: 0.4px solid black;
    border-radius: 5px;
    font-size: .7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .shd-new-scheduler-item-filler{
    border: 0.4px solid transparent;
    background-color: transparent;
    color: transparent;
  }

  .shd-single-filled-container {
    border: 0.2px solid gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #a7c7d4;
    text-align: center;
    padding: 2px;
    position: relative;
    /* background: rgb(82, 70, 240); */
  }

  .shd-flex {
    display: flex;
  }

  .shd-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    min-width: 40%;
    padding: 0 5px;
    border-radius: 5px;
  }

  .shd-job-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    border: 0;
    width: 60%;
    min-height: 23px;
    background-color: white;
    margin: 7px 0;
    height: 26px;
    cursor: pointer;
  }
  .shd-employee-names{
    background-color: #B0EFEB;
    padding: 5px 7px;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    margin-top: 7px;
    word-break: break-word;
  }

  .shd-employee-names .shd-employees-tooltip {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    
    margin-top: 2px;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
    white-space: pre-wrap;
  }
  
  
  .shd-employee-names:hover .shd-employees-tooltip {
    visibility: visible;
  }

  .shd-employee-names .shd-employees-tooltip::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }

  .toggleDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    background-color: white;
    height: 80%;
    width: 35%;
    cursor: pointer;
    pointer-events: all;
    position: relative;
  }

  .toggleButton{
    width: 33%;
    padding: 0 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 0;
    cursor: pointer;
  }

  .toggleButtonActiveLeft, .toggleButtonActiveRight, .toggleButtonActiveMiddle{
    position: absolute;
    cursor: pointer;
    border-radius: 20px;
    background-color: var(--cl_grey);
    color: white;
    height: 80%;
    width: 28%;
    margin-left: 2%;
    align-self: center;
    -webkit-box-shadow: 4px 0px 10px -5px rgba(0,0,0,0.68);
-moz-box-shadow: 4px 0px 10px -5px rgba(0,0,0,0.68);
box-shadow: 4px 0px 10px -5px rgba(0,0,0,0.68);

  }

  .toggleButtonActiveLeft{
    background-color: var(--cl_blue);
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    
  transition: transform 500ms ease, 
    background-color 500ms ease, 
    box-shadow 500ms ease;
    -webkit-transition: transform 500ms ease, 
    background-color 500ms ease, 
    box-shadow 500ms ease;
    -moz-transition: transform 500ms ease, 
    background-color 500ms ease, 
    box-shadow 500ms ease;
    -o-transition: transform 500ms ease, 
    background-color 500ms ease, 
    box-shadow 500ms ease;
  }

  .toggleButtonActiveRight{
    margin-right: 2%;
    -webkit-box-shadow: -4px 0px 10px -5px rgba(0,0,0,0.68);
-moz-box-shadow: -4px 0px 10px -5px rgba(0,0,0,0.68);
box-shadow: -4px 0px 10px -5px rgba(0,0,0,0.68);
  }

  .toggleButtonActiveRight{
    transform: translateX(155%);
    -webkit-transform: translateX(155%);
    -moz-transform: translateX(155%);
    -o-transform: translateX(155%);

    background-color: var(--cl_blue);
    transition: transform 500ms ease, 
      background-color 500ms ease, 
      box-shadow 500ms ease;
    -webkit-transition: transform 500ms ease, 
    background-color 500ms ease, 
    box-shadow 500ms ease;
    -moz-transition: transform 500ms ease, 
    background-color 500ms ease, 
    box-shadow 500ms ease;
    -o-transition: transform 500ms ease, 
    background-color 500ms ease, 
    box-shadow 500ms ease;

      
    }

    .toggleButtonActiveMiddle{
      margin-right: 2%;
    -webkit-box-shadow: -4px 0px 10px -5px rgba(0,0,0,0.68);
-moz-box-shadow: -4px 0px 10px -5px rgba(0,0,0,0.68);
box-shadow: -4px 0px 10px -5px rgba(0,0,0,0.68);

transform: translateX(80%);
    -webkit-transform: translateX(80%);
    -moz-transform: translateX(80%);
    -o-transform: translateX(80%);

    background-color: var(--cl_blue);
    transition: transform 500ms ease, 
      background-color 500ms ease, 
      box-shadow 500ms ease;
    -webkit-transition: transform 500ms ease, 
    background-color 500ms ease, 
    box-shadow 500ms ease;
    -moz-transition: transform 500ms ease, 
    background-color 500ms ease, 
    box-shadow 500ms ease;
    -o-transition: transform 500ms ease, 
    background-color 500ms ease, 
    box-shadow 500ms ease;

    }

  #scrollCalGrid{
    cursor: grab;
    cursor: -webkit-grab;
  }
  .scheduleScrollInsdDiv > div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .shimmerHead, .shimmerList{
        background: #f6f7f9;
        background-image: linear-gradient(
          to right,
          #f6f7f9 0%,
          #d3d4d4 20%,
          #f6f7f9 40%,
          #f6f7f9 100%
        );
        background-repeat: no-repeat;
        background-size: 800px 104px;
        animation: shine 2s infinite linear forwards;
    border: none;
    /* border-radius: 10px; */
}
.shimAdj{
  background: #707070;
  background-image: linear-gradient(
          to right,
          #707070 0%,
          #a3a2a2 20%,
          #707070 40%,
          #707070 100%
        );
  border: 1px solid var(--cl_ivery);
  border-radius: 5px;
  animation: shine 20s infinite linear forwards;

}
.reportPrblmFloat{
  position: absolute;
  top: 0;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
  border: 1px solid red;
  padding: 1%;
}
.changeValBoxEdit, .changeValBoxEditFx{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.changeValBoxEdit > span, .changeValBoxEditFx > span{
  display: grid;
  place-content: center;
  border-radius: 50%;
  padding: 5%;
  aspect-ratio: 1/1;
  width: 1rem;
  height: 1rem;
  border : 1px solid white;
}

.changeValBoxEditFx > span{
  border-radius: 20px;
  width: 4rem;
}

.changeValBoxEdit > span:hover, .changeValBoxEditFx > span:hover{
  background-color: white;
  color: var(--cl_blue);
  cursor: pointer;
}

.shdl-changedValuesBox input{
  border : none;
  border-bottom: 1px solid grey;
  background: transparent;
  width: 90%;
  color: white;
}

.shdl-cmpltd-jobStatusTime-informant{
  border : 2px solid orange;
  border-radius: 20px;
  padding: 2%;
  font-weight: 500;
  font-size: small;
  background-color: rgb(240, 193, 38);
}

.schCalHoverElevate:hover{
  position: relative;
  z-index: 1;
  transition: all 1s ease-in-out;
}
.schCalHoverElevate:hover > div{
  box-shadow: 0px 0px 14px 8px rgba(0,0,0,0.3);
}

.floatingCommentIconRedDot{
  display: grid;
  width: 100%;
  font-size: 1rem;
  place-content: end;
}

@keyframes shine {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
