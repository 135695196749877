/*///////////////////////////////////////////////////////////////////////////////////////////////
/////////////////// STYLES IMPORTED FROM FOLDER */
*{
    padding: 0;
    margin: 0;
}

.folder-main-layout {
    height: 50px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 160px);
    padding: 15px;
}

.main-folder-card {
    height: 42px;
    width: 160px;
    border-radius: 8px;
    border: 1px solid rgb(221, 221, 221);
    cursor: default;
    padding: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: gray;
}

.main-folder-card:hover {
    background-color: rgb(221, 221, 221);
    color: rgb(82, 81, 81);
}

.scopOfWorkEditorPopclose{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 5vh;
    background-color: var(--cl_light_grey);
}
.scopOfWorkEditorPop{
    height: 10vh;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    background-color: var(--cl_light_grey);
    /* min-width: 60%; */
}

.scopOfWorkEditorPop > div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    margin-left: 1%;
}

.scopOfWorkEditorPop p{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width:40%;
    margin-left: 1%;
}
.scopOfWorkEditorPop input{
    background-color: transparent;
    border : none;
    border-bottom: .5px solid grey;
    margin-left: 1%;
    width: 60%;
}
.scopOfWorkEditorPop textarea{
    background-color: rgba(0,0,0,0.1);
    border: .5px solid grey;
    margin-left: 1%;
    height: 90%;
}
.scopeOfWorkEditorPopBtn{
    background-color: var(--cl_blue);
    border-radius: 10px;
    padding: 1%;
    color: white;
    /* min-width: 4rem !important; */
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
}

.cer-cerificate-modal-layout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 75vw;
    height: 60vh;
    padding: 1vh;
    padding-bottom: 2vh;
    outline: none;
    background-color: var(--cl_white);
    border-radius: 15px;
}

.cer-modal-header-layout {
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
}
.cer-modal-header-tab-left, .cer-modal-header-tab-right{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 1%;
    background-color: var(--cl_blue);
    color: white;
    border : .5px solid var(--cl_blue);
    cursor: pointer;
}

.cer-modal-header-tab-left{
    border-radius : 30px 0 0 30px;
}

.cer-modal-header-tab-right{
    border-radius : 0 30px 30px 0;
}

.cer-modal-header-tab-inactive{
    background-color: transparent;
    color: black;
}
.cer-modal-header-tabDiv{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: .2%;
    font-size: 1rem;
}

.cer-modal-main-title {
    font-size: 20px;
    font-weight: bold;
    color: grey;
    margin-left: 12px;
}

.cer-modal-body-layout {
    height: 45vh;
}

.cer-main-file-layout {
    display: inline-grid;
    grid-template-columns: auto auto auto auto;
}

.cer-single-file-certificate-nw {
    border: 1px solid #c4c3c0;
    height: 120px;
    margin: 0.5vw;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.cer-single-file-certificate-nw:hover {
    background-color: rgb(231, 230, 230);
}

.description-icon-btn {
    cursor: pointer;
}

.cer-single-body {
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center ;
    color: grey;
}

.cer-single-header-nw {
    flex: 1;
    width: 11.2vw;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgb(184, 184, 184);
    font-size: 14px;
    cursor: pointer;
}


.cer-single-header {
    height: 40%;
    width: 11.2vw;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgb(184, 184, 184);
    font-size: 14px;
}

.cer-test {
    display: block;
}

.cer-text {
    overflow: hidden;
    height: 15px;
    width: 11.2vw;
    color: black;
}

.cer-modal-footer-layout {
    height: 10vh;
    display: flex;
    justify-content: space-between;
    padding-left: 2vh;
    font-size: 14px;
}

.cer-modal-footer-layout-inner {
    display: flex;
    align-items: flex-end;
    padding-left: 2vh;
    font-size: 14px;
}

.cer-footer-left {
    height: 30px;
    display: flex;
    align-items: center;
}

.cer-footer-left-file {
    display: flex;
    height: 30px;
    align-items: center;
}

.cer-file-single {
    height: 30px;
    width: 30px;
    border: 1px solid gray;
    border-radius: 2px;
    margin: 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cer-file-remove {
    position: absolute;
    cursor: pointer;
    
}

.cer-modal-footer-title {
    padding: 0;
    margin: 0;
}

.cer-footer-right-nw {
    background-color: var(--cl_blue);
    color: white;
    border-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    cursor: pointer;
}

.cer-footer-right-nw:hover { 
    background-color: rgb(19, 87, 165);
}

.cer-file-delete {
    height: 10px;
}

.cer-file-delete-icon {
    padding-left: 90%;
}


.delete-icon-btn {
    color: grey;
    cursor: pointer;
}

.delete-icon-btn:hover {
    color: black;
}

/*////////////////////////////////////////////////////////////////////////////////////////////////*/
.header-button-top {
    height: 35px;
    width: 10vw;
    /* width: 150px; */
    background-color: #fff;
    border: 1px solid gray;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: gray;
    margin-left: 10px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
}

.header-button-top-active {
    background-color: black;
    border: 1px solid black;
    color: white;
}

.header-button-top:hover{
    background-color: gray;
    color: white;
}
/* 
.navbar-layout {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 10%);
    padding: 0px 3vw;
}

.navbar-layout-back-btn {
    display: flex;
    justify-content: space-between;
    padding: 0px 3vw;
}

.navbar-button {
    height: 32px;
    min-width: 10%;
    /* width: 100%; */
/*    border-radius: 30px;
    border: 1px solid gray;
    background-color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}


.navbar-button:hover {
    border: 1px solid rgb(197, 197, 197);
    background-color: rgb(197, 197, 197);
}

.navbar-button-active {
    border: 1px solid var(--cl_blue);
    background-color: var(--cl_blue);
    color: white;
    pointer-events: none;
} */
/* 
.sales-main-layout {
    padding: 10px 3vw;
    height: 76vh;
    display: flex;
    flex-direction: column;
}

.sales-home-list-layout {
    height: 50vh;
    background-color: white;
    margin-bottom: 10px;
}

.sales-excecutive-layout {
    height: 25vh;
    background-color: white;
    border-radius: 10px;
    font-size: 12px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
 */
.intranet-single-file-layout {
    height: 50px;
    /* margin: 30px;
    margin-top: 10px; */
    margin-right: 30px;
    margin-bottom: 0px;
    display: flex;
}

.inavlb-jobCard{
    opacity: 0.5;
}

.intranet-single-index {
    height: 100%;
    width: 40px;
    border: 1px solid  rgb(211, 211, 211);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.intraner-single-label {
    width: 8vw;
    height: 100%;
    background-color: rgb(211, 211, 211);
    border: 1px solid  rgb(211, 211, 211);
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0px 10px;
}

.intraner-single-label-active {
    width: 100%;
    height: 100%;
    background-color: rgb(161, 161, 161);
    border: 1px solid  rgb(161, 161, 161);
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0px 10px;
}
/* 
.sales-exce-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sales-exce-head-sort {
    display: flex;
}

.sales-exce-head-sort-label {
    margin-bottom: 10px;
    margin-left: 20px;
    background-color: var(--cl_blue);
    border-radius: 20px;
    padding: 10px;
    color: white;
    display: flex;
    align-items: center;
}

.sales-exce-head-sort-label span {
    font-size: 12px;
    font-weight: 500;
}


.sales-exce-card-layout {
    display: flex;
}

.sales-exce-card {
    height: 18vh;
    background-color: rgb(226, 226, 226);
    margin: 0px 5px;
    border-radius: 8px;
    padding: 5px;
    display: flex;
}

.sales-exce-card-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: gray;
}

.sales-exce-img {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    padding: 5px 0px;
}

.sales-exce-card-right {
    flex: 2;
    width: 150px;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 15px;
} */
/* 
.sales-table-layout {
    height: 49vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sales-table-body-container {
    flex: 10;
}

.sales-table {
    width: 100%;
    height: 45vh;
}

.table-head td {
    font-size: 13px;
    font-weight: bold;
    border: 0.5px solid rgb(226, 226, 226);
    padding: 8px 0px;
}

.sales-table-footer {
    flex: 1;
    display: flex;
    background-color: rgb(226, 226, 226);
    justify-content: flex-end;
    align-items: center;
    padding: 0px 10px;
    height: 5vh;
}

.sales-footer-page-label {
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px; 
    font-weight: bold; 
    margin-left: 4px; 
    margin-right: 4px;
    padding: 5px 8px;
}

.sales-footer-buttons {
    color: black;
    border-radius: 5px;
    cursor: pointer;
}

.sales-footer-buttons:hover {
    background-color: gray;
} */

.sales-generate-quote-layout {
    height: 75vh;
    padding: 0px 3vw;
    display: flex;
}

.sales-qtGnrt-CreatBtn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 2vh;
    font-size: 12px;
    border-radius: 30px;
    padding: 8px;
    cursor: pointer;
    background-color: rgb(179, 174, 174);
}

.sales-qtGnrt-CreatBtn:hover{
    background-color: white;

}

.sales-qt-gnrt-left {
    flex: 1.5;
    padding: 20px 0px;
}

.sales-qt-gnrt-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 3;
    padding: 20px 0px;
}

.sales-qt-gnrt-template-layout {
    height: 75vh;
    border: 1px solid grey;
    border-radius: 5px;
    width: 90%;
}

.sales-qt-template-header {
    height: 40px;
    background-color: grey;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
}


.sales-qt-gnrt-template-main {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    padding: 10px;
}


.sales-qt-rgrt-template-single {
    height: 42px;
    width: 160px;
    border-radius: 10px;
    background-color: rgb(209, 209, 209);
    cursor: pointer;
    padding: 5px 7px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
    text-decoration: none;
}

.sales-qt-rgrt-template-single:hover {
    background-color: rgb(177, 177, 177);
    color: black;
}

.sales-qt-gnrt-detail {
    height: 82vh;
    display: flex;
    padding: 0px 3vw;
    flex-wrap: wrap;
    overflow-y: auto;
    position: relative;
    scroll-behavior: smooth;
}

.sales-qt-gnrt-dtl-left {
    flex: 50%;
}

.sales-qt-gnrt-dtl-right {
    flex: 50%;
    font-size: 12px;
}

.sales-quote-editDiv{
    display: flex;
    flex-direction: column;
    padding: 10px 5px;
}

.sales-quote-editTextAreaDiv{
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
    margin: 5px 0;
    background-color: #add8e6;
}

.sales-quote-editTextAreaDiv > p{
    color: black;
    font-weight: 600;
    padding: 10px 0;
}

.sales-quote-editTextAreaDiv > textarea{
    background-color: rgb(211, 229, 235);
    outline: none;
    resize: none;
    border: none;
}

@media (max-width:1000px) {
    .sales-qt-gnrt-dtl-left, .sales-qt-gnrt-dtl-right{
        flex: auto;
    }
}

.sales-qt-gnrt-dtl-recocur {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
    margin-top: 20px;
    margin-left: 20px;
    border: 1px solid rgb(196, 195, 195);
    border-radius: 10px;
    max-width: 50%;
}

.sales-qt-gnrt-dtl-intra-files {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 21vw);
    margin-top: 10px;
    margin-left: 20px;
}

.sales-selection-box {
    height: 25vh;
    border: 1px solid rgb(196, 195, 195);
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}

.sales-selection-box-header {
    height: 35px;
    background-color: rgb(196, 195, 195);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
}

.sales-attach-single-file {
    display: flex;
    align-items: center;
    margin: 10px;
    color: gray;
    font-size: 14px;
}

.sales-qt-rgstr-btn-layout {
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
}

.sales-qt-rgstr-btn-layout button {
    border: 0;
    background-color: var(--cl_blue);
    height: 35px;
    padding: 0px 20px;
    color: white;
    font-weight: 500;
    border-radius: 30px;
    cursor: pointer;
}

.sales-qt-rgstr-btn-layout button:hover {
    background-color: rgb(35, 117, 150);
    color: white;
}


.sales-qt-rgstr-info-btn-layout {
    display: flex;
    padding: 20px 0px;
    align-items: center;
    margin-right: 20px;
}

.sales-qt-rgstr-info-btn-client {
    flex: 1;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.sales-qt-rgstr-info-btn-clientBox{
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 5px 20px;
    position: relative;
}

.sales-qt-rgstr-info-btn-clientBoxRipple {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--cl_blue);
    border-radius: 10px;
    z-index: 1;
    left: 0;
    top: 0;
    animation: ripple 3s ease-out infinite;
  }

  @keyframes ripple {
    from {
      opacity: 1;
      transform: scale(0);
    }
    to {
      opacity: 0;
      transform: scale(1);
    }
  }

  .salesQtBtnFloat{
    position: sticky;
    top: 0;
    z-index: 1;
    padding-left: 1%;
  }
  .salesQtBtnFloat > button{
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
  }

.sales-qt-rgstr-editor-sts-btn {
    border: 1px solid rgb(194, 194, 194);
    min-height: 35px;
    width: 120px;
    padding: 0px 20px;
    margin-right: 10px;
    color: black;
    font-weight: 500;
    border-radius: 30px;
    cursor: pointer;
    
}

.sales-qt-rgstr-editor-sts-btn:hover {
    font-weight: 600;
    background-color: rgb(180, 180, 180);
}

.sales-qt-rgstr-editor-sts-btn-active {
    font-weight: 600;
    color: white;
    background-color: var(--cl_blue);
}

.sales-qt-rgstr-editor-sts-btn-active:hover {
    color: black;
    background-color: var(--cl_blue);
}

.sales-qt-client-info {
    background-color: #fff;
    height: 65vh;
    margin-right: 20px;
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
}


.sales-qt-client-reg-box {
    display: flex;
    margin: 10px 0px;
}

.sales-qt-client-reg-box input {
    flex: 1;
    border: 0;
    border-bottom: 1px solid gray;
    margin-right: 10px;

}

.sales-qt-client-reg-box button {
    flex: 1;
    background-color: rgb(190, 190, 190);
    border: 0;
    padding: 0px 20px;
    height: 30px;
    border-radius: 7px;
}

.sales-qt-clinet-table-lay {
    margin-top: 20px;
    height: 55vh;
    border: 1px solid  rgb(194, 194, 194);
    border-radius: 10px;
}

.sales-qt-clinet-table-hd {
    height: 2.5rem;
    background-color: rgb(196, 195, 195);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 20px;
}

.sales-qt-clinet-table-hd-searchDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 98%;
}

.sales-qt-clinet-table-hd p {
    flex: 1;
}

.sales-qt-client-body {
    display: flex;
    padding: 10px;
}

.sales-qt-perment-client-lay {
    flex: 1;
    width: 50%;
}

.sales-qt-temp-client-lay {
    flex: 1;
    width: 50%;
}

.sales-qr-client-single {
    margin-right: 25px;
    margin-bottom: 20px;
    min-height: 55px;
    border: 1px solid rgb(231, 231, 231);
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
}

.sales-qr-client-single-selected {
    border: 1px solid rgb(187, 187, 187);
    background-color: rgb(187, 187, 187);
}

.sales-qr-singl-client-mn-titleDiv{
    display: flex;
    flex-direction: column;
    row-gap: .3rem;
    padding: 2% 0;
}

.sales-qr-singl-client-mn-title {
    font-size: 16px;
    font-weight: bold;
    word-wrap: break-word;
    word-break: break-all;
}
.sales-qr-singl-client-mn-desc{
    font-size: 14px;
    font-weight: 300;
    word-wrap: break-word;
    word-break: break-all;
}


.sales-jobs-main-layout {
    height: 80vh;
    padding: 0px 3vw;
}

.sales-jobs-back-layout {
    display: flex;
    justify-content: flex-end;
}

.sales-jobs-back-layout > button {
    height: 35px;
    width: 100px;
    background-color: #fff;
    border: 1px solid grey;
    border-radius: 20px;
    cursor: pointer;
}

.salesPerformTopDiv{
    display:flex; 
    flex-direction: row; 
    width: 90%; 
    padding: 20px 0; 
    justify-content:space-between;
}

.salesPerformTopDivRight{
    display:flex; 
    flex-direction: column; 
    padding: 0;
    margin-left: 2%; 
    justify-content:space-between;
    align-items: flex-end;
    float: left;
    max-width: 50vw;
}

.salesPerformTopDivRightDown{
    display: flex;
    width: 60vw;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
}

.salesRptBtn{
    border-radius: 10px;
    border:1px solid gray;
    background-color: var(--cl_blue);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    height: 100%;
    padding: 1%;
    margin-right: 1%;
    cursor: pointer;
}

.salesPerformTopDivRightDown > div{
    cursor: pointer;
}

.sales-horizScroll-List{
    margin-left: 20px;
    margin-top: 30px;
    min-width: 41vw;
    position: relative;
}

.sales-horizScroll-ListSpl{
    margin-left: 2px;
    margin-top: 30px;
    margin-bottom: 20px;
    min-width: 21vw;
    position: relative;

}

.sales-client-drafts-list{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    margin: 0 1vw;
    width: 80%;
    /* position: relative; */
}

.sales-client-drafts{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding: 20px;
    background-color: lightgray;
}

.sales-client-drafts:hover{
    background-color: gray;
}

.sales-template-modal{
    display: block; 
    position: fixed; 
    z-index: 1; 
    padding-top: 100px; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}
.sales-template-modal-close{
    display: none;
}
.sales-template-modal-content{
    background-color: #fefefe;
    margin: auto;
    padding: 40px 20px 10px 20px;
    border-radius: 30px;
    border:none;
    width: 25%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sales-template-table{
    height: auto;
    width: 100%;
    /* display: flex;
    align-items: center;
    flex-direction: column; */
}
/* 
.sales-template-table > tbody {
    width: 100%;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
} */

.sales-template-tableHead, .sales-template-tableRow{
    /* display: flex;
    flex-direction: row; */
    height: 5vh;
    /* width: 100%; */
    /* align-items: center;
    justify-content: space-between; */
}

.sales-template-tableRow > td, .sales-template-tableHead > th{
    /* display: flex; */
    /* width: 25%; */
    height: 100%;
    /* justify-content: center;
    align-items: center; */
}

.sales-template-tableHead > th{
    font-size: 18px;
    font-weight: 500;
    height: 6vh;
}

.sales-template-tableRow > td > div {
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1%;
    /* min-height: 5vh; */
    min-height: 6vh;
    font-size: 16px;
    cursor: pointer;
    margin: 0;
}

.sales-template-tableRow > td > div > input{
    min-height: 6vh;
    padding: 0 1%;
    border: 1px solid lightgray;
    background-color: transparent;
}

.sales-template-tableRow > td > div > textarea{
    /* min-height: 6vh; */
    height: 100%;
    padding: 0 1%;
    border: 1px solid lightgray;
    background-color: transparent;
    resize: none;
    overflow-y: hidden;
}


.sales-template-tableRow > td > div > p{
    display: flex;
    width: 80%;
    align-self: center;
    justify-self: center;
    min-height: 6vh;
    padding: 0 1%;
    align-items: center;
    background-color: transparent;
    font-weight: 400;
    word-wrap: break-word;
    /* border: 1px solid red; */
}

.sales-template-tableRowTotal, .sales-template-tableRowTotal > td{
    /* display: flex; */
    width: 100%;
    /* align-items: flex-start; */
    font-size: 18px;
    font-weight: 500;
}
.tableGSTCheckBoxGreen{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--cl_blue);
    border-radius: 5px;
    background-color: white;
    height: 1rem;
    width: 1rem;
}

.tableGSTCheckBoxGreen::after{
    content : '\2713';
    font-size: 1.5rem;
    font-weight: 600;
}
.tableGSTCheckBoxRed{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--cl_blue);
    border-radius: 5px;
    background-color: white;
    height: 1rem;
    width: 1rem;
}
#tableTotalDiv{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}
.TotalInpDiv, .totalInp{
    display: flex;
    flex-direction: row;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    width: 9.5vw;
}

.TotalInpDiv{
    /* border-bottom: 5px solid lightgray; */
    border: none;
}

.typeOfWasteChipBtn{
    background-color: var(--cl_grey);
    border-radius: 50%;
    padding: 1%;
    color: white;
    height: 1rem;
    width: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1%;
}

.typeOfWasteChipBtn:hover{
    background-color: white;
    color: var(--cl_grey);
}

.editTowSelected{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2%;
    border-radius: 50%;
    color:white;
    background-color: var(--cl_grey);
    border: 1px solid var(--cl_grey);
    font-size: 1rem;
    top:0;
    right:0;
    cursor: pointer;
}

.editTowSelected:hover{
    color:var(--cl_grey);
    background-color: white;
}

.dateSalesFn{
    background-color: whitesmoke;
    border: 1px solid gray;
    border-radius: 20px;
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 3vh;
    font-size: .9rem;
}

.TOWasteInputText{
    display: block;
}


.TOWasteInputText > input{
    width: 20vw;
    height: 4vh;
    padding:1%;
    border-radius: 5px;
    outline: none;
    border: .5px solid var(--cl_blue);
}

.swal-improve-z{
    z-index: 10000;
}
/* 
.quoteStatusButtonDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    height: 2vh;
}

.quoteStatusButton{
    width: 45%;
    height: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    margin: 0 2px;
    cursor: pointer;
}

.quoteStatusButton:hover{
    background-color: grey;
}

.quoteStatusAccept{ background-color: var(--cl_green); }
.quoteStatusReject{ background-color: var(--cl_red); }

.sales-qt-amnt-field{
    margin: 10px 0px 10px 30px;
    display: flex;
    align-items: center;
}

.salesAmntField{
    height: 3vh;
    border : none;
    background-color: transparent;
    border-bottom: 1px solid var(--cl_blue);
}

.viewBtn{
    height: 80%;
    border: 1px solid gray;
    background-color: #fff;
    padding: 3px 20px;
    font-size: 11px;
    border-radius: 20px;
    cursor: pointer;
}

.viewBtn:hover{
    border: 1px solid #fff;
    background-color: gray;
    color: white;
} */

.quote-confirm-modal-layout {
    width: 30vw;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    outline: none;
}   

.quote-cnrf-to-layout {
    margin: 20px 0px;
}

.quote-cnrf-field-title{
    font-size: 16px;
    font-weight: bold;
}

.quote-cnrf-emails {
    background-color: rgba(77, 202, 224, 0.712);
    margin: 5px;
    border-radius: 20px;
    font-size: 12px;
    padding: 5px 10px;
    width :fit-content;
    max-width: 8vw;
    min-height: 1vh;
    word-wrap: break-word;
    overflow: hidden;
    margin-right: 4px;
}

.quote-cnrf-emails-layout {
    display: grid;
    grid-template-columns: repeat(auto-fill, 10vw);
    margin-top: 10px;
}

.quote-cnrf-emails-input {
    border: 0;
    border-bottom: 1px solid gray;
    padding: 5px 5px;
    width: 100%;
    font-size: 16px;
    margin-top: 10px;
}

.quote-cnrf-emails-textarea {
    border: 0;
    border-bottom: 1px solid gray;
    padding: 5px 5px;
    width: 100%;
    font-size: 16px;
    margin-top: 10px;
    height: 100px;
}

.quote-cnrf-emails-textarea:focus {
    outline: none;
}

.quote-cnrf-actions-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sales-table-filterDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 90%;
    /* width: 30vw; */
}

.sales-table-filter-sites{
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: 0 10px;
    z-index: 3;
}

.sales-table-filter-sites-input{
    height: 3vh;
    border-radius: 10px;
    font-size: 16px;
    width: 15vw;
    background-color: white;
    color: black;
    border: none;
}

.sales-table-filter-dateDiv, .sales-table-filter-searchDiv{
    width: 20vw;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 30px;
    height: 3vh;
    cursor: pointer;
}

.sales-table-filter-dateDiv:hover{
    background-color: lightgray;
    color: black;
}

.sales-table-filter-date{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    background-color: transparent;
    cursor: pointer;
}

.sales-table-exportBtn{
    background-color: var(--cl_blue);
    color:white;
    width: 20%; 
    margin-left: 1%; 
    border-radius:10px;
}
.sales-table-exportBtn:hover{
    background-color: white;
    color:var(--cl_blue);
}

.draftTile{
    min-height: 140px;
}


.sales-quote-editTable{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width:100%;
}

.salesQuoteTableScrollDiv{
    display: flex;
    flex-direction: column;
}

.searchInpSalesQuote{
    border: none;
    width: 10vw;
    padding:2px;
    border-radius: 30px;
}

.quote-sales-approval-action {
    margin-top: 30px;
}

.quote-sales-approval-action p {
    padding: 0px 5px;
    padding-bottom: 5px;

}

.quote-sales-approval-action button {
    height: 40px;
    width: 200px;
    border: 0;
    border-radius: 30px;
    margin: 0px 5px;
    cursor: pointer;
    margin-top: 10px;
}

.cardEditDelBtn{
    height:25px;
    width:25px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:50%;
    background-color:rgb(175, 171, 171);
    margin:2px;
}
.cardEditDelBtn :hover{
    height:25px;
    width:25px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:50%;
    background-color: white;
}

.cardActiveQuoteBtn{
    height:25px;
    width:25px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:50%;
    background-color: var(--cl_green);
}

.cardNotActiveQuoteBtn{
    height:25px;
    width:25px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:50%;
    background-color: var(--cl_red);
}

.cardQtGenSml{
    height: 15px;
    width: 15px;
    border-radius: 25%;
}

.qt-gnrt-jobCardAddBtn{
    width: 7vw;
    height: 8vh;
    border-radius: 10px;
    background-color: lightgrey;
    cursor: pointer;
    margin-left: 10px;
}

.qt-gnrt-jobCardAddBtn > p{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    
}

.qt-gnrt-jobCardAddBtn :hover{
    background-color: white;
    border:1px solid lightgrey;
    border-radius: 10px;
}
/* 
.resendMailContent{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.resendMailId{
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    height: 5vh;
}

.resendMailBody, .resendMailSubject{
    width: 45vw;
    padding: 20px;
    height: 40vh;
    margin-bottom: 10px;
}

.resendMailSubject{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 2vh;
    padding-bottom: 5px;
    padding-top: 5px;
    margin: 0;
}

.reoccurModalLayout{
    background-color: white;
    border-radius: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
} */

.reoccurModalContent{
    display: flex;
    flex-direction: row;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
}

.reoccurModalDateTile{
    background-color: whitesmoke;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
    width: 35%;
    border: 1px solid lightgrey;
}

.reoccurModalTileHead{
    padding: 10px 0;
}

.reoccurModalTileContent{
    margin: 0 10px;
}

.typeOfWasteChipGrid{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    /* grid-template-columns: auto auto auto;
    grid-auto-flow: dense; */
}

.typeOfWasteChip, .typeOfWasteChipSel{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--cl_ivery);
    border: 2px solid var(--cl_ivery);
    border-radius: 5px;
    padding: 2%;
    margin: 1%;
    height: 2vh;
    align-self: center;
    cursor: pointer;
}

.typeOfWasteChip:hover{
    background-color: var(--cl_light_grey);
    border: 2px solid var(--cl_ivery);
}


.typeOfWasteChipSel{
    background-color: white;
    -webkit-box-shadow: 0px 0px 32px 6px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 32px 6px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 32px 6px rgba(0,0,0,0.1);
}

.typeOfWasteChip > div, .typeOfWasteChipSel > div{
    border: 2px solid var(--cl_ivery);
    padding: 1%;
}

.assetAutocompleteDiv{
    position: relative;
    background-color: transparent;
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
}

.assetAutocompleteDiv > input{
    width: 100%;
    height: 80%;
    background-color: transparent;
    outline: none;
    border: none;
}

.assetAutocompleteDrop{
    position: absolute;
    top: 80%;
    background-color: white;
    height: 10vh;
    width: 100%;
    border: .5px solid black;
    border-top: none;
}

.assetHoverDiv{
    padding: 5px;
}

.assetHoverDiv:hover{
    padding: 5px;
    background-color: var(--cl_blue);
}


.refetchPermsModal{
    width: 30%;
    height: 30%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.refetchPermsTitle{
    height: 30%;
    width: 100%;
    text-align: center;
    color: white;
    font-weight: 600;
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
    background-color: var(--cl_blue);
    padding: 2% 0;
    border-radius: 10px 10px 0 0;
}

.refetchPermsContent{
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    width: 100%;
}

.refetchPermsContent > div{
    width: 10%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--cl_blue);
    color: white;
    border-radius: 5px;
}

.SearchWidthAdjust{
    width: 30%;
}

.newsiteProfCheck{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 38vw;
    height: 2vh;
}

.newsiteProfCheck > div{
    border-radius: 20px;
    height: 2vh;
    width: 3vw;
    border: .5px solid black;
    background-color: var(--cl_light_grey);
    margin-right: 1%;
    position: relative;
    cursor: pointer;
}

.newsiteProfCheck > div > input{
    opacity: 0;
    height: 100%;
    width: 100%;
}

.newsiteProfCheckOFF{
    border-radius: 50%;
    height: 80%;
    width: 30%;
    border: .5px solid var(--cl_grey);
    background-color: var(--cl_grey);
    position: absolute;
    top:5%;
    right: 2%;
}

.newsiteProfCheckON{
    border-radius: 50%;
    height: 80%;
    width: 30%;
    border: .5px solid var(--cl_blue);
    background-color: var(--cl_blue);
    position: absolute;
    top:5%;
    left: 2%;

}

.newsiteProfCheckNO{
    color: var(--cl_grey);
    position: absolute;
    font-weight: 600;
    font-size: .8rem;
    top:10%;
    left: 5%;
}

.newsiteProfCheckYES{
    color: var(--cl_blue);
    position: absolute;
    font-weight: 600;
    font-size: .8rem;
    top:10%;
    right:5%;
}

.newsiteProfCheckName{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 38vw;
    height: 5vh;
}

.newsiteProfCheckName > p{
    color: var(--cl_blue);
    margin-right: 1%;
    width: 50%;
}

.newsiteProfCheckName > input{
    border: none;
    width: 40%;
    border-bottom: 1px solid var(--cl_blue);
    margin-right: 1%;
}

.helperTextHovering{
    position: absolute;
    justify-content: center;
    align-items: center;
    background-color: rgba(250, 250, 126);
    border: 1px solid orange;
    text-align: center;
    min-width: 10vw;
    max-height: 10vh;
    top:-100%;
    border-radius: 10px;
    padding: 10px;
    font-size: .8rem;
}

.scrollDownRounded{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 6rem;
    width: 6rem;
    padding: 1rem;
    font-size: 1.2rem;
    text-align: center;
    position: absolute;
    left:50%;
    bottom:10%;
    font-weight: 500;
    background-color: var(--cl_blue);
    opacity: 0.8;
    cursor: pointer;
    -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
	        animation: pulsate-fwd 0.5s ease-in-out infinite both;
}

.sortDropDownAsync{
    width: 100%;
}

.sortDropDownAsyncBtn{
    width: 100%;
    outline: none;
    border:none;
    background-color: var(--cl_blue);
    color:white;
    font-weight: 500;
    height: 3rem;
}

@-webkit-keyframes pulsate-fwd {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes pulsate-fwd {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  

.scrollDownRoundedClose{
    display: none;
}

.jobCardTypeModalBox{
    background-color: white;
    height: 50vh;
    width: 30vw;
    border-radius: 20px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.jobCardTypeModalBoxTitle{
    font-size: 1.5rem;
    font-weight: 500;
}
.jobCardTypeModalBoxContent{
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}
.jobCardTypeModalBoxDiv{
    border: 1px solid var(--cl_blue);
    border-radius: 20px;
    width: 80%;
    padding: 4% 2%;
    margin-bottom: 1%;
    text-align: center;
    background-color: var(--cl_blue);
    color: white;
    font-weight: 600;
    cursor: pointer;
}
.jobCardTypeModalBoxDiv:hover{
    background-color: var(--cl_light_grey);
    color: black;
}