.intranet-main-layout {
    max-height: 80vh;
    padding: 0px 1vw;
}

.intranet-main-header {
    height: 50px;
    background-color: #fff;
    border-bottom: 1px solid rgb(235, 235, 235);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
}

.intranet-head-button {
    font-size: 20px;
    padding: 5px 15px;
    color: rgb(77, 77, 77);
    cursor: pointer;
}

.intranet-head-button-b {
    font-size: 16px;
    padding: 5px 15px;
    color: rgb(77, 77, 77);
    cursor: pointer;
}

.intranet-head-button:hover {
    background-color: rgba(141, 152, 156, 0.199);
    padding: 5px 15px;
    border-radius: 8px;
}

.intranet-head-button-b:hover {
    background-color: rgba(141, 152, 156, 0.199);
    padding: 5px 15px;
    border-radius: 8px;
}

.add-files-btn {
    display: flex;
    font-size: 16px;
    align-items: center;
    background-color: rgba(184, 227, 252, 0.753);
    color: black;
    border-radius: 30px;
    height: 30px;
    padding: 0px 3px;
    padding-right: 30px;
    margin-right: 10px;
    cursor: pointer;
}

.add-files-btn:hover {
    background-color: rgb(161, 219, 252);
}

.add-folder-btn {
    display: flex;
    font-size: 16px;
    align-items: center;
    background-color: rgba(168, 250, 195, 0.658);
    color: black;
    border-radius: 30px;
    height: 30px;
    padding: 0px 3px;
    padding-right: 30px;
    margin-right: 10px;
    cursor: pointer;
}

.add-folder-btn:hover {
    background-color: rgb(142, 243, 176);
}

.dlt-folder-btn {
    color: rgb(250, 116, 116);
    cursor: pointer;
}

.dlt-folder-btn:hover {
    color: rgb(248, 73, 73);
}

.edit-folder-btn {
    color: rgb(97, 96, 96);
    cursor: pointer;
    margin-left: 10px;
}

.edit-folder-btn:hover {
    color: rgb(43, 43, 43);
}

.intranet-main-body {
    max-height: 72vh;
    background-color: #fff;
    padding: 1%;
}

.body-main-title {
    font-size: 14px;
    color: gray;
    font-weight: 500;
    margin-top: 10px;
    margin-left: 15px;
}

.intranet-folder-layout {
    display: grid;
    column-gap: 1%;
    row-gap: 2%;
    grid-template-columns: repeat(auto-fill, minmax(12vw, 1fr));
    padding: 15px;
}

.intranet-main-folder-card {
    position: relative;
    height: 60px;
    width: 12vw;
    border-radius: 8px;
    border: 1px solid rgb(221, 221, 221);
    padding: 5px;
    font-size: 15px;
    display: flex;
    align-items: center;
    color: gray;
}

.intranet-main-folder-card:hover, .intranet-main-folder-card-selected {
    background-color: rgb(221, 221, 221);
    color: rgb(82, 81, 81);
}

.intranet-files-layout {
    display: grid;
    column-gap: 1%;
    row-gap: 1%;
    grid-template-columns: repeat(auto-fill, minmax(12vw, 1fr));
    padding: 15px;
    position: relative;
}

.intranet-main-file-card {
    height: 150px;
    width: 12vw;
    border-radius: 8px;
    border: 1px solid rgb(221, 221, 221);
    font-size: 15px;
    color: gray;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23e0dee4' fill-opacity='0.96'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
}

.intranet-main-file-card:hover, .intranet-main-file-card-selected {
    background-color: rgb(243, 243, 243);
    color: rgb(82, 81, 81);
}



.main-file-icon {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.main-file-title {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0px 5px;
    background-color:rgb(221, 221, 221);
    border-top: 1px solid rgb(221, 221, 221);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.intranet-folder-modal-layout {
    width: 30vw; 
    background-color: white; 
    border-radius: 10px;
    padding: 25px;
    outline: none;
}

.filesFolderCarousel{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 1% 0;
    background-color: var(--cl_ivery);
}

.intranet-modal-title-layout {
    display: flex;
    justify-content: space-between;
}

.intranet-input {
    width: 97%;
    height: 30px;
    margin-top: 30px;
    padding-left: 10px;
    border: 1px solid rgb(96, 96, 216);
    border-radius: 5px;
}

.intranet-input-desc {
    height: 60px;
    padding: 10px;
    
}

.memList-popOver{
    margin: 2%;
    height: 100px;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-between;
}

.memListItem{
    margin: 1% 0;
    cursor: pointer;
    display: flex;
    justify-content: left;
}

.memListItem:hover{
    background-color: lightgray;
}

.memListSelItem{
    margin: 1px 0;
    cursor: pointer;
    display: flex;
    justify-content: left;
    background-color: lightgreen;
}

.memListSelItem:hover{
    background-color: rgb(92, 216, 92);
}

.intranet-btn-layout {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}

.intranet-cancel-btn {
    height: 35px;
    width: 100px;
    margin-right: 20px;
    background-color: transparent;
    border-radius: 5px;
    border: 0;
    cursor: pointer;
}

.intranet-cancel-btn:hover {
    -webkit-box-shadow: 0px 2px 15px 2px rgba(0,0,0,0.27); 
box-shadow: 0px 2px 15px 2px rgba(0,0,0,0.27);
}

.intranet-create-btn {
    height: 35px;
    min-width: 100px;
    background-color: var(--cl_blue);
    border: 0;
    cursor: pointer;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    padding: 0 1%;
}


.intranet-files-modal-layout {
    height: 50vh;
    background-color: #fff;
    width: 50vw;
    border-radius: 10px;
    padding: 20px;
}

.singleFileDeleteIcon, .singleFileSelectIcon{
    position: absolute;
    margin: 5px 5px 5px 140px;
    padding: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--cl_ivery);
    cursor: pointer;
    opacity: 0;
    z-index: 5;
}

.intranet-main-file-card:hover .singleFileDeleteIcon, .singleFileSelectIcon{
    opacity: 1;
} 

.singleFileSelectIcon{
    margin-left: 170px;
}

.singleFolderDeleteIcon {
    position: absolute;
    right: 2%;
    top: 10%;
    /* margin: -22px 5px 5px 184px; */
    cursor: pointer;
}

.singleFolderDeleteIcon:hover {
    color: black;
}

.singleFolderEditIcon {
    position: absolute;
    right: 10%;
    top:10%;
    /* margin: -22px 5px 5px 160px; */
    cursor: pointer;
}

.singleFolderEditIcon:hover {
    color: black;
}

.singleFolderSelectIcon {
    position: absolute;
    margin: -22px 5px 5px 136px;
    cursor: pointer;
}

.singleFolderSelectIcon:hover {
    color: black;
}

.singleFolderSelectedIcon {
    position: absolute;
    margin: -22px 5px 5px 184px;
    cursor: pointer;
}

.singleFolderSelectedIcon:hover {
    color: black;
}

.infoFolderChip{
    position: absolute;
    top: 5px;
    right: 10px;
    /* margin: -22px 5px 20px 100px; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color:  var(--cl_ivery);
    font-size: 70%;
    padding: 2px 5px;
}

.fileExpiryChip{
    position: absolute;
    background-color: rgb(255, 125, 125);
    color: black;
    font-weight: 500;
    font-size: .7rem;
    max-width: 50%;
    padding: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    left: 0;
    text-align: center;
    top: 0;
    border: .5px solid white;
}

.intrnet-modal-title-description{
    font-size: 1rem;
    height: 3vh;
}
.folder-title{
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: wrap;
    width: 100%;
    white-space: nowrap;
}