
.accountsTableDiv{
    padding: 0 2%;
    display: flex;
    flex-direction: column;
}

.invoiceLayout{
    display: flex;
    flex-direction: column;
}

.accountsTableHeading{
    font-weight: 500;
    font-size: 18px;
    padding: 10px 0;
}
.accountsTableSec{
    height: auto;
    position: relative;
}
.accountsTableSec > th{
    font-size: 16px;
    padding: 10px 0;
}
.accountsTableSec > tbody > tr > td{
    font-size: 14px;
    padding: 5px 0;
    cursor: pointer;
    text-align: center;
}
.accountTableStatusBtn, .accountTableStatusBtnDis{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    color: white;
    font-weight: 500;
    background-color: red;
    width: 90%;
    height: 3vh;
    border-radius: 20px;
    cursor: pointer;
    margin: 0 5%;
}

.accountTableStatusBtn:hover{
    background-color: rgb(250, 116, 116);
}

.jobRescheduleDivLayout{
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
    width: 60%;
    height: 70%;
}

.jobRescheduleDividedSection{
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-radius: 20px;
    width: 95%;
    height: 90%;
}

.jobRescheduleContentDiv{
    width: 50%;
}

.jobRescheduleTopDiv{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: whitesmoke;
}

.jobRescheduleJobCodeList{
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.jobRschdJobCodeListBtn, .jobCodeChipDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: var(--cl_blue);
    color:white;
    width: 60%;
    height: 3vh;
    margin: 5% 0;
    cursor: pointer;
    padding: 5px 0;
}



.jobCodeChipDiv{
    width: 90%;
    margin: 5%;
}

.jobRschdJobCodeListBtn:hover, .jobRschdJobCodeListBtnSelected{
    background-color: var(--cl_ivery);
    color:var(--cl_blue);
}

.jobRescheduleBtn, .jobRescheduleBtnDisable{
    background-color: var(--cl_blue);
    color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    border-radius: 20px;
}

.jobRescheduleBtnDisable{
    opacity: 0.6;
    cursor: none;
}

.shdl-Leave-emp-body {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    width: 100%;
    height: 100%;
}

.jobRescheduleTeamList{
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, 70px);
    padding: 20px;
}

.shdl-single-team-circle {
    height: 60px;
    width: 60px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shdl-single-team-circle img {
    height: 50px;
    width: 50px;
    background-color: #fff;
    border-radius: 50%;
}

.inactiveCircle{
    opacity: 0.3;
    cursor: default;
}

.selectJobWarn{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 5%;
}

.accountsTableNavDiv{
    display: flex;
    flex-direction: row;
    margin: .7rem;
    font-size: 1rem;
}

.accountsTableNavBtn{
    display:flex;
    align-items: center;
    justify-content: center;
    padding: .8rem;
    border-radius: 30px;
    border: 1px solid var(--cl_grey);
    margin: 0 .5rem;
    background-color: white;
    font-weight: 500;
}

.accountsTableNavBtnActive{
    background-color: var(--cl_blue);
    color: white;
}

.stickyFooterTable{
    display: flex;
    flex-direction: row;
    background-color: rgb(172, 169, 169);
    bottom: 0%;
    padding: 1% 2%;
    border-radius: 0 0 10px 10px;
    justify-content: space-between;
    align-items: center;
}

.complJobTemplateModal{
    position: fixed; /* Stay in place */
z-index: 10; /* Sit on top */
padding-top: 1px; /* Location of the box */
left: 0;
top: 0;
width: 100%; /* Full width */
height: 100%; /* Full height */
overflow: auto; /* Enable scroll if needed */
display: flex;
align-items: center;
justify-content: center;
background-color: rgba(0,0,0,0.4);

}

.accDateFilterTab{
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    justify-content: space-between;
    align-items: center;
    width: 25%;
    border-radius: 30px;
    padding: .5%;
    background-color: whitesmoke;
}
.accDateFilterDateTab{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1%;
}

.scopOfWorkEditorPopclose{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 5vh;
    background-color: var(--cl_light_grey);
}
.scopOfWorkEditorPop{
    height: 40vh;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    background-color: var(--cl_light_grey);
    /* min-width: 60%; */
}

.scopOfWorkEditorPop > div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    margin-left: 1%;
}

.scopOfWorkEditorPop p{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width:40%;
    margin-left: 1%;
}
.scopOfWorkEditorPop input{
    background-color: transparent;
    border : none;
    border-bottom: .5px solid grey;
    margin-left: 1%;
    width: 60%;
}
.scopOfWorkEditorPop textarea{
    background-color: rgba(0,0,0,0.1);
    border: .5px solid grey;
    margin-left: 1%;
    height: 90%;
}
.scopeOfWorkEditorPopBtn{
    background-color: var(--cl_blue);
    border-radius: 10px;
    padding: 1%;
    color: white;
    /* width: 4rem !important; */
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
}
.pageEndScrollEffect{
    position: absolute;
    bottom: 0;
    background: rgb(0,219,255);
background: linear-gradient(0deg, rgba(0,219,255,1) 0%, rgba(253,187,45,0) 85%);
    width: 100%;
    left: 0;
    height: 0vh;
    z-index: 1;
    border-radius: 70% 70% 0 0;
    transition: height 1s ease-out;
}
.pageEndScrollEffectIncr{
    height: 5vh;
    transition: height 1s ease-in;
}

.invoiceTableTdTh > td, th{
    width:'auto';
    max-width: 18vw;
}

.commentsBoxModalContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    background-color: var(--cl_ivery);
    width: 70vw;
    height: 60vh;
    overflow-y: auto;
}
.commentsBoxModalHeader{
    font-size: 1.3rem;
    height: 2vh;
    padding: 2%;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.commentsBoxCommentsListSection{
    width: 95%;
    border-radius: 20px;
    margin-bottom: 1%;
    background-color: white;
}
.CMBCMessageChip{
    width: 99%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.CMBCsentUserMessageChip, .CMBCUserMessageChip{
    border-radius: 20px;
    background-color: rgba(15, 153, 208,.1);
    margin: .5%;
    padding: 2%;
    position: relative;
    font-size: 1rem;
    border-bottom-left-radius: 0;
    max-width: 60%;
    border : 1px solid var(--cl_blue);
    cursor: pointer;
}
.CMBCUserMessageChip{

    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 0;
}

.CMBCsentUserMessageChipName, .CMBCUserMessageChipName{
    height: 2vh;
    font-size: .9rem;
    font-weight: 600;
    color : var(--cl_blue);
}
.CMBCsentUserMessageChipMsg, .CMBCUserMessageChipMsg{
    padding: 1% 0;
}
.CMBCsentUserMessageChipTime, .CMBCUserMessageChipTime{
    font-size: .8rem;
    font-weight: 300;
    float: right;
    color: grey;
}
.commentsBoxCommentsAddSection{
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid red; */
}
.commentsBoxCommentsAddSectionInpdiv{
    padding: 2%;
    background-color: white;
    border-radius: 30px;
    height: 1vh;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.commentsBoxCommentsAddSectionInpdiv > textarea{
    /* border: 1px solid green; */
    outline: none;
    width: 100%;
    height: 4vh;
    resize: none;
    border: none;
}
.commentsBoxCommentsAddSectionSenddiv{
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px solid var(--cl_blue);
    background-color: var(--cl_blue);
    color:'white';
    cursor: pointer;
}
.commentsBoxCommentsAddSectionSenddiv:hover{
    background-color: white;
    color: var(--cl_blue);
    fill:var(--cl_blue);
}
.commentsBoxCommentsAddSectionSenddiv:hover > svg{
    color: var(--cl_blue);
    fill:var(--cl_blue);
}

.CMBCMessageChipEditDelBox{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 20px;
    color: white;
    display: flex;
    flex-direction: row;
    background-color: rgba(15, 153, 208,.7);
    padding: .5%;
    opacity: 0;
}

.CMBCMessageChipEditDelBox > div{
    width: 50%;
    display: grid;
    place-content: center;
    border-radius: 20px;
    border: 1px solid transparent;
}

.CMBCMessageChipEditDelBox > div:hover{
    background-color: white;
    color: var(--cl_blue);
}

.CMBCMessageChipEditDelBox > div:first-of-type{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.CMBCMessageChipEditDelBox > div:last-of-type{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.invoiceButtonNotifyDrop{
    position: absolute;
    width: .6rem;
    height: .6rem;
    border-radius: 50%;
    background-color: red;
    top: 0;
    right: 0;
    border : 1px solid white;
}

.invoiceButtonNotifyDropBlue{
    position: absolute;
    width: .6rem;
    height: .6rem;
    border-radius: 50%;
    border : 1px solid white;
    background-color: var(--cl_blue);
    top: 0;
    right: .5rem;
}