.jobs-main-layout {
    height: 75vh;
    display: flex;
    font-size: 12px;
}

.jobCardNameDiv{
    display: flex;
    flex-direction: row;
    width: 70%;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.2rem;
}

.jobCardNameDiv > p{
    margin-right: 1%;
    font-weight: 500;
}

.jobCardNameDiv > input{
    font-weight: 400;
    font-size: 1rem;
    width: 60%;
}

.editPencilIcon{
    padding: 1%;
    cursor: pointer;
}

.editPencilIcon:hover{
    color: white;
    background-color: grey;
    border-radius: 50%;
}

.checkCircIcon{
    padding: .1%;
    cursor: pointer;
    color:green;
}

.checkCircIcon:hover{
    color: white;
    background-color: green;
    border-radius: 50%;
}


.jobs-left-layout {
    flex: 1;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.jobs-right-layout {
    flex: 1;
    height: 100%;
    padding: 15px;
}

.jobs-client-layout {
    border: 1px solid grey;
    margin-top: 10px;
    display: flex;
    padding: 10px;
}

.jobs-client-left {
    flex: 2;
}
.jobs-special-text {
    display: flex;
    flex: 1;
    font-weight: bold;
    color: grey;
    line-height: 20px;
}

.jobs-client-map {
    flex: 1;
    height: 100%;
    background-color: grey;
}

.job-client-layout-b {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.job-client-image-layout img {
    height: 70px;
    border-radius: 5px;
}

.job-client-b-info {
    margin-left: 20px;
    width: 100%;
}

.jobs-schedule-layout {
    border: 1px solid grey;
    margin-top: 10px;
    padding: 10px;
}

.jobs-team-member-layout {
    margin-top: 20px;
    display: grid;
    width: 320px;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 90px);
}

.jobs-team-main-flex {
    display: flex;
}

.jobs-schedule-galary {
    height: 100%;
    flex: 1;
}

.job-img-a {
    display: grid;
    grid-template-columns: 130px 130px;
    justify-content: center;
    grid-gap: 10px;
}

.jobs-img {
    width: 90%;
    height: 150px;
    margin: 5px;
}

.jobs-img-b {
    width: 120px;
    margin: 5px;
    height: 160px;
    border-radius: 5px;
    cursor: pointer;
}

.jobs-img-btn-lay {
   display: flex;
   justify-content: center; 
}

.jobs-img-btn-lay button {
    height: 35px;
    width: 120px;
    border-radius: 20px;
    border: 1px solid grey;
    margin: 0px 5px;
 }

 .jobs-right-tableLayout{
     height: 50vh;
     border: 1px solid grey;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
 }

 .jobs-rightTableHead{
     background-color: var(--cl_blue);
     color: white;
     font-size: 15px;
     font-weight: 500;
     padding:5px;
     height: 2vh;
     
 }

 .jobsTableDetails > tr > td {
     background-color: var(--cl_ivery);
     border-radius: 10px;
 }

 .jobsTableFooterDiv{
     display: flex;
     flex-direction: column;
 }

 .jobsTableFooterAmnt{
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     height: 6vh;
     padding: 0 20px;
     font-weight: 600;
     align-items: center;
 }

 .jobsTableFooterBtnDiv{
     display: flex;
     flex-direction: row;
     justify-content: left;
     align-items: center;
 }

 .jobsTableExportBtn{
     width: 5vw;
     height: 1vh;
     background-color: orange;
     color: white;
     font-weight: 600;
     justify-content: center;
     align-items: center;
     padding:20px;
     margin: 0 10px;
     margin-bottom: 10px;
     cursor: pointer;
     border-radius: 10px;
 }

 .jobsTableRRtab{
    height: 15vh;
    border: 1px solid grey;
   margin-top: 10px;
   display: flex;
   flex-direction: row;
   padding: 10px;
 }

 .jobsTableReviewDiv{
     background-color: var(--cl_ivery);
     width: 20vw;
     display: flex;
     flex-direction: column;
     justify-content: left;
        padding: 10px; 
 }

 .jobsReviewHead{
     color:black;
     padding-bottom: 2px;
     font-weight: 600;
 }

 .jobsREviewTabInput{
     background-color: white;
     border-radius: 30px;
 }

 .jobsREviewTabInput textarea{
     border: none;
     margin: 20px;
     margin-bottom: 10px;
 }

 .jobRatingDiv{
     width: 20vw;
     padding: 10px;
     display: flex;
     flex-direction: column;
     align-items: flex-end;
     justify-content: space-between;
 }

 .jobRatingStatusBtn{
     height: 5vh;
     width: 10vw;
     display: flex;
     color: white;
     font-size: 20px;
     font-weight: 600;
     justify-content: center;
     align-items: center;
     background-color: var(--cl_blue);
     cursor: pointer;
 }

 .thisIsNotAvailable{
     opacity: 0.5;
     cursor: default;
 }

 .jobsAddNewTab > td{
     background-color: white;
 }

 .jobsearchAddressSite{
    width: 100%;
}

 .jobCardDivInpContent{
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
}
.jobCardDivInpContentDiv, .jobCardDivInpContentDivTOW{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1px 2px;
}
.jobCardDivInpContentDiv > div, .jobCardDivInpContentDivTOW > div{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.jobCardDivInpContentDiv > div > span, .jobCardDivInpContentDivTOW > div > span{
    margin: 0 10px;
}

.jobCardDivInpContentDivTOW{
    height: 5vh;
}

.weekDayCheckBox > span{
    border-right: 3px solid grey;
    padding-right: 2%;
}



.jobCardDivInp{
    border: none;
    border-bottom: 1px solid black;
    width: 100%;
    height: 5vh;
}

.jobCardDivInpTextArea{
    border: none;
    outline: none;
    padding: 1vh;
    border-radius: 10px;
    border: 1px solid rgb(164, 222, 240);
    background-color: rgba(164, 222, 240, 0.2);
    resize: vertical;
    font-family: inherit;
    width: 100%;
    height: 9vh;
}



.jobCardDivTableContent{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.jobCardDivTHead{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.jobCardDivTHead > div{
    width: 20%;
}
.jobCardFooterContent{
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
    align-items: center;
}
.jobCardFooterContent > div{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.jobCardFooterContent > div > span{
    margin-right: 10px;
}


.delBtnTop{
    background-color: red;
    color: white;
}


.delBtnTop:hover{
    background-color: rgb(243, 169, 169);
}


    .selectBoxHead{
        background-color: var(--cl_blue);
        color: white;
        align-items: center;
    }
    .selectBoxHead select{
        margin-bottom: 0;
        color: white;
        background-color: transparent;
    }

    .selectBoxHead select > option{
        color: black;
    }

.jobCardFileListDiv{    
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15vw, 1fr));
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 20vh;
    background-color: whitesmoke;
}
.jobCardFileDiv{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: 1vh;
    height: 4vh;
}
.jobCardFileAddBtn > label{
    position: relative;
}
.jobCardFileAddBtn > label > input[type='file']{
    opacity: 0;
    width: 100%;
    cursor: pointer;
    position: absolute;
    right: 0;
    top:0;
}
.jobCardFileAddBtn > label > button{
    background-color: var(--cl_blue);
    padding: 1%;
    border-radius: 10px;
    color:white;
    border: none;
    outline: none;
    height: 3vh;
    width: 8vw;
    cursor: pointer;
}

.jobCardFileAddBtnInactive > label > button{
    background-color: var(--cl_grey);
    opacity: 0.3;
    cursor: default;
    pointer-events: none;
}
.jobCardFileAddBtnInactive > label > input[type='file']{
    cursor: default;
    pointer-events: none;
}

.jobCardFileExt{
    background-color: aliceblue;
}

.groupAllRadioBracket{
    border-style:solid;
    border-width:1px 0 1px 20px;
    border-image:url(../../../assets/Images/curly.png) 1 20 stretch;
    -webkit-border-image:url(../../../assets/Images/curly.png) 1 20 stretch;
    -o-border-image: url(../../../assets/Images/curly.png) 1 20 stretch;
    -moz-border-image: url(../../../assets/Images/curly.png) 1 20 stretch;
    padding-left:0.5em;
}

.jobCardFieldDisabled{
    pointer-events: none;
    cursor: default;
    background-color: var(--cl_ivery);
    opacity: 0.5;
}


.jobCardFieldDisabled input{
    background-color: transparent;
    border: none;
}

.jobCardMultilineEntryManage{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    align-items: flex-start;
    margin-top: 1%;
}
.jobCardMultilineEntryManage > div{
    color:white;
    font-size: .9rem;
    background-color: rgb(15, 153, 208, 0.8);
    padding: 2%;
    border-radius: 20px;
    margin: .5%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.jobCardMultilineEntryManage > div > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    border-radius: 20px;
    cursor: pointer;
    margin: 0 .5%;
}