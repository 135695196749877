.scheduling-main-layout {
    height: 80vh;
    display: flex;
    font-size: 12px;
    flex-direction: row;
}

.swipeDsgnTop,
.swipeDsgnBottom {
    background-color: var(--cl_blue);
    height: 47%;
    width: 2%;
    position: absolute;
}

.swipeDsgnTop {
    top: 0;
    right: 0;
    border-radius: 30px 0 0 0;
    z-index: 100;
}

.swipeDsgnBottom {
    bottom: 0;
    right: 0;
    border-radius: 0 0 0 30px;
    z-index: 100;
}

.swipeBtn {
    position: absolute;
    background-color: var(--cl_blue);
    height: 2%;
    padding: 2% 1%;
    border-radius: 30px 0 0 30px;
    top: 47%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    right: 0;
    color: white;
    z-index: 100;
    font-size: .8rem;
}

.swipeDsgnTopLeft,
.swipeBtnLeft,
.swipeDsgnBottomLeft {
    left: 0;
    right: auto;
}

.swipeDsgnTopLeft {
    border-radius: 0 30px 0 0;
}

.swipeDsgnBottomLeft {
    border-radius: 0 0 30px 0;
}

.swipeBtnLeft {
    border-radius: 0 30px 30px 0;
    flex-direction: row-reverse;
}

.jobListMaxModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    border-radius: 20px;
    height: 70vh;
    width: 90vw;
    padding: 1%;
}

.jobListMaxModalHide {
    display: none;
}

.scheduing-left-layout {
    flex: 2;
    padding: 0px 10px;
    width: 40%;
}

.shdl-jobList-search-anime {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: .3rem;
    background-color: var(--cl_blue);
    background-image: linear-gradient(90deg,
            #0f99d0 50%,
            aliceblue 50%, aliceblue 100%);
    background-size: 20rem 1rem;
    animation: searchShine 20s infinite linear forwards;
    z-index: 1;
}

@keyframes searchShine {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.shdl-jobList-search, .shdl-jobList-search-new {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    padding: .5rem;
    border-radius: 20px;
    background-color: white;
}

.shdl-jobList-search input, .shdl-jobList-search-new input {
    height: 100%;
    border: none;
    background-color: transparent;
}

.shdl-jobList-search-new input{
    width: 80%;
}

.shdl-list-status {
    height: 10px;
    width: 10px;
    background-color: green;
}

.shdl-list-status-pnd {
    height: 10px;
    width: 10px;
    background-color: red;
}

.shdl-jobs-layout,
.shdl-jobs-Footer-layout {
    height: 50px;
    background-color: rgb(223, 223, 223);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    font-size: 1rem;
    border-radius: 10px;
}

.shdl-jobs-Footer-layout {
    justify-content: space-between;
    height: 30px;
    padding: 5px;
}

.shdl-jobs-footer-sort {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background-color: white;
    border-radius: 5px;
    padding: 3px;
    height: 80%;
    width: 100%;
}


.shdl-jobs-footerDivTab {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: lightgrey;
}

.shdl-jobs-footer-btn {
    border-radius: 5px;
    background-color: var(--cl_blue);
    color: white;
    height: 90%;
    padding: 1%;
    width: 15rem;
    cursor: pointer;
}

.shdl-jobs-footer-btn:hover {
    background-color: var(--cl_ivery);
    color: var(--cl_blue);
}

.shdl-jobs-layout-tabMainDiv {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.shdl-jobs-tabDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    background-color: transparent;
    width: 100%;
    cursor: pointer;
    position: relative;

}

.shdl-jobs-tabDiv:hover {
    background-color: lightgray;
    border-radius: 10px 10px 0 0;

}

.counterChip {
    /* position: absolute; */
    background-color: var(--cl_blue);
    color: white;
    font-weight: 500;
    border-radius: 50%;
    padding: 1%;
    /* width: 1.4rem;
    height: 1.4rem; */
    min-width: 1.4rem;
    height: 1.4rem;
    max-width: 2.9rem;
    text-align: center;
    display: grid;
    place-items: center;
    /* top: -0.2rem; */
    /* right: 0; */
    font-size: .8rem;
    margin-left: 5px;
}

.shdl-jobs-tabDivActive {
    height: 25px;
    margin-top: 5px;
    border-radius: 15px 15px 0px 0px;
    border-bottom: 5px solid grey;
}

.shdl-jobs-action-btn-inactive {
    width: 90px;
    border-radius: 20px;
    height: 24px;
    background-color: red;
    border: 0;
    opacity: 0.6;
    color: white;
    font-weight: 400;
    pointer-events: none;
}

.shdl-jobs-action-btn-active,
.shdl-jobs-action-btn-activeExtraBtn {
    width: 90px;
    border-radius: 20px;
    height: 24px;
    background-color: #79e882;
    border: 0;
    cursor: pointer;
}

.shdl-jobs-action-btn-activeExtraBtn {
    min-width: 20vw;
    font-weight: 600;
}

.shdl-jobs-action-btn-active:hover {
    background-color: #50b558;
}

.shdl-jobs-action-btn-activeExtraBtn:hover {
    opacity: 0.6;
}

.shdl-jobs-frqDiv {
    margin: 1%;
    padding: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    border: 1px solid black;
    border-radius: 10px;
}

.shdl-modal-titles {
    font-size: 14px;
    margin: 5px 0px;
    font-weight: bold;
}

.shdl-pagi-m-a {
    background-color: rgb(180, 180, 180);
    border: 1px solid rgb(180, 180, 180);
    height: 18px;
    width: 18px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.shdl-pagi-m-b {
    border: 1px solid gray;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
    font-size: 12px;
}

.shdl-emp-layout {
    max-height: 35vh;
    margin-top: 10px;
    border-radius: 20px;
    background-color: #fff;
    overflow: hidden;

}

.shdl-jobs-table {
    height: 34vh;
    background-color: white;
    border-radius: 2px;
    position: relative;
}

.shdl-jobs-table::-webkit-scrollbar {
    width: .9rem;
}

.shdl-jobs-table::-webkit-scrollbar-track {
    background: var(--cl_ivery);
}


.rsc-thumb-vertical {
    background: var(--cl_grey);
    border-radius: 10px;
}

.jobListTableContentRw>td:nth-child(4) {
    background-color: whitesmoke;
}

.shdl-emp-header {
    height: 5vh;
    background-color: #fff;
    display: flex;
    align-items: center;
}

.shdl-emp-btn {
    height: 30px;
    border-radius: 20px;
    border: 1px solid gray;
    padding: 0px 25px;
    margin-left: 10px;
    cursor: pointer;
}

.shdl-emp-btn:hover {
    border: 1px solid black;
    color: white;
    background-color: black;
}

.shdl-emp-btn-active {
    border: 1px solid black;
    color: white;
    background-color: black;
}

.shdl-emp-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    margin-top: 10px;
}

.shdl-emp-bodyLoader>p {
    height: 1vh;
    border-radius: 10px;
}

.shdl-emp-bodyLoader>p,
.shdl-emp-bodyLoader>div {
    background: #bdbcbc;
    background-image: linear-gradient(to right,
            #bdbcbc 0%,
            #f5f3f3 20%,
            #bdbcbc 40%,
            #bdbcbc 100%);
    border: 1px solid var(--cl_ivery);
    animation: shine 20s infinite linear forwards;

}

@keyframes shine {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.shdl-emp-body-tooltipbody .shdl-quote-box-item-tooltip {
    visibility: hidden;
    /* width: 100px; */
    background-color: rgb(37, 37, 37);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 125%;
    white-space: pre-wrap;
}


.shdl-emp-body:hover .shdl-quote-box-item-tooltip {
    visibility: visible;
}

.shdl-emp-body-tooltipbody .shdl-quote-box-item-tooltip::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
}


.scheduing-right-layout {
    flex: 5;
    padding: 0px 10px;
    overflow: auto;
    white-space: nowrap;
}

.shdl-main-row-hd {
    display: flex;
}

.shdl-col-1-hd {
    width: 150px;
    border: 0.5px solid gray;
    height: 35px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
}

.shdl-col-2-hd {
    border: 0.5px solid gray;
    height: 35px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
}

.shdl-main-row {
    height: 200px;
    display: flex;
}

.shdl-col-1 {
    width: 150px;
    border: 0.5px solid gray;
    display: flex;
    align-items: center;
    padding: 0px 10px;
}

.shdl-col-2 {
    border: 0.5px solid gray;
    display: flex;
    padding: 0px 10px;
}

.shdl-col-3 {
    margin: 2px 0px;
    background-color: rgb(224, 224, 224);
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    cursor: pointer;
}

.scheduing-right-layout-main {
    flex: 2;
    height: 80vh;
    background-color: #fff;
    border-radius: 10px;
    margin-right: 20px;
    margin-left: 10px;
}

.schdl-main-head {
    height: 40px;
    background-color: rgb(223, 223, 223);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
}

.schdl-main-head span {
    font-weight: bold;
    font-size: 16px;
}

.shdl-main-footer {
    height: 40px;
    background-color: rgb(223, 223, 223);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 10px;
    margin-top:10px;
}

.shdl-pagination-layout {
    display: flex;
}

.shdl-pagi-a {
    background-color: rgb(180, 180, 180);
    border: 1px solid rgb(180, 180, 180);
    height: 25px;
    width: 25px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.shdl-pagi-b {
    border: 1px solid gray;
    height: 25px;
    width: 25px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
}

.shdl-main-body {
    height: 70.4vh;
    transition: all 1s ease-out;
}

.shdl-single-week {
    width: 100px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 15px;
}

.shdl-col-single-filled {
    height: 250px;
    width: 200px;
    border: 1px solid grey;
    display: flex;
    flex-direction: column;
    margin: 4px;
}

.shdl-col-single-filled-min {
    /* height: 120px;
    width: 120px; */
    /* border: 1px solid grey; */
    display: flex;
    flex-direction: column;
    margin: 4px;
}

.shdl-single-hdr {
    height: 12.5%;
    background-color: rgb(219, 219, 219);
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500px;
    padding: 0px 10px;
    justify-content: space-between;
}

.shdl-single-footer {
    height: 13%;
    display: flex;
    font-size: 14px;
    font-weight: bold;
}

.shdl-single-amount-a {
    flex: 1;
    background-color: yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
}

.shdl-single-amount-b {
    flex: 1;
    background-color: green;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
    color: white;
}

.shdl-single-body {
    height: 84.5%;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.shdl-single-unfilled {
    height: 65px;
    width: 200px;
    background-color: rgb(197, 197, 197);
    border: 1px solid rgb(216, 216, 216);
    margin: 4px;
    border-radius: 6px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shdl-single-unfilled-min {
    height: 35px;
    width: 100%;
    word-wrap: break-word;
    background-color: rgb(197, 197, 197);
    border: 1px solid rgb(216, 216, 216);
    margin: 4px;
    border-radius: 6px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shdl-tst-scrool>th {
    /* display: flex;
    flex-direction: row; */
    padding: 0px 7px;
    position: sticky;
    top: 0;
    z-index: 3;
    border: none;
    background-color: transparent;
}


.shdl-single-unfilled p {
    font-weight: bold;
}

.shdl-single-unfilled-min p {
    font-weight: bold;
    font-size: 8px;
    word-break: break-all;
    word-wrap: break-word;
}

.shdl-modal-layout-mainn {
    outline: none;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    -webkit-box-shadow: 0px 4px 26px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 26px -2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 26px -2px rgba(0, 0, 0, 0.75);

}

.shdl-modal-layout {
    outline: none;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    display: flex;
}


.draggerHead {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--cl_ivery);
}

.shdl-modal-left-lay {
    margin: 0px 10px;
}

.shdl-modal-right-lay {
    margin: 0px 10px;
}

.shdl-modal-center-lay {
    font-size: 14px;
    font-weight: bold;
}

.shdl-modal-center-x-layout {
    width: 320px;
    height: 54vh;
    background-color: rgb(226, 226, 226);
    border-radius: 20px;
    margin-top: 20px;
    padding: 20px;
}

.shdl-modal-status {
    display: flex;
    margin-top: 10px;
}

.shdl-modal-status-box {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    background-color: green;
    margin: 0px 10px;
}

.shdl-modal-first-layout {
    width: 320px;
    background-color: rgb(226, 226, 226);
    border-radius: 20px;
    margin-top: 20px;
    padding: 20px;
}

.shdl-modal-first-layout input,
select {
    border: 0;
    border-bottom: 1px solid gray;
    background-color: transparent;
    width: 100%;
    margin-bottom: 10px;
    font-size: 12px;
}

.shdl-modal-first-layout p {
    font-size: 14px;
    font-weight: bold;
    margin-top: 15px;
}

.shdl-timeDate-field {
    cursor: pointer;
    padding: 8px;
}

.shdl-timeDate-field:hover {
    background-color: gray;
    border-radius: 20px;
    color: white;
}

.shdl-modal-shdl-btn button {
    height: 30px;
    width: 100px;
    border-radius: 30px;
    border: 1px solid black;
    margin: 20px 0px;
    cursor: pointer;
}

.shdl-modal-shdl-btn button:hover {
    background-color: black;
    color: white;
}

.shdl-modal-apply-btn {
    display: flex;
    justify-content: flex-end;
    margin-left: 20px;
}


.shdl-modal-apply-btn-dlt {
    display: flex;
    justify-content: flex-end;
    margin-left: 20px;
}

.shdl-modal-apply-btn-dlt button {
    height: 35px;
    width: 150px;
    border-radius: 30px;
    border: 1px solid var(--cl_red);
    margin: 20px 0px;
    color: var(--cl_red);
    cursor: pointer;
}


.shdl-modal-apply-btn button {
    height: 35px;
    width: 150px;
    border-radius: 30px;
    border: 1px solid var(--cl_blue);
    margin: 20px 0px;
    color: var(--cl_blue);
    cursor: pointer;
}

.shdl-modal-apply-btn button:hover {
    border: 1px solid var(--cl_blue);
    background-color: var(--cl_blue);
    color: white;
}

.shdl-modal-apply-btn-dlt button:hover {
    border: 1px solid var(--cl_red);
    background-color: var(--cl_red);
    color: white;
}

.shdl-team-lay {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fill, 60px);
    padding: 20px 10px;
}

.shdl-team-vehicle-lay {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
    height: 100%;
}

.shdl-vehicle-layItemSel,
.shdl-vehicle-layItem {
    display: flex;
    padding: 3px 6px;
    border-radius: 10px;
    align-items: center;
    justify-content: space-around;
    background-color: lightgray;
    color: black;
    font-weight: 400;
    width: 100%;
    height: 5vh;
    cursor: pointer;
    margin: 4px 0;
}

.shdl-vehicle-layItem:hover {
    background-color: gray;
}

.shdl-vehicle-layItemSel {
    background-color: white;
}

.shdl-single-team-circle {
    height: 60px;
    width: 60px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shdl-single-team-circle img {
    height: 50px;
    width: 50px;
    background-color: #fff;
    border-radius: 50%;
}

.inactiveCircle {
    opacity: 0.3;
    cursor: default;
}

.shdl-single-team-circle-b {
    height: 40px;
    width: 40px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shdl-single-team-circle-b img {
    height: 35px;
    width: 35px;
    background-color: #fff;
    border-radius: 50%;
}


.shdl-emp-modal-single {
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    justify-content: space-between;
}

.shdl-sortings-main-layout {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
}

.shdl-sortings-main-layout input {
    border: 0;
    background-color: transparent;
    border-bottom: 1px solid gray;
    margin-left: 10px;
    margin-right: 30px;
    font-size: 12px;
}

.shdl-sortings-main-layout button {
    border: 1px solid gray;
    border-radius: 20px;
    width: 60px;
    height: 22px;
    cursor: pointer;
}

.shdl-right-layout {
    margin-left: 10px;
    height: 63vh;
    width: 25vw;
    background-color: rgb(226, 226, 226);
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    margin-top: 20px;
}

.shdl-changedValuesBox {
    font-size: 12px;
    background-color: var(--cl_blue);
    padding: 8px;
    width: 250px;
    line-height: 18px;
    border-radius: 10px;
    color: white;
    margin-top: 5px;
    text-align: justify;
}

.shdl-comment-layout {
    height: 35vh;
    padding: 20px 15px;
}

.shdl-gallery-layout,
.shdl-sign-image-layout {
    flex: 2;
    padding: 15px;
}

.shdl-comment-main {
    display: flex;
    justify-content: center;
    align-items: center;
}

.shdl-comment-main input {
    width: 70%;
    border: 0;
    height: 30px;
    border-radius: 30px;
    padding: 0px 20px;
}

.shdl-comnt-lefing {
    font-size: 12px;
    display: flex;
    padding-left: 2%;
}

.shdl-comnt-righting {
    font-size: 12px;
    display: flex;
    justify-content: flex-end;

}

.shdl-comnt-box-layout {
    font-size: 12px;
    background-color: rgb(197, 207, 228);
    padding: 8px;
    width: 90%;
    line-height: 18px;
    border-radius: 10px;
    color: rgb(94, 94, 94);
    margin-top: 5px;
    text-align: justify;
    box-shadow:  -1px 1px 1.7px hsl(221deg 18% 37% / 0.42),
    -1.9px 2px 3.3px -1px hsl(221deg 18% 37% / 0.49),
    -5px 5px 8.5px -2px hsl(221deg 18% 37% / 0.56);
}

.shdl-gallery-grid, .shdl-gallery-grid-vid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 50px);
    padding: 10px;
    position: relative;
    padding-top: 10%;
    margin-bottom: 1%;
}

.shdl-gallery-grid-vid{
    grid-template-columns: repeat(auto-fill, 5rem);
}

.shdl-img-PicsDiv, .shdl-img-PicsDiv-vid {
    position: relative;
    border: .5px solid grey;
    border-radius: 5%;
    background: radial-gradient(circle,
            rgba(255, 255, 255, 0),
            rgba(0, 0, 0, 0.1) 50%,
            rgba(255, 255, 255, 0) 80%),
        rgb(238, 238, 236);
    /* background: linear-gradient(to right,
        rgba(255, 255, 255, 0),
        rgba(0, 0, 0, 0.1) 50%,
        rgba(255, 255, 255, 0) 80%
        ),
        rgb(238, 238, 236); */
    background-repeat: repeat-y;
    background-size: 150px;
    background-position: 0 0;
    animation: image-shine 2s linear infinite;
}

@keyframes image-shine {
    to {
        background-position: 100% 0;
    }
}

.shdl-img-dlt-btn {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    stroke: white;
}

.shdl-img-splPics {
    position: absolute;
    top: 0%;
    width: 95%;
    font-size: .8rem;
    background-color: var(--cl_blue);
    border-radius: 5px;
    padding: 1%;
    text-align: center;
    color: white;
}

.shdl-quote-modal-layout {
    max-width: 80vw;
    background-color: #fff;
    outline: none;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    height: 80vh;
    overflow: hidden;
    -webkit-box-shadow: 0px 4px 26px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 26px -2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 26px -2px rgba(0, 0, 0, 0.75);
    position: relative;
}

.shdl-quote-seperation {
    flex: 30%;
    border: 0.5px solid grey;
    margin: 10px;
    border-radius: 5px;
    padding: 5px;
    height: 70vh;
    overflow-y: auto;
    width: 40vw;
    transition: width 1s;
}

.maximiseWindow {
    /* position: absolute; */

    background: var(--cl_ivery);

    -webkit-animation: slide-out-left 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 0s both;
    animation: slide-out-left 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 0s both;
    /* left: -100%;
    position: absolute;
    transition: all 1s linear; */
    /* transition: 1s; */
}

.minimiseWindow {
    /* transition: 1s; */
    /* left: 0;

    transition: all 1s linear; */
    -webkit-animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0s both;
    animation: slide-in-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0s both;
}

.shdl-cmpltd-jobStatus{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1% 5%;
}

.shdl-cmpltd-jobStatusTime{
    padding: 1% 5%;

}
.shdl-cmplt-job-imgDel{
    display: none;
}
.shdl-img-splPicsDiv:hover > .shdl-cmplt-job-imgDel{
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    color: red;
    background-color: white;
} 

.shdl-cmpltd-finalFields-Box{
    display: flex;
    flex-direction: column;
    padding: 1% 5%;
}
.shdl-cmpltd-finalFields{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 1% 0;
}
.shdl-cmpltd-finalFields > div{
    width: 100%;
}

.shdl-cmpltd-finalFields > div > textarea, .shdl-cmpltd-finalFields > div > input{
    outline: none;
    border: none;
    width: 100%;
    resize: none;
    min-height: 3vh;
}

.shdl-cmpltd-jobStatusInformant, .shdl-cmpltd-jobStatusInformantAttention{
    font-size: .8rem;
    padding: 1% 5%;
    margin: 1%;
    border-radius: 10px;
    border:2px solid red;
    background-color: rgba(255,0,0,0.1);
}

.shdl-cmpltd-jobStatusInformant{
    border: 2px solid var(--cl_blue);
    background-color: rgba(15, 153, 208,0.1);
}

@-webkit-keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        position: absolute;
        opacity: 0;
    }
}

@keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        position: absolute;
        opacity: 0;
    }
}


@-webkit-keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}


.maximiseWindowBtn {
    background-color: var(--cl_blue);
    color: white;
    font-size: .9rem;
    padding: 1%;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    right: 0;
    top: 20%;
    border-radius: 10px 0px 0px 10px;
    z-index: 5;
    cursor: pointer;
}

@media (max-width:1000px) {
    .shdl-quote-seperation {
        flex: 100%;
    }
}

.shdl-quotes-special-text-layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shdl-quotes-special-text-main {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 10px;
    width: 400px;
}

.shdl-quotes-special-text-border {
    border: 1px solid grey;
    min-width: 100px;
    font-size: 12px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
}

.shdl-quote-info-main-layout {
    background-color: rgb(228, 227, 227);
    padding: 20px;
    border-radius: 10px;
}

.shdl-quote-sender-layout {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: rgb(228, 227, 227);
}

.shdl-quote-sender-layout img {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    object-fit: cover;
}

.shdl-quote-templ-EditBox {
    background-color: var(--cl_ivery);
    display: block;
    border-radius: 10px;
    margin: 1% 0;
    padding: 2%;
}

.shdl-quote-templ-EditBoxTitle {
    background-color: var(--cl_grey);
    color: white;
    padding: 1%;
    border-radius: 5px;
}

.shdl-quote-templ-EditBoxContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 1% 0;
}

.shdl-quote-templ-EditBoxBtn {
    display: block;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 1%;
    background-color: var(--cl_blue);
    color: white;
    margin-right: 1%;
    cursor: pointer;
}

.shdl-quote-templ-EditBoxBtn:hover {
    background-color: var(--cl_blue);
    opacity: 0.5;
}

.shdl-quote-sender-infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
}


.shdl-quote-info-box {
    max-width: 60vw;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
}

.quoteModalMemberChip {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    border-radius: 30px;
    width: fit-content;
}

.quoteModalMemberChip>p {
    margin-left: 1%;
    font-size: .7rem;
}

.shdl-quote-box-grid-files {
    /* display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 100px); */
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 30px;
}

.shdl-quote-box-item {
    height: 40px;
    width: 100px;
    border: 1px solid gray;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    position: relative;
}

.shdl-quote-box-item .shdl-quote-box-item-tooltip {
    visibility: hidden;
    width: auto;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    white-space: pre-wrap;
    margin-top: 2px;
    position: absolute;
    z-index: 1;
    top: 100%;
    /* left: 50%; */
    /* margin-left: -85px; */
    white-space: pre-wrap;
}


.shdl-quote-box-item:hover .shdl-quote-box-item-tooltip {
    visibility: visible;
}

.shdl-quote-box-item .shdl-quote-box-item-tooltip::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}





.shdl-quote-box-item-sp {
    height: 40px;
    width: 100px;
    border: 1px solid gray;
    border-radius: 10px;
    background-color: grey;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
}

.shdl-quote-job-layout {
    background-color: rgb(228, 227, 227);
    padding: 10px;
    border-radius: 5px;
}

.shdl-quote-schedule-layout {
    border: 1px solid grey;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.shdl-quote-timing-layout {
    display: flex;
    font-size: 12px;
    margin: 10px 0px;
    align-items: center;
}

.shdl-quote-timing-layout-driver {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 12px;
    margin: 10px 0px;
}

.shdl-quote-timing-layout-driver-content {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: space-between;
    font-size: 12px;
    margin: 10px 0px;
    align-items: flex-start;
}

.shdl-quote-timing-layout-layerOne,
.shdl-quote-timing-layout-layerTwo,
.shdl-quote-timing-layout-layerTwoEndLine,
.shdl-quote-timing-layout-layerOneEndLine {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.shdl-quote-timing-layout-layerOne::before,
.shdl-quote-timing-layout-layerTwo::before,
.shdl-quote-timing-layout-layerOneEndLine::before,
.shdl-quote-timing-layout-layerOneEndLine::before {
    content: "";
    background-color: var(--cl_grey);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 4px;
    width: 100%;
    z-index: -1;
}

.shdl-quote-timing-layout-layerOne::after,
.shdl-quote-timing-layout-layerTwo::after,
.shdl-quote-timing-layout-layerTwoEndLine::after,
.shdl-quote-timing-layout-layerOneEndLine::after {
    content: "";
    background-color: var(--cl_grey);
    position: absolute;
    top: 0%;
    right: 0;
    height: 3.02rem;
    width: 4px;
    z-index: -1;
}

.shdl-quote-timing-layout-layerOneEndLine::after,
.shdl-quote-timing-layout-layerTwoEndLine::after {
    background-color: var(--cl_blue);
    -webkit-animation: scale-up-ver-top 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-ver-top 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.shdl-quote-timing-layout-layerTwoEndLine::after {
    -webkit-animation: scale-up-ver-top 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 3s both;
    animation: scale-up-ver-top 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 3s both;
}

.shdl-quote-timing-layout-layerOne::after,
.shdl-quote-timing-layout-layerOneEndLine::after {
    bottom: 0;
    top: auto;
}

.shdl-quote-timing-layout-layerOne-progress,
.shdl-quote-timing-layout-layerTwo-progress {
    background-color: var(--cl_blue);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 4px;
    width: 0%;
    z-index: -1;
    transition: 0.4s ease;

}

.shdl-quote-timing-layout-layerOne-progress,
.shdl-quote-timing-layout-layerTwo-progress {
    background-color: var(--cl_blue);
}

.shdl-quote-timing-layout-layerTwo-progress {
    right: 0;
    left: auto;
}

.shdl-quote-timing-layout-stepCircle,
.shdl-quote-timing-layout-stepCircle-invalid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10%;
    width: 6rem;
    height: 4rem;
    padding: 2%;
    text-align: center;
    line-height: 1rem;
    background-color: var(--cl_blue);
    color: white;
    margin: 1% 0;
    position: relative;
}

.confettiAnime {
    position: absolute;
    z-index: 0;
    padding: 15px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-clip: border-box;
}

.confettiYellow {
    border: 10px dotted yellow;
    -webkit-animation: confettiPing 2s ease-in-out 3s infinite forwards;
    animation: confettiPing 2s ease-in-out 3s infinite forwards;
}

.confettiRed {
    border: 10px dotted rgb(208, 255, 0);
    -webkit-animation: confettiPing 3s ease-in-out 5s infinite forwards;
    animation: confettiPing 3s ease-in-out 5s infinite forwards;
}

.confettiGreen {
    border: 10px dotted greenyellow;
    -webkit-animation: confettiPing 5s ease-in-out 8s infinite forwards;
    animation: confettiPing 5s ease-in-out 8s infinite forwards;
}

@-webkit-keyframes confettiPing {
    0% {
        -webkit-transform: scale(0.2);
        transform: scale(0.2);
        opacity: 0.8;
    }

    80% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(2.2);
        transform: scale(2.2);
        opacity: 0;
    }
}

@keyframes confettiPing {
    0% {
        -webkit-transform: scale(0.2);
        transform: scale(0.2);
        opacity: 0.8;
    }

    80% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(2.2);
        transform: scale(2.2);
        opacity: 0;
    }
}

@-webkit-keyframes scale-up-ver-top {
    0% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
    }
}

@keyframes scale-up-ver-top {
    0% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
    }
}


.shdl-quote-timing-layout-stepCircle-invalid {
    background-color: var(--cl_grey);
}

.shdl-quote-timing-seperation {
    flex: 2;
    border: 1px solid grey;
    margin: 0px 20px;
    border-radius: 5px;
    display: flex;
    padding: 10px;
}

.shdl-quote-vehicle-layout {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: rgb(228, 227, 227);
}

.shdl-quote-vehicle-layout img {
    height: 60px;
    border-radius: 5px;
}

.shdl-quote-team-layout {
    display: flex;
}

.shdl-quote-tam-seperation {
    flex: 1;
    margin: 4px;
    padding: 8px;
}

.shdl-quote-team-list-layout {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid rgb(206, 206, 206);
    border-radius: 5px;
}

.shdl-quote-team-list-layout img {
    height: 55px;
    width: 55px;
    border-radius: 50%;
}

.shdl-quote-gallary-tabs {
    display: flex;
}

.shdl-quote-gallary-tabs button {
    height: 30px;
    flex: 1;
    margin: 2px;
    border: 0;
    border-radius: 20px;
    cursor: pointer;
}

.shdl-quote-gallary-btn-active {
    background-color: var(--cl_blue);
    color: white;
}

.shdl-quotes-gallery-grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, 90px);
    padding: 20px;
}

.shdl-quotes-gallery-grid>div {
    min-height: 50px;
    width: 90px;
    border-radius: 5px;
    border: .5px solid grey;
    background-color: aliceblue;
    position: relative;

    background: radial-gradient(circle,
            rgba(255, 255, 255, 0),
            rgba(0, 0, 0, 0.1) 50%,
            rgba(255, 255, 255, 0) 80%),
        rgb(238, 238, 236);
    /* background: linear-gradient(to right,
        rgba(255, 255, 255, 0),
        rgba(0, 0, 0, 0.1) 50%,
        rgba(255, 255, 255, 0) 80%
        ),
        rgb(238, 238, 236); */
    background-repeat: repeat-y;
    background-size: 150px;
    background-position: 0 0;
    animation: image-shine 2s linear infinite;
}

.shdl-quotes-gallery-grid>div>img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.shdl-quotes-gallery-gridImg-afterPic {
    border: 1px solid var(--cl_blue) !important;
}

/* .shdl-quotes-gallery-gridImg-afterPic > span{
        position : absolute;
        padding: 1%;
        left: 0;
        border-radius: 5px 5px 1px 1px;
        top: 0;
        background-color: var(--cl_blue);
        color: white;
        width: 100%;
        text-align: center;
    } */
.shdl-quotes-gallery-gridImg-beforePic {
    border: 1px solid var(--cl_green) !important;
}

.shdl-quotes-gallery-splPicsChip {
    /* position : absolute; */
    padding: 1%;
    left: 0;
    border-radius: 5px 5px 1px 1px;
    top: 0;
    background-color: var(--cl_green);
    color: white;
    width: 100%;
    text-align: center;
}

.shdl-quotes-comments-layout {
    padding: 20px;
}

.shdl-quotes-comments-item {
    padding: 10px 10px;
    background-color: rgb(202, 231, 250);
    margin: 5px;
    border-radius: 4px;
    font-size: 12px;
}

.jobCardButton {
    cursor: pointer;
}

.jobCardButton:hover {
    background-color: gray;
}

.jobCardModalBgDiv {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.jobCardModalBgDivClose {
    display: block;
}

.jobCardLayout {
    outline: none;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 50vw;
    height: 75vh;
    font-size: 16px;
    margin: auto;
    -webkit-box-shadow: 0px 4px 26px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 26px -2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 26px -2px rgba(0, 0, 0, 0.75);
}

.jobCardHeadContentDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.jobCardHeadContentDiv>div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20vw;

}

.jobCardHead {
    font-weight: 600;
    padding: 0 20px;
}

/* .jobCardEmphBox{
        display: flex;
        background-color: var(--cl_blue);
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        border:none;
        padding: 10px;
        color: white;
    } */

.jobCardContentDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}



/* .jobCardDivLeft, .jobCardDivRight{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 10px;
    }

    .jobCardDivLeft > div, .jobCardDivRight > div{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        padding: 5px 0;
    }

    .jobCardDivFooter, .jobCardDivFooter > div{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .jobCardDivFooter > div{
        width: 40%;
        padding: 5px;
    } */

.textAreaTitle {
    width: 70%;
    padding: 10px;
}

.textAreaTitle>textarea::placeholder,
.textAreaTitle>textarea::-webkit-input-placeholder,
.textAreaTitle>textarea:-ms-input-placeholder {
    color: white;
}

.jobCardModalSchList {
    position: fixed;
    /* Stay in place */
    z-index: 5;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    display: flex;
    justify-content: center;
    align-items: center;
}


.schdl-timing-box-main {
    padding: 10px 0px;
    display: flex;
}

.schdl-timing-box {
    width: 40px;
    background-color: #fff;
    border: 1px solid grey;
    border-radius: 5px;
    margin: 0px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    padding-top: 10px;
}

.schdl-timing-input {
    border: 0;
}

.newJobCardBtnActive {
    background-color: var(--cl_blue);
    color: white;
}

.greenContinueBtn {
    background-color: var(--cl_green);
}

.greenContinueBtn:hover {
    opacity: 0.7;
    ;
}


.cancelContinueBtn {
    background-color: var(--cl_grey);
}

.cancelContinueBtn:hover {
    opacity: 0.7;
    ;
}

.newJobCardBtnDisActive {
    opacity: 0.5;
    pointer-events: none;
}

.shceduler-container {
    height: 85vh;
    width: 97.7vw;
    overflow: scroll;
}

.vehicleFinderBtn {
    padding: 5px 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-align: center;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--cl_blue);
}

.vehicleFinderBtn:hover {
    opacity: 0.5;
}

.shdl-quote-chngValBoxes {
    border-radius: 10px;
    color: white;
    background-color: var(--cl_blue);
    padding: 1%;
    line-height: 1.5rem;
    width: 80%;
    margin-top: 1%;
}