.FilesDragAndDrop {
    position: relative;
    width: 100%;
    background-color: rgba(0,0,0,0.02);
}

.FilesDragAndDrop__placeholder, #FilesDragAndDropInput {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    background-color: #e7e7e7;
    border-radius: 12px;
    color: #7f8e99;
    font-size: 24px;
    opacity: .9;
    text-align: center;
    line-height: 1.4;
  }

.FilesDragAndDrop >.FilesDragAndDrop__area, .rpDropArea {
      width: 300px;
      height: 200px;
      padding: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column nowrap;
      font-size: 24px;
      color: #555555;
      border: 2px #c3c3c3 dashed;
      border-radius: 12px;  
}
.fileInputDrag{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  
  background-color: rgba(15, 153, 208,0.1);
  border : 1px dashed var(--cl_blue);
  border-radius: 10px;
  height: 10vh;
  width: 100%;
  position: relative;
}
.fileInputDrag input{
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.fileInputDrag > .ant-upload-wrapper{
  height: 100% !important;
}

.fileFolderCNPArea{
  /* grid-column-start: 1;
  grid-column-end: none; */
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  min-height: 15vh;
  background-color: rgba(15, 153, 208,0.05);
}

.fileFolderCNPArea:hover{
  opacity: 0.5;
  transition: all 700ms ease-in-out;
}

.ant-upload .ant-upload-btn{
  padding: 2px 0px !important;
}

.fileInputAnim {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 10px;
  background-image: linear-gradient(90deg,
          #0f99d050 50%,
          transparent 50%, transparent 100%);
  background-size:3rem;
  animation: fileUploadShine 20s infinite linear forwards;
  z-index: 1;
}

@keyframes fileUploadShine {
  0% {
      background-position: -468px 0;
  }

  100% {
      background-position: 468px 0;
  }
}