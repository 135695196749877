.profile-modal-main {
    width: 30vw;
    background-color: #fff;
    border-radius: 20px;
    padding: 30px;
    outline: none;
}

.profile-modal-head {
    display: flex;
    width: 100%;
}

.profile-modal-head img {
    height: 60px;
    width: 60px;
    border-radius: 10px;
    margin-right: 20px;
    cursor: pointer;
}

.profiler-title-layout {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.profiler-title {
    font-size: 16px;
    font-weight: bold;
    word-wrap: break-word;
}

.profiler-designation-title {
    font-size: 16px;
}

.profiler-text-input-layout {
    font-size: 12px;
    margin-top: 20px;
}

.profiler-text-input-layout input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid grey;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    padding-bottom: 5px;
}

.profiler-bottom-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.profiler-bottom-btn button {
    height: 35px;
    width: 140px;
    margin: 0px 10px;
    border: 0;
    border-radius: 20px;
    cursor: pointer;
}

.profiler-logout-btn {
    background-color: rgb(221, 140, 140);
}

.profiler-update-btn {
    background-color: var(--cl_blue)
}

.profiler-logout-btn:hover {
    background-color: rgb(233, 99, 99);
}

.profiler-update-btn:hover {
    background-color: rgb(28, 104, 148);
}

.devMsgModal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 20px;
    width: 30%;
    height: 30%;
}

.devMsgContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.devMsgTitle{
    display: flex;
    flex-direction: row;
    font-weight: 600;
    padding: 10px;
}

.devMsgDesc{
    font-size: 16px;
    font-weight: 400;
    padding: 10px;
}

.profiler-timesheetModalBtn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 3vh;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 20px;
    /* background-color: var(--cl_blue); */
    background-color: white;
    margin-right: 10px;
    cursor: pointer;
}

.profiler-timesheetModalBtn:hover{
    background-color: rgb(28, 104, 148);
    color: white;
}

.timeSheetLayout{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    width: 80vw;
    height: 80vh;
    border-radius: 20px;
    padding: 20px;
}

.timeSheetHead{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 10vh;
    font-size: 20px;
    font-weight: 400;
    width: 100%;
}

.timeSheetTab{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.timeSheetTab > span{
    font-size: 16px;
    font-weight: 400;
    padding: 0 10px;
}

.timeSheetTable{
    width: 100%;
    height: 100%;
    border: 0.5px solid black;
    border-collapse: collapse;
    font-size: 18px;
}
.timeSheetHeadDiv > th, .timeSheetFooterDiv > th{
    background-color: whitesmoke;
    height: 3vh;
}

.timeSheetFooterDiv > th > input{
    max-width: 2vw;
    border: none;
    outline: none;
    border-bottom: 1px;
    text-align: center;
}

.timeSheetTable > tbody > tr > td{
    
    font-size: 18px;
    font-weight: 300;
    word-break: break-all;
    width: 15vw;
    height: 1vh;
}

.timeSheetTable > tbody > tr > td > input, .dateStyleCSS{
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    height: 80%;
    width: 70%;
    border: none;
    border-bottom: 0.5px solid black;
    background-color: transparent;
}

.erroneousDateEnteredRow > .timePickerTd{
    border: 3px solid red;
}
.erroneousDateEnteredRow > .timePickerTd{
    background-color: rgba(211, 84, 0, 0.2);
}

.timeSheetFooter{
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: flex-start;
    justify-content: space-between;
}

.timeSheetFooter > div{
    display: flex;
    flex-direction: row;
    font-size: 18px;
    font-weight: 300;
    padding: 10px 0;
}

.timeSheetFooter > div > input{
    border: none;
    border-bottom: 0.5px solid black;
    font-size: 18px;
    font-weight: 300;
}

.timeSheetUpdateBtn, .timeSheetUpdateBtnDisable{
    height: 5vh;
    width: 9vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: var(--cl_blue);
    cursor: pointer;
    color: white;
    font-size: .9rem;
    font-weight: 800;
}

.timeSheetUpdateBtn:hover{
    background-color: #97cee4;
}

.timeSheetUpdateBtnDisable{
    opacity: 0.7;
    cursor: none;
}

.leaveApplyModalLayout{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    width: 60vw;
    height: 80vh;
    border-radius: 20px;
    padding: 20px;
    font-size: 18px;
}

.leaveApplyModalHeading{
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: center;
    justify-content: space-between;
    font-size: 30px;
    font-weight: 500;
    color: var(--cl_blue);
}

.leaveTypeCheckDiv{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.leaveCommentImp{
    color:red;
}

.leaveTypeCheckHead{
    padding: 10px 0;
    font-weight: 600;
}

.leaveTypeCheckBoxDiv{
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 0 10%;
    align-items: center;
    justify-content: space-between;
    background-color: whitesmoke;
}

.leaveTypeCheckBoxDiv > div{
    display: flex;
    flex-direction: row;
    padding: 20px 0;
    width: 90%;
    align-items: center;
    justify-content: flex-start;
}

.leaveTypeCheckBoxDiv > div > span{
    padding-right: 40px;
}

.leaveApplyDateDiv{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    font-weight: 600;
}

.leaveApplyDateSec{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    padding: 0 10px;
}

.leaveApplyTableDiv{
    background-color: whitesmoke;
    padding: 20px 0;
}

.leaveApplyTableDiv > p{
    font-weight: 600;
    padding: 10px;
}

.leaveApplyTable{
    border: 1px solid black;
    border-collapse: collapse;
}
.leaveApplyTable > tr{
    display: flex;
    flex-direction: row;
}
.leaveApplyTable > tr > th{
    width: 40%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.leaveApplyTable > tr > td{
    min-width: 20%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    border: .5px solid black;
    font-size: 14px;
}

.leaveApplyTable > tr > td > input{
    height: 70%;
    border: none;
    border-bottom: .5px solid black;
    background-color: transparent;
}

.leaveApplyComment{
    display: flex;
    flex-direction: row;
    width: 80%;
    padding: 10px;
}
.leaveApplyComment > span{
    width: 70%;
    padding: 0 20px;
}
.leaveApplyComment > span > input{
    border: none;
    width: 100%;
    border-bottom: 1px solid black;
}
.textAreaDiv{
    background-color: white;
}
.textAreaDiv > div > textarea{
    border: none;
    width: 100%;
}

.leaveApplyBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    width: 20vw;
    padding: 20px;
    background-color: var(--cl_blue);
    color:white;
    font-weight: 500;
    border-radius: 20px;
    cursor: pointer;
}

.leaveApplyBtnDisabled{
    opacity: 0.7;
    pointer-events: none;
}

.endOfListDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: .9rem;
    width: 100%;
}

.endOfListDiv > div{
    width: 40%;
    height: .1vh;
    display: flex;
    background-color: var(--cl_grey);
    border-radius: 10px;
}


.endOfListDiv > span::after, .endOfListDiv > span::before{
    content: '\2666';
    color: var(--cl_grey);
}

.teamVehDelPrevModalBox{
    border-radius: 20px;
    height: 50vh;
    width: 55vw;
    padding: 1%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: white;
}

.teamVehDelPrevModalContent{
    margin-top: 2%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    
}

.teamVehDelPrevModalContentLoaderPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-self: center;
    justify-self: center;
}

.teamVehDelPrevModalContentLoaderPage > p{
    margin: 1%;
    font-size: 1.3rem;
}


.teamVehDelPrevModalContentTable table > thead > th{
    font-size: 1rem;
}

.teamVehDelPrevModalContentTable  table > tbody > tr > td{
    font-size: .9rem;
    padding: 1% 0;
}
.errorDivParentModal{
position: fixed; /* Stay in place */
z-index: 1; /* Sit on top */
padding-top: 1px; /* Location of the box */
left: 0;
top: 0;
width: 100%; /* Full width */
height: 100%; /* Full height */
overflow: auto; /* Enable scroll if needed */
display: flex;
align-items: center;
justify-content: center;

}
.errorDivModal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 40vw;
    border-radius: 10px;
    background-color: white;
    -webkit-box-shadow: 0 35px 68px 0 rgb(139, 136, 136), inset 0 -8px 16px 0 rgb(199, 198, 198);
    -moz-box-shadow: 0 35px 68px 0 rgb(139, 136, 136), inset 0 -8px 16px 0 rgb(199, 198, 198);
    box-shadow: 0 35px 68px 0 rgb(139, 136, 136), inset 0 -8px 16px 0 rgb(199, 198, 198);
}

.errorDivHeadTab{
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: var(--cl_blue);
    border-radius: 10px 10px 0 0;
    color:white;
    font-weight: 600;
    font-size: larger;
}

.errorDivDescPara{
    max-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2% 5%;
    text-align: center;
    border-radius: 0 0 10px 10px;
}

.errorConsole{
    margin-top: 1%;
    height: 20vh;
    padding: 1%;
    text-align: left;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 0, 0, 0.39);
    border-radius: 10px;
}

.signpadModal{
    background-color: rgb(231, 230, 230);
    max-height: 40vh;
    min-height: 20vh;
    max-width: 40vw;
    min-width: 30vw;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}

.signpadModalHeading{
    width: 100%;
    font-size: 1.3rem;
    font-weight: 500;
    text-align: center;
}

.signpadModalButtonDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.signpadModalUpload, .signpadModalStencil{
    border-radius: 10px;
    background-color: var(--cl_green);
    padding: 3%;
    text-align: center;
    width: 10rem;
    cursor: pointer;
    color: white;
}
.signpadModalUpload:hover{
    opacity: .5;
}
.signpadModalStencil:hover{
    opacity: .5;
}
.signpadModalStencil{
    background-color: var(--cl_blue);
}

.signpadStyle{
    border : 1px solid black;
    border-radius: 30px;
    width: 70%;
    background-color:#97cee4;
}

.videoFileTileMain{
    width: 5rem;
    height: 5rem;
    position: relative;
}

.videoFileTileDel{
    position: absolute;
    right: 0;
    top:-10;
    z-index: 2;
}

.videoFileTile{
    position: relative;
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    border-radius: 10px;
    border: none;
}

.videoFileTilePlay{
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    color: white;
    border-radius: 50%;
    cursor: pointer;
}

.videoFileTileThumb{
    height: 100%;
}

.videoFilePreviewTileThumb{
    height: 5rem;
    width: 5rem;
}

.videoPlayerModalContent{
    width: 60vw;
    height: 60vh;
    padding: 1% 3%;
    background-color: black;
    border-radius: 30px;
    border: 2px solid white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-weight: 600;
    box-shadow:
  0px 2.5px 2.5px rgba(0, 0, 0, 0.1),
  0px 20px 20px rgba(0, 0, 0, 0.2)
;
}

.videoPlayerModalContent > video{
    width: 100%;
    height: 80%;
}