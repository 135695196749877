.add-client-right-main-layout{
    width: 100px;
    height: 200px;
    background-color: tomato;
}
.add-client-right-top{
    height: 6vh;
    display: flex;
}
.folder-btn, .folder-btnMainDiv, .folder-btnTab{
    width: 100px;
    height: 30px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    outline: none;
    background-color: white;
    cursor: pointer;
    text-decoration: none;
    border:solid 0.5px rgb(214, 214, 214);
    margin-right: 20px;
    transition: all 0.3s ease-in-out;
}

.folder-btnMainDiv{
    flex-direction: row;
    width: auto;
    justify-content: space-around;
    margin: 0;
}

.folder-btnTab{
    border-radius: 30px;
    border: none;
    width: 100px;
    margin: 0;
}

.folder-btn:hover, .folder-btnTab:hover{
    background-color: rgb(143, 219, 255);
    color: black;
}
.folder-btn-active, .folder-btn-activeTab{
    background-color: var(--cl_blue);
    color: white;
    
}

.folder-btn-active:hover{
    background-color: rgb(143, 219, 255);
    color: black;
}
.add-client-right-center{
    border-radius: 10px;
    background-color: #fff;
    border:solid 0.5px rgb(214, 214, 214);
}
.add-client-right-bottom{
    width: 100%;
    margin-top: 4px;
    height: 32vh;
    border-radius: 10px;
    background-color:white;
    border:solid 0.5px rgb(214, 214, 214);
}
/****************************************
  CLIENT CHART/GRAPH STYLE
*****************************************/
.client-chart-mainlayout{
   width: 95%;
   height: 38vh;
   padding: 10px;
}
.chart-main-layout-top{
    width: 100%;
    height: 17%;
    display: flex;
}
.chart-starting-btn-layout{
    width: 50%;
    display: flex;
    justify-content: space-between;
}
.chart-starting-btn{
    width: 100px;
    height: 30px;
    font-size: 10px;
    color: var(--cl_grey);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background-color: rgb(143, 219, 255);
}
.chart-ending-btn{
    width: 100px;
    height: 30px;
    font-size: 10px;
    color: var(--cl_grey);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background-color: rgb(143, 219, 255);
}
.chart-sales-in-layout{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: small;
    color: var(--cl_grey);
}
.chart-main-layout-bottom{
    width: 100%;
    height: 83%;
    display: flex;
}
/************Graph design from the roaster***********/
.Sales-management-graph-yaxis{
    width: 10%;
    height: 100%;
}
.Sales-management-graph-yaxis-top{
    width: 100%;
    height: 10%;
    display: flex;
}
.Sales-management-graph-yaxis-top-l{
    width: 90%;
    height: 100%;
    font-size: small;
    color: #666666;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Sales-management-graph-yaxis-top-r{
    width: 10%;
    height: 100%;
    border-bottom: 0.5px solid #FF8A23;
}
.Sales-management-graph-sales-layout{
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Sales-management-graph-sales{
    width: 40px;
    height: 20px;
    background-color: #109AD1;
    font-size: small;
    color: white;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Sales-management-graph-xaxis{
    width: 85%;
    height: 100%;
}
.Sales-management-graph-xaxis-top{
    width: 100%;
    height: 10%;
    border-left: 0.5px solid var(--cl_grey);
}
.Sales-management-line{
    width: 10px;
    height: 100%;
    border-bottom: 0.5px solid #FF8A23;
}
.Sales-management-graph-xaxis-center{
    width: 100%;
    height: 75%;
    border-left: 0.5px solid var(--cl_grey);
    border-bottom: 0.5px solid var(--cl_grey);
    display: flex;
    align-items: flex-end;
    justify-content: space-around;

}
.Sales-management-graph-month{
    width: 5%;
    background-color: #109AD1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Sales-management-graph-month:hover{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.sales-management-rotate{
    font-size: small;
    color: white;
    font-weight: 500;
    transform: rotate(-90deg);
}

.Sales-management-graph-xaxis-bottom{
    width: 100%;
    height: 15%;
    background-color: white;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
}
.Sales-management-graph-month-name-client{
    width: 5%;
    height: 100%;
    font-size: 13px;
    color: #666666;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
/****************************************
 FOLDER STYLE
*****************************************/
.folder-main-layout{
    width: 95%;
    height: 38vh;
}
.certificate-list-layout-client {
    height: 100%;
    margin: 10px;
}

.certificate-main-layout-client {
    width: 100%;
    display: inline-grid;
    grid-template-columns: auto auto auto auto;
}

.single-certificate-client{
    border: 1px solid #c4c3c0;
    height: 40px;
    margin: 8px;
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    padding: 0px 15px;
    justify-content: space-between;
}

.certificate-logo-layout-client {
    display: flex;
    align-items: center;
}

.certificate-logo {
    height: 40px;
    margin-left: 10px;
}

.certificate-view-layout-client{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    font-size: 10px;
}

/****************************************
  WORK-EXPAND STLES
*****************************************/
.client-work-layout{
    width: 95%;
    height: 94%;
    padding: 1% 2.5%;
    display: flex;
    /* background-color: whitesmoke; */
}
.client-work-layout-left{
    width: 100%;
    height: 90%;
}
.client-work-table-head{
    width: 100%;
    height: 30px;
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    color: gray;
    line-height: 10px;
}
.client-work-table-head > td{
    border: 1px solid gray;
}
.client-work-table-title1{
    width: 15%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
    line-height: 10px;
    border-left: .1px solid rgb(214, 211, 211);
    border-right: .1px solid rgb(214, 211, 211);
}
.client-work-table-title2{
    width: 15%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
    line-height: 10px;
    border-left: .1px solid rgb(214, 211, 211);
    border-right: .1px solid rgb(214, 211, 211);
}
.client-work-table-title3{
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
    line-height: 10px;
    border-left: .1px solid rgb(214, 211, 211);
    border-right: .1px solid rgb(214, 211, 211);
}
.client-work-table-title4{
    width: 15%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
    line-height: 10px;
    border-left: .1px solid rgb(214, 211, 211);
    border-right: .1px solid rgb(214, 211, 211);
}
.client-work-table-title5{
    width: 20%;
    height: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
    line-height: 10px;
    border-left: .1px solid rgb(214, 211, 211);
    border-right: .1px solid rgb(214, 211, 211);
}
.client-work-table-title6{
    width: 15%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
    line-height: 10px;
    border-left: .1px solid rgb(214, 211, 211);
    border-right: .1px solid rgb(214, 211, 211);
}

.tdButton{
    background-color: var(--cl_grey);
    padding: .3rem 0;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.tdButton:hover{
    background-color: var(--cl_light_grey);
    color: var(--cl_grey);
}

.sales-table-REshd{
    font-size: 1rem;
    width: 90%;
    margin: 1%;
}

.client-work-layout-right{
    width: 15%;
    height: 100%;
}
.client-work-bt1-layout{
    width: 100%;
    height: 30%;
}
.client-work-bt1{
    width: 70px;
    height: 25px;
    font-size: 10px;
    background-color: var(--cl_light_grey);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    margin: 10%;
    cursor: pointer;
    border:solid 0.5px rgb(214, 214, 214);  
}
.client-work-bt1:hover{
   background-color:#707070;
}
.client-work-bt2{
    width: 60px;
    height: 20px;
    font-size: 10px;
    color: var(--cl_grey);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15%;
    border-radius: 30px;
    cursor: pointer;
    border:solid 0.5px rgb(214, 214, 214);
    background-color: white;   
}
.client-work-bt3{
    width: 60px;
    height: 20px;
    font-size: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15%;
    border-radius: 30px;
    cursor: pointer;
    background-color:  #0F99D0;   
}
.client-work-bt4{
    width: 60px;
    height: 20px;
    font-size: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15%;
    cursor: pointer;
    border-radius: 30px;
    background-color: #FF8A23; 
}
.add-client-right-work-expand{
    width: 100%;
    height: 70vh;
    border-radius: 8px;
    background-color: white;
}



.add-client-right-work{
    width: 100%;
    height: 74.5vh;
    border-radius: 8px;
    background-color:white;
    display: flex;
    align-items: center;
    justify-content: center;
    border:solid 0.5px rgb(214, 214, 214);
}
.client-work-expand{
    width: 95%;
    height: 95%;
    display: flex;
}
.client-work-layout-search{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
}
.client-work-top-layout{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.client-worksearch-bar{
    width: 230px;
    height: 30px;
    background-color:var(--cl_light_grey);
    border:solid 0.5px rgb(214, 214, 214);
    border-radius: 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.client-worksearch-bar-input{
  border: none;
  width: 90%;
  height: 100%;
  background-color:var(--cl_light_grey);
}
.client-work-expand-bt1-layout{
    width: 100%;
    height: 70%;
}
.client-work-table-body-content{
    width: 100%;
    height: 30px;
    display: flex;
    font-size: 10px;
    font-weight: 500;
    color: var(--cl_orange);
    cursor: pointer;
}
.client-work-table-body-content > td{
    word-wrap: break-word;
}

.folder-spinner-layout {
    height: 100%; 
    width: 100%; 
    display: flex; 
    align-items: center; 
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    color: grey;
}

.clientAttachTableDiv{
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.clientAttachTableHeadDiv, .clientAttachTableBodyRowDiv{
    display: flex;
    flex-direction: row;
    min-height: 5vh;
    align-items: center;
}

.clientAttachTableHeadDiv > th, .clientAttachTableBodyRowDiv > td{
    min-width: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 5vh;
    padding: 2px 0;
}

.clientAttachTableBodyRowDiv > td{
    border-bottom: 1px solid rgb(143, 219, 255);
}

.clientAttachTableNavDiv{
    display: flex;
    align-items: center;
}

.clientAttachActBtns{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 16px;
    padding: 2px;
    cursor: pointer;
}

.clientAttachActBtns:hover{
    background-color: rgb(143, 219, 255);
    border-radius: 50%;
    padding: 2px;
}

.attachCardDivInpContent{
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
}
.attachCardDivInpContent > div{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1px 2px;
}
.attachCardDivInpContent > div > div{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.attachCardDivContLeft{
    width: 10vw;
}
.attachCardDivInpContent > div > div > span{
    margin: 0 10px;
}
.attachCardDivInp{
    border: none;
    /* border-bottom: 1px solid black; */
    width: 25vw;
    height: 5vh;
}
.clientAttachViewBtn{
    width: auto;
    height: auto;
    padding: 10px;
    background-color: lightgrey;
}

.clientAttachViewBtn:hover{
    background-color: rgb(143, 219, 255);
    width: auto;
    height: auto;
    padding: 10px;
}
