/* ****************************** */
/* H E A D E R    S T Y L E */
/* ****************************** */

.client-header {
    height: 15vh;
    display: flex;
    align-items: center;
    padding-left: 6vh;
    padding-right: 6vh;
}

.client-brand-layout {
    height: 50px;
    width: 60%;
}

.client-brand-img {
   height: 50px;
   padding: 0;
   margin: 0;
}

.client-back-btn-layout {
    height: 50px;
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.header-back-btn {
    height: 35px;
    width: 80px;
    border: 1px solid gray;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: var(--cl_grey);
    cursor: pointer;
}

.header-back-btn:hover {
    color: var(--cl_white);
    background-color: var(--cl_grey);
    border: 0
}

/* ****************************** */
/* T O P B A R    S T Y L E */
/* ****************************** */

.client-topbar-layout {
    height: 6vh;
    /* background-color: var(--cl_blue); */
    margin-left: 3%;
    margin-right: 4%;
    display: flex;
    justify-content: space-between;
}

.client-topbar-layout2 {
    min-height: 6vh;
    /* background-color: var(--cl_blue); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    align-self: center;
    justify-self: center;
    padding: 0% 5%;
}
.client-topbar-subsection{
    height: 6vh;
}
.client-topbar-subSection2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 6vh;
    align-items: center;
}

.topbar-left-layout {
    max-width: 30%;
    height: 100%;
    background-color: aliceblue;
    /* padding-left: 5px; */
}

.topbar-right-layout {
    max-width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.search-bar-layout {
    width: 20vw;
    height: 100%;
    display: flex;
    align-items: center;
    
}

.sort-layout {
    height: 80%;
    width: 14vw;
    background-color: var(--cl_white);
    border-radius: 30px;
    padding-left: 1vw;
    padding-right: 1vw;
    display: flex;
    justify-content: flex-end;
    border: 1px solid gray;
    margin-right: 1%;
}
.sort-layout .MuiSelect-select,.sort-layout .MuiMenuItem-root{
    font-size: .8rem;
}
.sort-select {
    font-size: 12px;
}

.sort-txt-layout {
    width: 40%;
    height: 100%;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.sort-dropdown-layout {
    width: 70%;
    height: 100%;
    font-size: 16px;
    font-weight: 500;
    color: var(--cl_blue);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}

.sort-icon {
    padding: 0;
    margin: 0;
    margin-left: 10px;
}

.add-client-layout, .client-tabBtn {
    height: 80%;
    width: 8vw;
    background-color: var(--cl_white);
    border: 1px solid gray;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.client-tabDiv, .client-tabDivInactive, .client-tabDiv-rt{
    width: 50%;
    margin-left: 1px;
    height: 98%;
    display: flex;
    padding: 0 10px;
    border-radius: 30px 0 0 30px;
    align-items: center;
    background-color: var(--cl_blue);
    color: white;
}

.client-tabDiv-span, .client-tabDivInactive-span{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: .8rem;
    padding: 4%;
    aspect-ratio: 1;
    margin: 0 4%;
    background-color: var(--cl_blue);
    color: white;
    border: 1px solid white;
}

.client-tabDivInactive-span{
    background-color: white;
    color: black;
    border: 1px solid gray;
}

.client-tabDivInactive{
    background-color: transparent;
    color: black;
}

.client-tabDiv-rt{
    border-radius: 0 30px 30px 0;
    margin-right: 1px;
}

.client-tabDiv-md{
    border-radius: 0;
    margin-right: 0;
}

.add-client-layout:hover {
    background-color: var(--cl_blue);
    color: var(--cl_white);
    border: 0;
}

.search-input {
    height: 80%;
    width: 60%;
    background-color: var(--cl_white);
    border: 1px solid gray;
    margin: 0;
    padding: 0;
    border-radius: 5px 0px 0px 5px;
    padding-left: 15px;
    padding-right: 15px;
}

.search-input:focus {
    outline: none;
}

.search-btn, .search-btn-thinnerScreen {
    height: calc(75% + 2px) ;
    width: 40%;
    background-color: var(--cl_blue);
    border: 1px solid var(--cl_blue);
    border-radius: 0px 10px 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--cl_white);
    font-size: 16px;
    cursor: pointer;
}

.thinnerScreenSpl > p{
    display: block;
}
.thinnerScreenSpl > svg, .search-btn-thinnerScreen{
    display: none;
}

@media only screen and (max-width:1200px)  {
    
.thinnerScreenSpl > p{
    display: none;
}
.thinnerScreenSpl > svg, .search-btn-thinnerScreen{
    display: block;
}
.search-btn{
    display: none;
}
.search-btn-thinnerScreen{
    display: flex;
    width: 25%;
}
.client-tabDiv, .client-tabDivInactive, .client-tabDiv-rt, .client-tabDiv-md{
    display: flex;
    align-items: center;
    justify-content: center;
}
.client-tabDiv-span, .client-tabDivInactive-span{
    position: absolute;
    bottom: 100%;
    border-radius: 50% 50% 0 0;
}

}

/* ****************************** */
/* M E M B E R S    S T Y L E */
/* ****************************** */

.client-members-layout {
    height: 70vh;
    padding-left: 3%;
    padding-right: 4%;
    border-radius: 0px 0px 0px 45px;
}

.members-main-layout {
    margin-top: 1vh;
    display: grid;
    /* grid-template-columns: 33.33% 33.33% 33.33%; */
    grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
    column-gap: 1%;
}

.scrollbar-layout {
    height: 75vh;
    width: 80vw;
}

.single-member {
    height: 18vh;
    background-color: var(--cl_white);
    margin: 0.5vw 0;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    border-radius: 10px;
    min-width: 30%;
}

.single-member:hover {
    background-color: var(--cl_ivery);
    border-radius: 5px;
    margin: 0.5vw 0;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
}

.member-thumbnail-layout {
    height: 100%;
    max-width: 7vw;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    align-items: flex-start;
}

.member-thumb-img {
    height: 80px;
    width: 80px;
    resize: cover;
    font-size: 12px;
    border-radius: 10px;
    margin-top: 30%;
}

.member-detail-layout {
    height: 100%;
    width: 20vw;
    display: flex;
}

.member-detail-head {
    width: 8vw;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 10%;
}

.member-detail-head p {
    margin: 0;
    padding: 0;
    margin-top: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    color: var(--cl_blue);
    font-size: 14px;
}

.member-detail-data {
    width: 12vw;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 10%;
}

.member-detail-data p {
    margin: 0;
    padding: 0;
    margin-top: 4px;
    margin-left: 4px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
    word-break: break-all;
}




.add-client-heading {
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: var(--cl_grey);
    font-weight: 500;
    margin-bottom: 10px;
    margin-left: 5px;
}
.client-sales-layout {
    margin-left: 1vw;
    height: 40vh;
    width: 49vw;
    border: 2px solid var(--cl_light_grey);
    border-radius: 8px;
    background-color: var(--cl_white);
}

.client-stock-layout {
    margin-left: 1vw;
    height: 38vh;
    width: 49vw;
    border: 2px solid var(--cl_light_grey);
    border-radius: 8px;
    background-color: var(--cl_white);
}

.detail-top {
    height: 15vh;
    display: flex;
    border-radius: 10px;
}

.detail-forms-container {
    height: 59vh;
    display: flex;
}

    .detailed-forms-main {
        flex: 2.5;
    }

    .detailed-forms-actions {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 10px;
        justify-content: flex-end;
    }

        .detailed-form-btns {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }

.client-img-layout {
    width: 30%;
    background-color: #70707093;
    margin: 8px 4px 8px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.dummy-img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    background-color: white;
}

.client-img-capture {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.client-img {
    height: 13vh;
    width: 13vh;
    resize: cover;
    cursor: pointer;
    border-radius: 10px;
}


.client-map-layout {
    width: 70%;
    background-color: var(--cl_blue);
    margin: 8px 8px 8px 4px;
    border-radius : 10px;
}

.detail-center {
    height: 24vh;
    margin: 8px;
    margin-top: 30px;
    display: flex;  
}

.detail-data {
    height: 75%;
    width: 75%;
}

.data-single {
    display: flex;
    height: 4vh;
}

.detail-headings {
    width: 30%;
    color: var(--cl_blue);
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.detail-headings p {
    margin-left: 10px;
}



.detail-input-dot {
    width: 5%;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.detail-input-layout {
    width: 65%;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.detail-input-layout textarea{
    padding: 10px 0 0 10px;
}

.client-input {
    border: 0px;
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.562);
    width: 90%;
    font-size: 13px;
    background-color: #fff;
}

.client-input:focus {
    outline: none;
}

.detail-btn {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.client-add-btn {
    height: 35px;
    width: 90%;
    border-radius: 30px;
    background-color: #CAE8F3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
    cursor: pointer;
    color: var(--cl_grey);
}

.client-delete-btn {
    height: 35px;
    width: 90%;
    border-radius: 30px;
    background-color: #f3caca;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
    cursor: pointer;
    color: var(--cl_grey);
}

.client-add-btn:hover {
    background-color: #60c4e9;
    color: var(--cl_white);
}

.client-delete-btn:hover {
    background-color: #fc7c7c;
    color: var(--cl_white);
}
.client-detail-layout {
    height: 100%;
    width: 100%;
    border-radius: 10px;
}
.work-main-layout {
    height: 75%;
    width: 75%;
}







.team-empty-layout {
    font-size: 14px;
}

.map-modal-layout {
    height: 70vh;
    width: 75vw;
    background-color: #fff;
    border-radius: 10px;
    outline: none;
}

/* .search {
    position: absolute;
    left: 50%;
    margin-top: 10px;
    opacity: 0.9;
    transform: translateX(-50%);
    width: 80%;
    z-index: 100000;
  } */

  .search {
    position: absolute;
    left: 55%;
    margin-top: 10px;
    opacity: 0.9;
    transform: translateX(-50%);
    width: 80%;
    z-index: 100000;
  }

  .searchbar-input {
      height: 30px;
      width: 300px;
      border-radius: 5px;
      border: 1px solid var(--cl_blue);
      padding-left: 10px;
  }

  .searchbar-input:focus {
    outline: none;
    }
.gm-style-cc button,.gm-style-cc a,.gmnoprint{
    display: none !important;
}
    .map-choose-btn {
        height: 40px;
        width: 200px;
        background-color: var(--cl_white);
        font-size: 16px;
        border-radius: 8px;
        font-weight: bolder;
        position: absolute;
        z-index: 200000;
        left: 70%;
        bottom: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .map-choose-btn:hover {
        border: 2px solid var(--cl_blue);
    }

    .map-error-btn{
        background-color: red;
        color: white;
    }


/* ******************************************* */
/* C R E A T E    C L I E N T    S T Y L E */
/* ******************************************* */

.client-create-main-layout {
    height: 85vh;
    padding: 0px 4vw;
}

.client-main-head {
    font-size: 14px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
}

.client-crt-container {
    height: 78vh;
    /* background-color: #fff;
    border: 1px solid gray; */
    border-radius: 5px;
}

.client-crt-top {
    min-height: 15vh;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

    .client-crt-dp {
        width: 10vw;
        height: 10vw;
        background-color: rgb(185, 185, 185);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 5px;
    }

    .client-crt-dp-img {
        width: 9.5vw;
        height: 98%;
        border-radius: 10px;
    }

    .client-crt-map {
        width: 25vw;
        background-color: greenyellow;
        border-radius: 10px;
    }

    .selectBoxHead{
        background-color: var(--cl_blue);
        color: white;
        align-items: center;
    }
    .selectBoxHead select{
        margin-bottom: 0;
        color: white;
    }

    .selectBoxHead select > option{
        color: black;
    }

.client-crt-bottom {
    height: 60vh;
    display: flex;
}

    .client-crt-btm-lft-main {
        flex: 6;
        display: flex;
    }

    .client-crt-btm-lft-main input, .client-crt-btm-lft-main select{
        background-color: transparent;
    }

        .client-ctr-btm-lft {
            border: 5px solid var(--cl_ivery);
            border-radius: 10px;
            margin-right: 1%;
            background-color: var(--cl_light_grey);
            flex: 3;
        }

        .client-ctr-btm-lftSingle{
            border: 5px solid var(--cl_ivery);
            border-radius: 10px;
            margin-right: 1%;
            display: flex;
            flex-direction: row;
            background-color: var(--cl_light_grey);
        }

        .client-ctr-btm-cntr {
            flex: 3;
            border: 5px solid var(--cl_ivery);
            border-radius: 10px;
            margin-right: 1%;
            background-color: var(--cl_light_grey);
        }

        .client-ctr-btm-cntr input, .client-ctr-btm-cntr select{
            background-color: transparent;
        }

    .client-ctr-btm-rgt {
        flex: 2;
        margin-right: 10px;
    }

        .client-ctr-btm-rgt-label {
            font-size: 14px;
            margin: 0;
            padding: 0px;
            margin-top: 20px;
            color: grey;
        }

        .client-ctr-btm-rgt-form {
            height: 80px;
            background-color: rgb(190, 188, 188);
        }

        .client-ctr-input-sep {
            background-color: transparent;
            border: 0;
            height: 50px;
            padding: 0px 20px;
            width: 100%;
        }

        .client-ctr-btn {
            min-width: 30%;
            margin-top: 20px;

            height: 80px;
            background-color: var(--cl_blue);
            border: 0px;
            border-radius: 20px;
            font-size: 20px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .client-ctr-btn:hover {
            background-color: rgb(19, 76, 129);
        }
.textLineSkelton{
    width: 100%;
    background-color: blanchedalmond;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.textLineSkelton > span:first-child{
    width: 20%;
    height: .5rem;
    margin-bottom: 5%;
    background-color: white;
    border-radius: 5px;
}

.textLineSkelton > td{
    width: 12%;
    height: 1rem;
    background-color: white;
    border-radius: 5px;
    margin: 1% 0;
}

.textLineSkelton > span:last-child{
    width: 70%;
    height: .5rem;
    margin-bottom: 5%;
    background-color: white;
    border-radius: 5px;
}
.textLineSkelton{
    background: linear-gradient(to right,
        rgba(255, 255, 255, 0),
        rgba(0, 0, 0, 0.1) 50%,
        rgba(255, 255, 255, 0) 80%
        ),
        rgb(238, 238, 236);
    background-repeat: repeat-y;
    background-size: 20%;
    background-position: 0 0;
    animation: shine 2s linear infinite;
    /* border-radius: 10px;

    border: 1px solid red; */
}

.siteProfTitle{
    background-color: var(--cl_blue);
    border-radius: 30px;
    padding: .5%;
    margin-bottom: .5%;
}
.siteProfTitle > p{
    color:white;
    width: auto;
}

.siteProfTitle  select{
    align-self: center;
    height: 80%;
    padding: 0;
    margin: 0;
    pointer-events:all;
    background-color: transparent;
    color: white;
}

.siteProfTitle  option{
    color: black;
}

.industryModalDiv{
    display: flex;
    flex-direction: column;
    width: 30vw;
    height: 60vh;
    background-color: white;
    border-radius: 30px;
}
.industryModalDivHeader{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 90%;
    padding: 5%;
    color: var(--cl_blue);
    font-weight: 500;
    font-size: 1.3rem;
}
.industryModalList{
    height: 80%;
    width: 100%;
    font-size: 1rem;
}
.industryModalList table th, table td{
    font-size: 1rem;
    cursor: pointer;
}
.industryTypeFooter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 90%;
    padding: 1% 5%;
    margin-bottom: 5%;
    background-color: var(--cl_ivery);
}
.industryTypeFooter > input{
    height: 90%; 
    background-color: transparent;
    border: none;
    width: 60%;
    border-bottom: 1px solid grey;
}
.industryTypeAddBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: var(--cl_blue);
    color:white;
    padding: 2%;
    font-weight: 600;
    cursor: pointer;
}

@keyframes shine {
    to{
        background-position: 100% 0;
    }
}

@-webkit-keyframes shine {
    to{
        background-position: 100% 0;
    }
}