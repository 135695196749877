.sales-main-layout {
    padding: 10px 3vw;
    height: 76vh;
    display: flex;
    flex-direction: column;
}


.sales-home-list-layout {
    height: 50vh;
    background-color: white;
    margin-bottom: 10px;
}


.sales-excecutive-layout {
    height: 25vh;
    background-color: white;
    border-radius: 10px;
    font-size: 12px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.sales-table-layout {
    height: 49vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sales-table-body-container {
    flex: 10;
}

/* .sales-table-body-container::-webkit-scrollbar{
    width: 5px;
} */

.loadingTbody>tr {
    background: linear-gradient(to right,
            rgba(255, 255, 255, 0),
            rgba(0, 0, 0, 0.1) 50%,
            rgba(255, 255, 255, 0) 80%),
        rgb(238, 238, 236);
    background-repeat: repeat-y;
    background-size: 150px;
    background-position: 0 0;
    animation: shine 2s linear infinite;
    border-radius: 10px;
}

@keyframes shine {
    to {
        background-position: 100% 0;
    }
}

.emptyDivSchedule {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70%;
    background-color: whitesmoke;
}

.sales-table {
    width: 100%;
    height: 45vh;
}

.sales-table>thead {
    position: sticky;
    z-index: 2;
    position: -webkit-sticky;
    top: 0px;
    background-color: white !important;
}

.sales-table>td {
    background-color: white !important;
    opacity: 1;
}

.sales-table>tbody>tr>td {
    font-size: .9rem !important;
    height: 40px;
    min-height: 40px;
}

.table-head td {
    font-size: .9rem;
    font-weight: bold;
    border: 0.5px solid rgb(226, 226, 226);
    padding: 8px 0px;
}

.sales-table-footer {
    flex: 1;
    display: flex;
    background-color: rgb(226, 226, 226);
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    height: 5vh;
}

.indicatedDot {
    height: 10px;
    position: absolute;
    width: 10px;
    background-color: orange;
    border-radius: 50%;
    /* top: 50%; */
    top: 15px;
    left: 3px;
}

.sales-footer-page-label {
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
    margin-left: 4px;
    margin-right: 4px;
    padding: 5px 8px;
}

.sales-footer-buttons {
    color: black;
    border-radius: 5px;
    cursor: pointer;
}

.sales-footer-buttons:hover {
    background-color: gray;
}

.navbar-layout {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, 10%);
    padding: 0px 3vw;
}

.navbar-layout-back-btn {
    display: flex;
    justify-content: space-between;
    padding: 0px 3vw;
}

.navbar-button {
    height: 32px;
    min-width: 10%;
    /* width: 100%; */
    border-radius: 30px;
    border: 1px solid gray;
    background-color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.navbar-button:hover {
    border: 1px solid rgb(197, 197, 197);
    background-color: rgb(197, 197, 197);
}

.navbar-button-active {
    border: 1px solid var(--cl_blue);
    background-color: var(--cl_blue);
    color: white;
    pointer-events: none;
}

.table-head td {
    font-size: .9rem;
    font-weight: bold;
    border: 0.5px solid rgb(226, 226, 226);
    padding: 8px 0px;
}

.sales-table-footer {
    flex: 1;
    display: flex;
    background-color: rgb(226, 226, 226);
    justify-content: flex-end;
    align-items: center;
    padding: 0px 10px;
    height: 5vh;
}

.sales-footer-page-label {
    border-radius: 4px;
    background-color: #fff;
    font-size: .8rem;
    font-weight: bold;
    margin-left: 4px;
    margin-right: 4px;
    padding: 5px 8px;
}

.sales-footer-buttons {
    color: black;
    border-radius: 5px;
    cursor: pointer;
}

.sales-footer-buttons:hover {
    background-color: gray;
}





.quoteStatusButtonDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    height: 2vh;
}

.quoteStatusButton {
    width: 45%;
    min-height: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    margin: 0 2px;
    cursor: pointer;
}

.quoteStatusButton:hover {
    background-color: grey;
}

.quoteStatusAccept {
    background-color: var(--cl_green);
}

.quoteStatusReject {
    background-color: var(--cl_red);
}




.sales-qt-amnt-field {
    margin: 10px 0px 10px 30px;
    display: flex;
    align-items: center;
}

.salesAmntField {
    height: 3vh;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid var(--cl_blue);
}



.viewBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    border: 1px solid gray;
    background-color: #fff;
    padding: .1rem 1.2rem;
    font-size: .7rem;
    border-radius: 20px;
    cursor: pointer;
    align-self: center;
}

.viewBtn:hover {
    border: 1px solid #fff;
    background-color: gray;
    color: white;
}




.sales-exce-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sales-exce-head-sort {
    display: flex;
}

.sales-exce-head-sort-label {
    margin-bottom: 10px;
    margin-left: 20px;
    background-color: var(--cl_blue);
    border-radius: 20px;
    padding: 10px;
    color: white;
    display: flex;
    align-items: center;
}

.sales-exce-head-sort-label span {
    font-size: 12px;
    font-weight: 500;
}


.sales-exce-card-layout {
    display: flex;
}

.sales-exce-card {
    height: 18vh;
    background-color: rgb(226, 226, 226);
    margin: 0px 5px;
    border-radius: 10px;
    padding: 5px;
    display: flex;
}

.sales-exce-card-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: gray;
}

.sales-exce-img {
    width: 100px;
    /* height: 100px; */
    border-radius: 10px;
    padding: 5px 0px;
}

.sales-exce-card-right {
    flex: 2;
    width: 150px;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 15px;
}


.resendMailContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.resendMailContent input{
    padding : 1%;
    border-width: 0px;
    border-bottom-width: 2px;
}

.resendMailId {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 2vh;
    padding: 5px 20px;
    width: 90%;
    font-size: 16px;
    font-weight: 600;
}

.resendMailId>span>input {
    font-size: 1rem;
}

.resendMailIdBtns {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    margin-top: 2%;
    justify-content: flex-end;
}

.resendMailBody,
.resendMailSubject {
    width: 90%;
    padding: 20px;
    height: 25vh;
    margin-bottom: 10px;
}

.resendMailBody{
    height: 30vh;
}

.resendMailBodyTextArea{
    border: none;
    outline: none;
    padding: 1vh;
    border-radius: 10px;
    border: 1px solid rgb(164, 222, 240);
    background-color: rgba(164, 222, 240, 0.2);
    resize: vertical;
    font-family: inherit;
    width: 100%;
    height: 30vh;
}


.resend-emails-layout {
    display: grid;
    grid-template-columns: repeat(auto-fill, 10vw);
    margin-top: 1%;
    margin-bottom: 1%;
}

.resend-emails {
    background-color: rgba(77, 202, 224, 0.712);
    margin: 5px;
    border-radius: 20px;
    font-size: 12px;
    padding: 5px 10px;
    /* width: 98px; */
    width: fit-content;
    max-width: 8vw;
    min-height: 1vh;
    word-wrap: break-word;
    overflow: hidden;
    margin-right: 4px;
}

.resendMailSubject {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 2vh;
    padding-bottom: 5px;
    padding-top: 5px;
    margin: 0;
}

.reoccurModalLayout {
    background-color: white;
    border-radius: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.react-select-customStyle__indicator{
    padding: 0px 8px !important;
}