.login-layout{
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
     
}
.login-team-brand-layout {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.login-team-brand-img {
   height: 50px;
   padding: 0;
   margin: 0;
}

.login-form-layout{
    display: flex;
    height: 40vh;
    width: 25vw;
    padding: 10px 2px 20px 2px;
    border-radius: 35px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: black;
    color: white;
}

.login-head{
    padding: 20px 0 ;
    font-size: 30px;
    font-weight: 500;
}

.login-form-div{
    display: flex;
    width: 25vw;
    height: 30vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: white;
}

.login-form-section{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 50%;
}

.login-Username-input{
    height: 5vh;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--cl_blue);
}

.login-form-btn{
    background-color: var(--cl_blue);
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}



.newLoginLayout{
    display: flex;
    flex-direction: row;
}

.newLoginLayoutDiv1, .newLoginLayoutDiv2{
    width: 50%;
    height: 100vh;
}

.newLoginLayoutDiv1{
    display: grid;
    place-items: center;
}
.newLoginLayoutDiv2{
    width: 45%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.loginCredsScreen{
    padding-left: 3vw;
    width: 50%;
}

.newLoginPanelHead{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}


.newLoginPanelHead > p{
    font-weight: 400;
    font-size: 2.6rem;
}

.newLoginPanelHead > span{
    font-weight: 400;
    font-size: 1rem;
    color: lightgray;
}

.newLoginPanelForm{
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
}

.newLoginPanelForm > div{
    display: flex;
    flex-direction: row;
    border: 1px solid lightgray;
    border-radius: 10px;
    margin: 1vh 0;
    position: relative;
    height: 5vh;
    padding: 1vh 0;
}

.newLoginPanelForm > div > input{
    border: none;
    width: 100%;
}

.newLoginPanelForm > div > span{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.newLoginPanelForm > div > label {
    position: absolute;
    font-size: .6rem;
    left: 10%;
    color: lightgray;
}

.newLoginPanelFormsvg{
    font-size: .5rem;
}

.newLoginPanelBtnDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.newLoginPanelBtn{
    border-radius: 10px;
    color:white;
    background-color: var(--cl_blue);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2vh;
    width: 40%;
    cursor: pointer;
}

.newLoginPanelBtnDiv > p{
    color: red;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.loginTopBarLogo{
    padding: 1%;
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.loginFooterDiv{
    padding-left: 3vw;
    display: flex;
    flex-direction: column;
}

.loginFooterDiv > span{
    margin: 1% 0;
    color: lightgray;
}


.loginFooterDiv > p{
    color: var(--cl_blue);
}