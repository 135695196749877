.vehicle-header {
    height: 15vh;
    display: flex;
    align-items: center;
    padding-left: 6vh;
    padding-right: 6vh;
}

.table-details-truck-tbl > tbody > tr > td, .pre-inspc-table-layout > tbody > tr > td,
.table-details-fork > table > tbody > tr > td, .table-details-car > table > tbody > tr > td{
    font-size: .9rem !important;
    max-width: 15vw;
    word-break: break-all;
}
.table-details-truck-tbl{
    position: relative;
}
.table-details-truck-tbl thead{
    position: sticky;
    position: -webkit-sticky;
    top: 2px;
    background-color: white;
}

.table-details-truck-tblLoader > tbody > tr{
    background: linear-gradient(to right,
        rgba(255, 255, 255, 0),
        rgba(0, 0, 0, 0.1) 50%,
        rgba(255, 255, 255, 0) 80%
        ),
        rgb(238, 238, 236);
    background-repeat: repeat-y;
    background-size: 150px;
    background-position: 0 0;
    animation: shine 2s linear infinite;
    border-radius: 10px;
}
.table-details-truck-tblLoader > tr{
    background: linear-gradient(to right,
        rgba(255, 255, 255, 0),
        rgba(0, 0, 0, 0.1) 50%,
        rgba(255, 255, 255, 0) 80%
        ),
        rgb(238, 238, 236);
    background-repeat: repeat-y;
    background-size: 150px;
    background-position: 0 0;
    animation: shine 2s linear infinite;
    border-radius: 10px;
}
.skeleton{
    background: linear-gradient(to right,
        rgba(255, 255, 255, 0),
        rgba(0, 0, 0, 0.1) 50%,
        rgba(255, 255, 255, 0) 80%
        ),
        rgb(238, 238, 236);
    background-repeat: repeat-y;
    background-size: 150px;
    background-position: 0 0;
    animation: shine 2s linear infinite;
    /* border-radius: 10px; */
}
.skeleton > tr > td{
    background-color: none !important;
    height: 30px;
}
@keyframes shine {
    to{
        background-position: 100% 0;
    }
}

.table-details-car > table > thead > tr > th{
    font-size: 1rem;
}

.vehicle-brand-layout {
    height: 50px;
    width: 60%;
}

.vehicle-brand-img {
   height: 50px;
   padding: 0;
   margin: 0;
}
.header-back-btn {
    height: 35px;
    width: 80px;
    border: 1px solid gray;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: var(--cl_grey);
    cursor: pointer;
}

.header-back-btn:hover {
    color: var(--cl_white);
    background-color: var(--cl_grey);
    border: 0
}


/****************************************************************
            Vechile navigation design start from here
 ****************************************************************/
.vech-navigation-bar{
    width: 50%;
    max-height: 12vh;
}

.vech-navigation-main-layout {
    display: flex;
    padding-left: 4vw;
}

.siv-vech-select-btn{
    height: 3vh;
    /* min-width: 170px; */
    border: 1px solid gainsboro;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50%;
    font-weight: 700;
    background-color: white;
    color: rgb(2, 15, 58);
    cursor: pointer;
    outline: none;
    margin-right: 15px;
    margin-top: 1%;
    padding: 5px;
    text-decoration: none;
}
.siv-vech-select-btn:hover{
    color: var(--cl_white);
    background-color: rgb(2, 15, 58);
    border: 0  
}

.siv-vech-select-btn-active{
    background-color: black;
    color: white;
    border: 0 
}
.siv-vech-select-btn:active{
    border: 0    
}
.siv-vech-select-btn:focus{
    color: var(--cl_white);
    background-color: rgb(2, 15, 58);
    border: 0
}



.vehicle-back-btn-layout {
    height: 50px;
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.vechile-details-btn{
    height: 3vh;
    border: 1px solid gainsboro;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45%;
    font-weight: 500;
    background-color: white;
    color: black;
    cursor: pointer;
    outline: none;
    margin-right: 10px;
    margin-top: 10px; 
    /* padding: 1%;   */
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
}

.vechile-details-btn:hover{
    color: var(--cl_white);
    background-color: rgb(2, 15, 58);
    border: 0  
}

.vechile-details-btn-add {
    height: 3vh;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45%;
    font-weight: 500;
    background-color: black;
    color: white;
    cursor: pointer;
    outline: none;
    border: 0;
    margin-right: 10px;
    margin-top: 10px; 
    /* padding: 1%;   */
    padding: 5px 10px;
    align-self: center;
}

.vechile-details-btn-add:hover{
    background-color: grey;
    color: black;
}


.vehicle-back-btn2-layout {
    height: 50px;
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.vechile-details-btn2{
    height: 40px;
    width: 150px;
    border: 1px solid gray;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    background-color: white;
    color: black;
    cursor: pointer;
    outline: none;
    margin-left: 10px;
    margin-top: 10px; 
    padding: 5px;  
}
.vechile-details-btn2:hover{
    color: var(--cl_white);
    background-color: rgb(2, 15, 58);
    border: 0  
}




.vehicle-back-btn3-layout {
    height: 50px;
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.vechile-details-btn3{
    height: 40px;
    width: 110px;
    border: 1px solid gray;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    background-color: white;
    color: black;
    cursor: pointer;
    outline: none;
    margin-left: 10px;
    margin-top: 10px; 
    padding: 5px;  
}
.vechile-details-btn3:hover{
    color: var(--cl_white);
    background-color: rgb(2, 15, 58);
    border: 0  
}




.vechile-details-btn4{
    height: 40px;
    width: 110px;
    border: 1px solid gray;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    background-color: white;
    color: gray;
    cursor: pointer;
    outline: none;
    margin-left: 10px;
    margin-top: 10px; 
    padding: 5px;  
}
.vechile-details-btn1{
    height: 40px;
    width: 150px;
    border: 1px solid grey;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    background-color: white;
    color: gray;
    cursor: pointer;
    outline: none;
    margin-left: 10px;
    margin-top: 10px; 
    padding: 5px;  
}

.regi-vehc-btn{
    height: 35px;
    width: 120px;
    border: 1px solid gray;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    background-color: white;
    color: black;
    cursor: pointer;
    outline: none;
    margin-left: 20px;
    padding: 5px;  
}
.regi-vehc-btn:hover{
    color: var(--cl_white);
    background-color: rgb(2, 15, 58);
    border: 0  
}
/****************************************************************
            Vechile VehicleImages design start from here
 ****************************************************************/
 .tableImageHoverTile{
     position: absolute;
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
     background-color: #EDECEC;
     border-radius: 5px;
     top: 0;
     bottom: 0;
     cursor: pointer;
 }
.vehicleMultiImages{
    display: grid;
    padding: 10px 20px;
    grid-template-columns: 25% 25% 25% 25% ;
    /* grid-template-rows: 25%; */
    grid-row-gap: 2px;
    width: 30vw;
    height: 10vh;
    justify-content: center;
    align-items: center;
}

.vehicleSingleImageDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 20px;
}

.document-delete, .document-delete-single{
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    left: 90%;
    top:-10%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    color: black;
}

.document-delete-single{
    top:90%;
    width: 10vw;
    border: 1px solid var(--cl_blue);
    background-color: white;
    font-size: 20px;
    border-radius: 20px;
}

.document-delete-single :hover{
    background-color: var(--cl_blue);
    color: white;
    border-radius: 20px;

}

.document-delete-single > label > div{ 
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.Vehicle-multi-crt-dp, .Vehicle-single-dp-img{
    height: 5vh;
    width: 5vw;
    position: relative;
}

.Vehicle-multi-crt-dp-img{
    height: 5vh;
    width: 5vw; 
}

.Vehicle-single-dp-img{
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: transparent;
}

.Vehicle-multi-crt-dp:hover .document-delete{
    opacity: 1;
}


.VehicleImages{
    width:50% ;
    height:10vh ;
    display: flex;
    justify-content: space-between;
    margin-right: 3vw;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-end;
    align-items: flex-end;
}
.vehicle-number{
   min-width: 40%;
   height: 38px;
   border-radius: 30px;
   margin-left: 43%;
   font-size: 15px;
   display: flex;
   /* margin-top: 5%; */
   align-items: center;
   justify-content: space-between;
   padding: 0px 10px;
   border: 1px gray solid;
   background-color:white;
}
.Vehicle-input{
    border: none;
}
.vech-img-head{
    width:100%;
    height: 20%;
    display: flex;
}

.right-vech-detls{
  width: 50%;
  height: 100%; 
  display: flex; 
  align-items: flex-end;
}

.strong-rigid{
    font-size: 15px;
    font-weight: 50px;
    color: red;
    float: left;
    font-weight: 500;
    text-transform: capitalize;
}
.strong-rigid-details{
    font-size: 15px;
    font-weight: 50px;
    color: black;
    float: left;
    font-weight: 500;
    margin-left: 5px;
    text-transform: capitalize;
}
.left-vech-detls{
    width: 50%;
    height: 100%; 
    display: flex; 
    align-items: flex-end;
    text-align: justify;
    

  }
.left-vech-detls-left{
    display: flex;
    
  }
.engine-number{
    font-size: 15px;
    font-weight: 50px;
    color: red;
    font-weight: 500;
    text-transform: capitalize; 
    margin-left: 125px;   
}
.engine-number-details{
    font-size: 15px;
    font-weight: 50px;
    color: black; 
    font-weight: 500;
     
}
.vech-images-all{
    width: 100%;
    height: 80%;
    display: flex;
 }
 .vech-img1{
   width: 33%; 
   height: 90%; 
   background-color: whitesmoke;
   float: right;
   margin-right: 10px;
 }

.car-img1{
    width: 100%; 
    height: 100%; 
    border-radius: 5px;
}
 .vech-img2{
    width: 32%; 
    height: 100%; 
    background-color: whitesmoke;
    float: right;
    margin-left: 2%;
  }
  .vech-img3{
    width: 32%; 
    height: 100%; 
    background-color: whitesmoke;
    float: right;
    margin-left: 2%;
  }
/****************************************************************
            Vechile Truck-table design start from here
 ****************************************************************/
 .TruckListTable{
    width: 100%;
    max-height: 80vh;
    display: flex;
    justify-content: center;
}

.truck-table-list{
    width: 93%;
    height: 100%;
}
.table-details-truck{
    width: 100% ;
    height: 80%;
    overflow: hidden;
}
table{ 
    width: 100%;
    height: 100%;  
    border-collapse: separate;
    position: relative;
    border-spacing: .4rem .4rem;
} 
 th{
    width: max-content;
    height:15px ;
    color: rgb(2, 15, 58);
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    border:0.5px solid gainsboro;
    border-radius: 3px;
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
    /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
    background: white;
    } 
td{
    width: max-content;
    text-align: center;
    height:15px ;
    color: rgb(2, 15, 58);  
    font-size: 10px;
    font-weight: 500;
    
}
.add-new{
    height:15px ;
    background-color: #EDECEC;
}
.truck-table-back-previous-btn{
   width:100% ;
   height: 6vh;
   display: flex;
   justify-content: flex-end;
   align-items: center;
}

.pagination-btn-layout {
    width: 25vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.table-list-previous-btn{
    height: 25px;
    width: 80px;
    border: 1px solid rgb(2, 15, 58);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: 700;
    background-color: white;
    color: var(rgb(2, 15, 58));
    cursor: pointer;
    outline: none;
    padding: 5px;
    float: right; 
    margin-top: 10px;
}
.table-list-previous-btn:hover {
    color: var(--cl_white);
    background-color: var(--cl_grey);
    border: 0
}
.table-list-next-btn{
    height: 28px;
    padding: 12px;
    border: 1px solid grey;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 10px;
    background-color: white;
    color: var(rgb(2, 15, 58));
    cursor: pointer;
    outline: none;
    margin-left: 10px;
    margin-right: 10px;
}
.table-list-next-btn:hover {
    color: var(--cl_white);
    background-color: var(--cl_grey);
    border: 1px solid var(--cl_grey);
}

.table-list-next-btn2{
    height: 32px;
    width: 120px;
    border: 1px solid grey;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 10px;
    background-color: white;
    color: var(rgb(2, 15, 58));
    cursor: pointer;
    outline: none;
    margin-left: 10px;
    margin-right: 10px;
}
.table-list-next-btn2:hover {
    color: var(--cl_white);
    background-color: var(--cl_grey);
    border: 1px solid var(--cl_grey);
}
.page-no {
    height: 20px;
    width: 20px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid grey;
    display: flex;
    justify-content: center;
    align-items: center;
}
/**************************************
Truck Fleet Model Design 
***************************************/
.Model-fleet-layout{
    width: 60%;
    height:65%;
    border-radius: 10px;
    background: white; 
    outline: none;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.certificate-main-layout-fleet{
    display: inline-grid;
    grid-template-columns: auto auto auto;
}


.single-certificate-vehicle{
    border: 1px solid #c4c3c0;
    height: 40px;
    margin: 8px;
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    padding: 0px 15px;
    justify-content: space-between;
}

 /****************************************************************
            Vechile Car-table design start from here
 ****************************************************************/

.CarListTable{
    width: 100%;
    max-height: 80vh;
    display: flex;
    justify-content: center;   
}
.car-table-list{
    width: 93%;
    height: 100%;
    
}
.table-details-car{
    width: 100% ;
    max-height: 80%; 
    overflow: auto;
    overflow-x: hidden;   
   
}

.car-table-back-previous-btn{
   width:100% ;
   height: 20%; 
}
.car-table-list-previous-btn{
    height: 25px;
    width: 80px;
    border: 1px solid rgb(2, 15, 58);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: 700;
    background-color: white;
    color: var(rgb(2, 15, 58));
    cursor: pointer;
    outline: none;
    padding: 5px;
    float: right; 
    margin-top: 10px;
}
.car-table-list-previous-btn:hover {
    color: var(--cl_white);
    background-color: var(--cl_grey);
    border: 0
}
.car-table-list-next-btn{
    height: 25px;
    width: 50px;
    border: 1px solid rgb(2, 15, 58);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: 700;
    background-color: white;
    color: var(rgb(2, 15, 58));
    cursor: pointer;
    outline: none;
    padding: 5px; 
    float: right;
    margin-left: 10px;
    margin-top: 10px;
}
.car-table-list-next-btn:hover {
    color: var(--cl_white);
    background-color: var(--cl_grey);
    border: 0
}
 /****************************************************************
            Vechile ForkLift-table start from here
 ****************************************************************/
 .ForkListTable{
    width: 100%;
    max-height: 80vh;
    display: flex;
    justify-content: center;
}

.fork-table-list{
    width: 93%;
    height: 100%;
}
.table-details-fork{
    width: 100% ;
    height: 80%; 
    overflow: hidden;  
}
.fork-table-back-previous-btn{
   width:100% ;
   height: 20%; 
}
.fork-table-list-previous-btn{
    height: 25px;
    width: 80px;
    border: 1px solid rgb(2, 15, 58);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: 700;
    background-color: white;
    color: var(rgb(2, 15, 58));
    cursor: pointer;
    outline: none;
    padding: 5px;
    float: right; 
    margin-top: 10px;
}
.fork-table-list-previous-btn:hover {
    color: var(--cl_white);
    background-color: var(--cl_grey);
    border: 0
}
.fork-table-list-next-btn{
    height: 25px;
    width: 50px;
    border: 1px solid rgb(2, 15, 58);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: 700;
    background-color: white;
    color: var(rgb(2, 15, 58));
    cursor: pointer;
    outline: none;
    padding: 5px; 
    float: right;
    margin-left: 10px;
    margin-top: 10px;
}
.fork-table-list-next-btn:hover {
    color: var(--cl_white);
    background-color: var(--cl_grey);
    border: 0
}
 /****************************************************************
          Add new vehicle  design start from here
 ****************************************************************/
.team-edit-setting-layout{
    width: 65%;
    height: 60%;
    outline: none;
    padding: 5vh;
    background-color: var(--cl_white);
    border-radius: 15px;
}

.team-edit-setting-main{
    width: 100%;
    height: 100%;
}

.team-edit-title {
   font-size: 20px; 
   padding-left: 20px;
   margin-bottom: 20px;
}

.vehicle-add-img-title {
    margin: 0;
    padding: 0;
    font-size: 14px;
    padding-left: 10px;
}

.box-one-add-items{
    width: 100%;
    height: 50vh;
    display: flex;
}

.box-one-add-items-split-left{
    width: 50%;
    height: 100%;
}

.box-one-add-items-split-right{
    width: 50%;
    height: 100%;
}
.box-one-add-items-split-right-img{
    width: 100%;
    height: 40%;

}
.title-box-one-add-items-split-right-img{
    width: 100%;
    height: 10%;
}
.add-box-one-add-items-split-right-img{
    width: 100%;
    height: 90%;
    display: flex ;
    align-items: center;
    justify-content: center;
}
.select-imag-add-one{
    width: 33%;
    height: 140px;
    background-color: whitesmoke;
    margin: 5px;
}

.select-img-1{
    width: 100%; 
    height: 100%; 
    border-radius: 5px;
}

.select-imag-add-two{
    width: 30%;
    height: 90%;
    background-color: whitesmoke;
    margin: 5px;
}
.select-imag-add-three{
        width: 30%;
        height: 90%;
        background-color: whitesmoke;
        margin: 5px;
}
.box-one-add-items-split-right-content{
    width: 100%;
    height: 60%;
}


.box-two-add-items{
    margin-top: 20px;
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-file-mntnce > label{
    position: relative;
    cursor: pointer;
}


.add-file-mntnce > label > input[type='file']{
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.mntnce-fileList-grid{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10vw, 1fr));
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 20vh;
    background-color: whitesmoke;
}

.addFileInitial{
    display: flex;
    flex-direction: column;
    padding: 2rem;
}
.addFileNormal{
    justify-content: center;
    align-items: center;
    height: 100%;
}

::-webkit-scrollbar-thumb  {
    background: var(--cl_grey);
    border-radius: 10px;
}

.mntc-file-mini{
    width: 10vw;
    height: 8vh;
    margin-top: 1%;
}
 /****************************************************************
         PreInspectionCheck    design start from here
 ****************************************************************/



 .PreInspectionCheck{
    height: 85vh; 
    padding-left: 3vw;
    padding-right: 3vw; 
}
.pre-options-div-boxClose{
    display: none;
}
.pre-options-div-box{
    position: absolute;
    top: 25%;
    right: 68%;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    background-color: rgb(231, 231, 231);
    cursor: pointer;
    z-index: 10;
}
.pre-options-div-box > div{
    position: relative;
    z-index: 10;
}
.pre-options-div-box > div > div{
    padding: 2px;
    z-index: 10;
}

.pre-options-div-box > div:first-child{
    color: rgb(0, 172, 57) !important;
    /* background-color: red; */
}
.pre-options-div-box_triangle{
    height: 10px;
    width: 10px;
    background-color: rgb(231, 231, 231);
    position: absolute;
    right: -8px;
    transform: rotate(45deg);
    z-index: 2;
}
.pre-options-div-box > div > div:hover{
    background-color: white;
}

/* Sahad */
.pre-topbar {
    display: flex;
    justify-content: space-between;
}

.pre-box-1 {
    height: 20vh;
    background-color: white;
    display: flex;
    flex-direction: column;
}

    .pre-box-1-top {
        flex: 3;
        display: flex;
    }

        .pre-box-1-left {
            flex: 5;
            display: flex;
            flex-direction: column;
        }
            .pre-box-1-column {
                display: flex;
                flex: 1;
                border: 0.5px solid rgb(235, 234, 234);
                font-size: 14px;
                font-weight: bold;
            }

                .pre-box-1-clmn-head {
                    flex: 2;
                    background-color: rgb(235, 234, 234);;
                    display: flex;
                    align-items: center;
                    padding: 0px 10px 0px 10px;
                }

                .pre-box-1-clmn-body {
                    flex: 4;
                    display: flex;
                    align-items: center;
                    padding: 0px 10px 0px 10px;
                }

                .pre-box-1-select {
                    border: 0;
                    width: 100%;
                    background-color: transparent;
                    height: 40px;
                    margin-bottom: 0px;
                }
                .pre-box-1-select .pre-box-1-select_option{
                    height: 30px;

                }

                .pre-box-1-input {
                    border: 0;
                    width: 100%;
                    background-color: transparent;
                }

                .pre-box-1-select select:focus {
                    outline: none;
                }

                .pre-box-1-input input:focus {
                    outline: none;
                }

        .pre-box-1-right {
            flex: 5;
            display: flex;
            flex-direction: column;
        }

    .pre-box-1-bottom {
        flex: 1;
        border: 0.5px solid rgb(235, 234, 234);
        display: flex;
        font-size: 14px;
        align-items: center;
        font-weight: 400;
    }

        .pre-box-1-bottom-check {
            margin-left: 30px;
            display: flex;
            align-items: center;
            gap: 5px;
        }




.pre-box-2 {
    margin-top: 10px;
    border: 0.5px solid rgb(235, 234, 234);
    padding-bottom: 10px;
}


.pre-box-3 {
    height: 40vh;
    background-color: white;
    display: flex;
}


    .pre-box-3-row {
        flex: 2;
        border: 0.5px solid rgb(235, 234, 234);
        display: flex;
    }

        .pre-box-3-row-left {
            flex: 5;
            display: flex;
            flex-direction: column;
        }

            .pre-box-3-row-head {
                flex: 1.5;
                border: 0.5px solid rgb(235, 234, 234);
            }

            .pre-box-3-row-body {
                flex: 1;
                display: flex;
                align-items: center;
                font-size: 12px;
                font-weight: 500;
                border-right: 0.5px solid rgb(235, 234, 234);
                padding: 0px 12px 0px 12px;
            }

        .pre-box-3-row-right {
            flex: 2;
            display: flex;
            flex-direction: column;
        }

            .pre-box-3-row-right-head {
                flex: 1.5;
                border: 0.5px solid rgb(235, 234, 234);
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                background-color: rgb(235, 234, 234);
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .pre-box-3-row-right-body {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-weight: 500;
                border-right: 0.5px solid rgb(235, 234, 234);
                padding: 0px 12px 0px 12px;
                position: relative;
            }

.pre-box-3-2 {
    height: 10vh;
    border: 0.5px solid rgb(235, 234, 234);
    display: flex;
}

    .pre-box-3-2-left {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #0F99D0;
        text-align: center;
    }   

    .pre-box-3-2-right {
        flex: 5;
        display: flex;
        flex-direction: column;
    }  

        .pre-box-3-2-right-top {
            flex: 2;
            display: flex;
            align-items: center;
        }

            .pre-box-3-2-right-top-check {
                font-size: 14px;
                margin-left: 30px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;
            }

        .pre-box-3-2-right-bottom {
            flex: 2;
            display: flex;
            align-items: center;
        }



.pre-box-4 {
    height: 10vh;
    background-color: rgb(255, 255, 158);
    display: flex;
}   

    .pre-box-4-left {
        flex: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        padding: 0px 20px 0px 20px;
    }

    .pre-box-4-right {
        flex: 6;
        padding: 10px;
    }
        .pre-box-4-right textarea:focus {
            outline: none;
        }
        .pre-box-4-textarea {
            border: 0;
            background-color: transparent;
            width: 100%;
            height: 100%;
        }


.pre-box-5 {
    height: 20vh;
    background-color: white;
    display: flex;
    flex-direction: column;
}

    .pre-box-5-top {
        flex: 2;
        display: flex;
    }

        .pre-box-5-top-head {
            flex: 2;
            display: flex;
            align-items: center;
            padding-left: 20px;
            font-size: 14px;
            border: 0.5px solid rgb(235, 234, 234);
            font-weight: 500;

        }

    .pre-box-5-bottom {
        flex: 4;
        padding: 20px
    }

        .pre-box-5-top-body {
            font-size: 12px;
            font-weight: 500;
            padding: 5px;
            display: flex;
            align-items: center;
            gap: 5px;
        }

.pre-box-6 {
    height: 25vh;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}
    .pre-box-6-top {
        flex: 2;
        border: 0.5px solid rgb(235, 234, 234);
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding-left: 20px;
        gap: 5px;
    }

    .pre-box-6-center {
        flex: 4;
        border: 0.5px solid rgb(235, 234, 234);
        display: flex;
        font-size: 12px;
        font-weight: 500;
    }

        .pre-box-6-center-left {
            flex: 2;
            padding: 20px;
        }

        .pre-box-6-center-right {
            flex: 6;
            display: flex;
            flex-direction: column;
        }

            .pre-box-6-center-right-top {
                flex: 2;
                display: flex;
                align-items: center;
            }

            .pre-box-6-center-right-bottom {
                flex: 2;
                display: flex;
                align-items: center;
            }

    .pre-box-6-bottom {
        flex: 2;
        border: 0.5px solid rgb(235, 234, 234);
        display: flex;
        justify-content: center;
    }

    .pre-box-6-submit {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }

    .pre-box-submit-btn {
        height: 40px;
        width: 150px;
        border-radius: 30px;
        border: 1px solid rgb(75, 219, 255);
        background-color: #fff;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
    }

    .pre-box-submit-btn:hover {
        border: 0;
        background-color: rgb(75, 219, 255);
        color: #fff;
    }



.PreInspectionCheck-content{
    width: 90%;
    height: 100%;
    margin-left: 5%;

}
.PreInspectionCheck-content-head{
    width: 100%;
    height: 15%;
}
.PreInspectionCheck-content-head-truck{
    height: 40px;
    width: 170px;
    border: 1px solid gray;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    background-color: rgb(2, 15, 58);
    color: white;
    outline: none;
    padding: 4px;
}
.PreInspectionCheck-content-pre
{
    height: 40px;
    width: 170px;
    border: 1px solid gray;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    background-color: rgb(2, 15, 58);
    color: white;
    outline: none;
    padding: 4px;
    margin-left: 10px;
}
.Vehicle-safety-pre-installed-content{
    font-size: medium;
    color: rgb(2, 15, 58);
    font-weight: 700;
 }

 .PreInspectionCheck-content-head-list{
    height: 40px;
    width: 200px;
    border: 1px solid gray;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    background-color: white;
    color: rgb(2, 15, 58);
    outline: none;
    padding: 4px;   
    margin-left:270% ;
 }

 /************************************
   scroll items design start from here
  ************************************/
.PreInspectionCheck-content-body{
    width: 100%;
    height: 85%;
    background-color: whitesmoke;  
    overflow: scroll;  
    overflow-x: hidden;   
}
/******* PreInspectionCheck-box-one*******/
.PreInspectionCheck-box-one-item{
    width: 100%;
    height: 165px;
    background-color: white;
    display: flex;
    border-left:0.2px solid rgb(211, 211, 211);
    border-top:0.2px solid rgb(211, 211, 211);
}
.PreInspectionCheck-box-one-content-one{
    width: 100%;
    height: 80%;
    background-color: white; 
   
}
.PreInspectionCheck-box-one-content-one-left{
    width: 85%;
    height: 100%;   
}
.PreInspectionCheck-box-one-content-one-left-top{
    width:100% ;
    height: 80%;
    display: flex;
}
.PreInspectionCheck-box-one-content-one-left-top-one{
    width: 20%;
    height: 100%;
    background-color: #B2B2B2;
}
.div-row-one{
    width: 100%;
    height: 33%;  
    border: 0.1px solid rgb(133, 133, 133);
    border-top: none;
    border-left: none;
    font-size: 15px;
    font-weight: 600;
    padding: 0.5px;
    color:rgb(2, 15, 58) ; 
    
}
.div-row-two{
    width: 100%;
    height: 33%;
    font-size: 15px;
    font-weight: 600;
    padding: 0.5px;
    color:rgb(2, 15, 58) ; 
}
.div-row-three{
    width: 100%;
    height: 33%;
    border: 0.1px solid rgb(133, 133, 133);
    border-bottom: none;
    border-left: none;
    font-size: 15px;
    font-weight: 600;
    padding: 0.5px;
    color:rgb(2, 15, 58) ; 
}
.div-row-one-l{
    width: 100%;
    height: 33%;  
    border: 0.1px solid rgb(211, 211, 211);
    border-top: none;
    border-left: none;
    font-size: 15px;
    font-weight: 600;
    padding: 0.5px;
    color:red ; 
    
}

.div-row-three-l{
    width: 100%;
    height: 33%;
    border: 0.1px solid rgb(211, 211, 211);
    border-bottom: none;
    border-left: none;
    font-size: 15px;
    font-weight: 600;
    padding: 0.5px;
    
}
.sort-icon-det {
    padding: 0;
    margin: 0;
    float: right;
    margin-top: 10px;
}

.para-content{
    margin-left: 10px;
  
}

.PreInspectionCheck-box-one-content-one-left-top-two{
    width: 25%;
    height: 100%;
    background-color: white;
}
.PreInspectionCheck-box-one-content-one-left-top-three{
    width: 20%;
    height: 100%;
    background-color: #B2B2B2;
}
.PreInspectionCheck-box-one-content-one-left-top-four{
    width: 35%;
    height: 100%;
    background-color: white;
}

.PreInspectionCheck-box-one-content-one-left-bottom{
    width:100% ;
    height: 20%;
    font-weight: 500;
    font-size: 13px;
    color:rgb(2, 15, 58) ;  
    border-top: .1px solid rgb(211, 211, 211); 
    display: flex;
    align-items: center;
  
}
input[type=checkbox] .vehicle1{
    margin-left: 50px;
  }
.PreInspectionCheck-box-one-content-one-left-bottom-check1{
    width: 20%;
    margin-left: 10px;
   
}
.PreInspectionCheck-box-one-content-one-left-bottom-check2{
    width: 40%;
} 
.PreInspectionCheck-box-one-content-one-left-bottom-check3{
    width: 40%;
    
}
.PreInspectionCheck-box-two-item-p
{
    margin-left: 10px;
}
.PreInspectionCheck-box-one-content-one-right{
    width: 15%;
    height: 100%;
    background-color: white;   
    font-weight: 600;
    font-size: 15px;
    color:rgb(2, 15, 58) ;
    border-left: 0.001px solid rgb(211, 211, 211);
}
.Prestart-Report{
    width: 100%;
    height: 50%;
    font-weight: 600;
    font-size: 15px;
    border: none;
    color:rgb(2, 15, 58) ;      
    display: flex;
    align-items: center;
    justify-content: center; 
   

}
.Prestart-Report-no{
    width: 100%;
    height: 50%;
    font-weight: 400;
    font-size: 30px;
    color:red ;
    display: flex;
    align-items: center;
    justify-content: center; 
    
}
/******* PreInspectionCheck-box-two*******/
.PreInspectionCheck-box-two-item{
    width: 100%;
    height: 30px;
    font-weight: 500;
    font-size: 15px;
    color:rgb(2, 15, 58) ;  
    border-top: .1px solid rgb(211, 211, 211); 
    display: flex;
    align-items: center;
    
}

/******* PreInspectionCheck-box-three*******/
.PreInspectionCheck-box-three-item{
    width: 100%;
    height: 55px;
    font-size: 15px;
    font-weight: 500;
    border-top:0.2px solid rgb(211, 211, 211);
    border-left:0.2px solid rgb(211, 211, 211);
    color:rgb(2, 15, 58) ;
}
.PreInspectionCheck-box-two-item-three{
    margin-left: 10px;
    line-height: 0.5;
}
.redText 
{ 
    color: red; 
}
.redText-1 
{ 
    color: #0F99D0; 
}
/******* PreInspectionCheck-box-4*******/
.PreInspectionCheck-box-four-item{
    width: 100%;
    height: 300px;    
    font-size: medium;
    border: 0.5px solid  rgb(211, 211, 211);   
    color:rgb(2, 15, 58) ;    
}
.box-four-item-head{
   width: 100%;
   height: 20%;
   display: flex;
}
.box-four-item-head-1{
   width: 25%;
   height: 100%;
   background-color: white;
}
.box-four-item-head-2{
    width: 25%;
    height: 100%;
    background-color: rgb(249, 250, 249);
 }
 .box-four-item-head-3{
    width: 25%;
    height: 100%;
    background-color: white;
 }
 .box-four-item-head-4{
    width: 25%;
    height: 100%;
    background-color: rgb(255, 255, 255);
 }
 .box-four-item-head-1-right{
       float: right;
       background-color: #B2B2B2;
       height: 100%;
       width: 25%;
       font-size: small;
       font-weight: 600;
 }

.box-four-item-body{
    width: 100%;
    height: 80%;
    display: flex;
    border-top:0.5px solid  rgb(211, 211, 211);
    background-color: rgb(255, 255, 255);
}
.box-four-item-body-1{
    width: 25%;
    height: 100%;
    border-right:0.5px solid  rgb(211, 211, 211); 

}
.box-four-item-body-2{
    width: 25%;
    height: 100%;
    border-right:0.5px solid  rgb(211, 211, 211); 

}
.box-four-item-body-3{
    width: 25%;
    height: 100%;
    border-right:0.5px solid  rgb(211, 211, 211); 

}
.box-four-item-body-4{
    width: 25%;
    height: 100%;
    border-right:0.5px solid  rgb(211, 211, 211); 

}
.box-four-item-body-1-checkbox{
    width: 75%;
    height: 100%;
    display: block;
    border-right:0.5px solid  rgb(211, 211, 211); 
}
.check-box-table{
    display: flex;  
    padding-top:9px;
    width: 100%;
    height: 25px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    color:rgb(2, 15, 58) ; 
  }
  .check-box-table-left{
    width: 85%;
    height: 100%;
    float: left;
    padding-left: 10px;
    
}
  
  #red-checkbox-color{
    visibility: hidden;
  } 
 

 


/******* PreInspectionCheck-box-5*******/
.PreInspectionCheck-box-5-item{
    width: 100%;
    height: 70px; 
    background-color: white; 
    border-top:0.5px solid  rgb(211, 211, 211); 
    border-left:0.5px solid  rgb(211, 211, 211); 
    display: flex;
}
.PreInspectionCheck-box-5-item-right{
    width: 20%;
    height: 100%;
    font-size: medium;
    font-weight: 700;
    color: #0F99D0 ;;
}
.Accessories-and-Fittings{
   margin-left: 20px;
}
/******* PreInspectionCheck-box-6*******/
.PreInspectionCheck-box-6-item{
    width: 100%;
    height: 70px;
    background-color: #fffab1;  
    font-size: medium;
    border: 0.5px solid  rgb(211, 211, 211); 
    color:rgb(2, 15, 58) ; 
    font-weight: 500;     
}
.PreInspectionCheck-box-6-item-content{
    padding-left: 15px;
    line-height: 7px;
}
/******* PreInspectionCheck-box-7*******/
.PreInspectionCheck-box-7-item{
    width: 100%;
    height: 150px;
    background-color: white;  
    font-size: medium;
    border-left: 0.5px solid  rgb(211, 211, 211);   
}
.box-7-item-head{
    width: 100%;
    height: 30%;
    border-bottom: 0.5px solid  rgb(211, 211, 211); 
    display: flex;  
    
}
.box-7-item-head-left{
    width: 40%;
    height: 100%;
    border-right: 0.5px solid  rgb(211, 211, 211);
    font-size: medium;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
}
.box-7-item-head-right{
    width: 60%;
    height: 100%;
    font-size: medium;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
}
.Action-Taken{
    margin-left: 10px;
}
.box-7-item-body{
    width: 100%;
    height: 70%;
}
/******* PreInspectionCheck-box-8*******/
.PreInspectionCheck-box-8-item{
    width: 100%;
    height: 50px;  
    font-size: medium;
    border-top: 0.5px solid  rgb(211, 211, 211);
    color:red ; 
    font-size: medium;
    font-weight: 400;

}
/******* PreInspectionCheck-box-9*******/
.PreInspectionCheck-box-9-item{
    width: 100%;
    height: 150px;  
    background-color: white; 
    border: 0.5px solid  rgb(211, 211, 211);      
}
.box-9-item-1{
    width: 100%;
    height: 20%;
    border-bottom: 0.5px solid  rgb(211, 211, 211);
}
.box-9-item-2{
    width: 100%;
    height: 60%;
    border-bottom: 0.5px solid  rgb(211, 211, 211);
}
.box-9-item-3{
    width: 100%;
    height: 20%;
    display: flex;
}
.box-9-item-3-left{
    width: 30%;
    height: 100%;
    font-size: 15px;
    display: flex;
    align-items: center;
    border-right: 0.5px solid  rgb(211, 211, 211);
}
.box-9-item-3-middle{
    width: 30%;
    height: 100%;
    font-size: 15px;
    display: flex;
    align-items: center;
    border-right: 0.5px solid  rgb(211, 211, 211);
}
.box-9-item-3-right{
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 15px;
}
.box-9-item-margin{
    margin-left: 10px;
}
.input-type-class{
    border-left: none;
    border-right: none;
    border-top: none;
    outline: none;
    border-bottom: 0.5px solid  rgb(211, 211, 211);
}
/******* PreInspectionCheck-box-10*******/
.PreInspectionCheck-box-10-item{
    width: 100%;
    height: 150px;  
}


/*****************************************************************
           check-box design
******************************************************************/
.red-checkbox{
    width: 15%;
    height: 100%;
    float: right;   
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: burlywood;
}
  
  /* Create a custom checkbox */
  .checkmark {
    height: 14px;
    width: 14px;
    padding-left: 3px;
    padding-bottom: 3px;
    background-color: rgb(0, 172, 57);
    border: .5px solid grey;
    border-radius: 3px;
    margin-right: 2px;
  }
  .checkmark svg{
      transform: translateY(1px);
  }
  /* On mouse-over, add a grey background color */
  .checkmark:hover {
    opacity: 0.4;
  }

  .checkedBlmark{
    background-color: #0F99D0;
  }

  .checkedRedmark{
    background-color: red;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkedmark {
    background-color: #0F99D0;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  /* .checkmark:after {
    content: "";
    display: none;
  } */
  
  /* Show the checkmark when checked */
  /* .checkmark:after {
    display: block;
  } */
  
  /* Style the checkmark/indicator */

  /* .checkmark:after {
    
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  } */
  




/****************************************************************
           design start from here
 ****************************************************************/


 /****************************************************************
             design start from here
 ****************************************************************/




/* use sagar css styles start with ".sgr-" like
.sgr-maintance-rprt-layout {

}
*/
.sgr-fuel-rprt-layout {
    
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    padding-left: 2%;
    height: 75%;
    width: 90vw;
} 

.sgr-fuel-rprt-topbar{
    display: flex;
    flex-direction: row;
    justify-self: start;
    width:30%;
    font-size: .9em;
    padding-top: .5em;
    padding-bottom: 1em;
}

.sgr-fuel-rprt-topbarBtn{
    background-color: var(--cl_black);
    color: var(--cl_white);
    border:none;
    border-radius: 30px;
    padding: 1em 2em 1em 2em;
    margin-right: 1em;
}

.sgr-fuel-rprt-pageTitle{
    padding:.9em;
}

.sgr-fuel-rprt-pageContent{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 80%;
}

.sgr-fuel-rprt-Table{
    color: var(--cl_black);
    padding:.2vh;
    width:80%;
    text-align: center;
}

.sgr-fuel-rprt-Table > table {
    border-collapse: collapse;
    border-spacing: .7rem .5rem;
    width:100%;
    table-layout: auto;
    
}
.sgr-fuel-rprt-TableHeads > td {
    padding:.3vh .5vh .3vh .5vh;
    text-align: center;
    font-size: .9rem;
}
.sgr-fuel-rprt-TableHeads > td > div{
    border-radius: 2px;
    background-color: var(--cl_white);
    border: .5px solid var(--cl_black);
    color:var(--cl_black);
    padding:1vh;
}

.sgr-fuel-rprt-tbAddnew:hover{
    background-color: var(--cl_black);
    color: var(--cl_white);
}


.sgr-fuel-rprt-Table > table > tr{
    color:var(--cl_black);
    font-size: .7rem;
}

.sgr-fuel-rprt-Table td:last-child{
    background-color: var(--cl_ivery);
}

.sgr-fuel-rprt-tb{
    width:100% ;
    height: 10%;    
}

.sgr-fuel-rprt-TableAction{
    padding:5% 1% 2% 1%;
    width:25%;
}
.sgr-fuel-rprt-TableActionBox{
    display: flex;
    background-color: var(--cl_white);
    border-radius: 3px;
    border:var(--cl_black);
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
    padding: 10% 1% 5% 1%;
    width:100%;
}
.sgr-fuel-rprt-Title{
    text-align: center;
    padding: 2%;
    font-weight: 700;
}
.sgr-fuel-rprt-detail{
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-around;
    padding: 1%;
    width: 100%;
}
.sgr-fuel-rprt-Date{
    border:.5px solid var(--cl_black);
    border-radius: 30px;
    padding: .5em 1em .5em 1em;
    background-color: var(--cl_white);
    color: var(--cl_black);
    height: 100%;
    text-align: center;
}

.sgr-fuel-rprt-tbTitle{
    padding: 4%;
    text-align: right;
}

.sgr-fuel-rprt-Export{
    border:.5px solid var(--cl_black);
    border-radius: 30px;
    padding: .5em 1em .5em 1em;
    background-color: var(--cl_white);
    color: var(--cl_black);
    width: 50%;
    font-weight: 700;
}

.sgr-fuel-rprt-Export:hover{
    background-color: var(--cl_black);
    color:var(--cl_white);
}

.sgr-fuel-rprt-action{
    width: 100%;
    padding: .5em 0 .5em 1em;
    font-size: .8rem;
    display: flex;
    justify-content: center;
}



.sgr-fuel-main-layout {
    margin-top: 20px;
    height: 72vh;
    width: 100%;
    display: flex;
}

.sgr-fuel-hd-title {
    margin: 0;
    padding: 0;
    margin-left: 8px;
    margin-bottom: 8px;
    font-size: 13px;
    font-weight: 600;
}

.sgr-fuel-tablez-layout {
    flex: 6;
}
.sgr-fuel-tablez-layout tr{
 height: 40px;
}
.sgr-fuel-tablez-layout tr > td{
    height: 40px;
   }
.sgr-fuel-actions-layout {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sgr-fuel-actions-box {
    height: 40%;
    width: 70%;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    font-size: 14px;
}

.sgr-fuel-action-title {
    font-size: 14px;
    color: grey;
    margin-bottom: 20px;
}

.sgr-fuel-action-forms {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 40px;
    font-size: 12px;
    
    text-align: center;
}

.sgr-fuel-date {
    flex: 2;
}

.sgr-fuel-date-input {
    flex: 4;
}

.sgr-fuel-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}

/*Maintenance CSS*/

.sgr-mntnce-rprt-layout{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 5vh;
    padding-right: 5vh;
}

.sgr-mntnce-rprt-topbar{
    display: flex;
    text-align: center;
}

.sgr-fleet-rprt-topbar{
    display: flex;
}



.sgr-fleet-titles {
    flex: 5;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.sgr-fleet-titles p {
    font-size: 14px;
    padding: 0;
    margin: 0;
    padding-right: 20px;
    padding-left: 10px;
}

.sgr-fleet-sortby {
    flex: 1;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}



.sgr-mntnce-rprt-topbarBtn{
    background-color: var(--cl_black);
    color: var(--cl_white);
    border:none;
    border-radius: 30px;
    padding: .5em 1em .5em 1em;
    margin-right: 1em;
}

.sgr-mntnce-rprt-topbarBtn2 {
    background: var(--cl_black);
    color: var(--cl_white);
    height: 35px;
    padding: 15px;
    border-radius: 30px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    align-self: center;
}


.sgr-mntnce-rprt-search-layout{
    border: .5px solid gainsboro;
    border-radius: 30px;
    padding: .5em 1em .5em 1em;
    margin-right: 1em;
    font-size: 12px;
    width: 300px;
    display: flex;
    align-items: center;
}

.search-icon {
    color: gray;
    cursor: pointer;
}

.search-icon:hover {
    color: black;
}

.sgr-mntnce-rprt-search{
    border: 0;
    padding: .5em 1em .5em 1em;
    font-size: 12px;
    width: 90%;
    background: transparent;
}

.sgr-maintanace-export-btn-layout{
    margin-top: 20px;
    width:100% ;
    height: 9vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
 }
 


.truck-maintance-tbl-list{
    width: 100%;
    height: 100%;
    margin-top: 20px;
}

.table-action-field {
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-action-btn {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    color: black;
    text-decoration: none;
}

.table-action-btn:hover {
    background-color: gainsboro;
    cursor: pointer;
    border-radius: 5px;
}

.sgr-mntnce-rprt-addNew, .sgr-mntnce-rprt-addNew2{
    border: .5px solid gainsboro;
    border-radius: 30px;
    background-color: var(--cl_white);
    font-size: 12px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
}


.sgr-mntnce-rprt-addNew:hover{
    border:none;
    background-color: var(--cl_black);
    color: var(--cl_white);
}

.mntnce-addNew-icon{
    padding:0;
    margin: 0 0 0 10px;
    position: absolute;
    z-index: 1;
}

.sgr-mntnce-rprt-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width:95%;
    height: 80%;
}

.sgr-mntnce-rprt-tb{
    width: 100%;
    height: 100%;  
    border-collapse: separate;
    border-spacing: .4rem .4rem;
    
}

.sgr-mntnce-rprt-tbHead{
    width: max-content;
    height:30px ;
    color: rgb(2, 15, 58);
    text-align: center;
    font-size: .9rem;
    border:0.5px solid  gray;
    border-radius: 3px;
}

.sgr-mntnce-rprt-tb > tr {
    font-size: 14px;
}

.sgr-mntnce-rprt-tb > tr > td{
    width: max-content;
    text-align: center;
    height:30px ;
    color: rgb(2, 15, 58);  
    
}
.sgr-mntnce-add-new{
    height:25px ;
    background-color: #bdbdbd;
}

.mntnce-addDesc-icon{
    padding:0;
    margin: 0 0 0 70%;
}

.sgr-mntnce-rprt-tbAction{
    width: 100%;
    height: 10%;
}

.sgr-mntnce-rprt-footer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: .8rem;
    padding:2%;
    height:10%;
}

.sgr-mntnce-rprt-comment{
    display: flex;
    flex-direction: column;
    border: .5px solid var(--cl_ivery);
    border-radius: 3px;
    margin-right: 25%;
    padding:1%;
    
}

.sgr-mntnce-rprt-comment > span{
    font-size: .9rem;
    color: var(--cl_red);
    font-weight: 700;
}

.sgr-mntnce-rprt-comment-content{
    overflow-y: scroll;
    max-height: 14vh;
}

.sgr-mntnce-rprt-action{
    width: 100%;
    padding: .5% 0 .5% 0;
    font-size: .8rem;
    display: flex;
    float: right;
    flex-direction: column;
    justify-content: flex-end;
}

.sgr-mntnce-rprt-Export, .sgr-mntnce-rprt-GoToReports{
    border:.5px solid var(--cl_black);
    border-radius: 30px;
    padding: .5em 1em .5em 1em;
    background-color: var(--cl_white);
    color: var(--cl_black);
    width: 10vw;
    font-weight: 700;
    margin-bottom: 4%;
    position: relative;
}

.sgr-mntnce-rprt-Export:hover, .sgr-mntnce-rprt-GoToReports:hover{
    background-color: var(--cl_black);
    color:var(--cl_white);
}

.mntnce-GTRfrwdicon{
    position: absolute;
    z-index: 1;
    top:.5vh;
}


.mntnce-addNew-Modal-layout {
    width: 50vw;
    outline: none;
    padding: 5vh;
    background-color: var(--cl_white);
    border-radius: 15px;
    font-size: .9rem;
}

.mntnce-addNew-Modal-main {
    height: 90%;
    width: 97%;
    background-color: var(--cl_white);
    border-radius: 5px;
    
}

.mntnce-addNew-Modal-noraml {
    height: 20px;
    display: flex;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 25px;
    padding-bottom: 10px;
    align-items: center;
}

.mntnce-addNew-Modal-title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    color:  var(--cl_grey);
}

.mntnce-addNew-Modal-submit-layout {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.mntnce-addNew-Modal-submit-btn {
    height: 38px;
    width: 140px;
    border-radius: 5px;
    border: 1px solid var(--cl_blue);
    background-color: var(--cl_white);
    color: var(--cl_blue);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}



.mntnce-addNew-Modal-submit-btn:hover {
    border: 1px solid var(--cl_blue);
    background-color: var(--cl_blue);
    color: var(--cl_white);
}

.mntnce-addNew-Modal-edit-left {
    flex: 8;
    text-transform: uppercase;
}
.mntnce-addNew-Modal-edit-center {
    flex: 1;
}
.mntnce-addNew-Modal-edit-right {
    flex: 11;
    width: 100%;
}

select:focus {
    outline: none;
}




.modal-display-content{
    display: flex;
}

.mntnce-modal-input {
    width: 70%;
    border: 0;
    border-bottom: 1px solid gainsboro;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
}

.modal-disPart1, .modal-dis-Part2 {
    width: 100%;
}

.modal-dis-Part2 {
    margin-left: 20px;
}

button:focus {
    outline: none;
}

input:focus {
    outline: none;
}

/* ********************************************* */
/************ PRE INSPECTION LAYOUT *************/
/* ********************************************* */

.pre-inspection-list-layout {
    height: 80vh;
}

.pre-inspection-list-top-layout {
    padding: 0px 3vw;
    display: flex;
}

.pre-inspc-add-btn {
    height: 40px;
    padding: 0px 25px;
    background-color: #fff;
    border: 1px solid grey;
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pre-inspc-add-btn:hover {
    background-color: gray;
    border: 1px solid grey;
    color: #fff;
    transition: cubic-bezier();
}


.pre-inspc-default-btn {
    height: 40px;
    padding: 0px 25px;
    background-color: black;
    border: 1px solid black;
    color: #fff;
    border-radius: 30px;
    margin: 0px 10px;
    cursor: pointer;
}

.pre-inspc-default-btn-add {
    height: 40px;
    padding: 0px 25px;
    border: 1px solid black;
    color: #fff;
    border-radius: 30px;
    margin: 0px 10px;
}

.pre-inspc-list-main {
    height: 74vh;
    padding: 10px 2vw;
}

.pre-inspc-table-layout {
    height: 100%;
}

.pre-inspc-table-layout th {
    height: 40px;
    font-size: 1rem;
}

.pre-inspc-table-layout tr {
   height: 50px;
}

.heading-truck-table th{
    height: 40px;
    font-size: 1rem;
    max-width: 15vw;
}

.heading-fork-table > th{
    font-size: 1rem;
    max-width: 5vw;
}