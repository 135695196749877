.team-header {
    height: 15vh;
    display: flex;
    align-items: center;
    padding-left: 6vh;
    padding-right: 6vh;
}

.team-data-check{
    position: absolute;
    right: -5%;
    bottom: 1%;
}

.team-brand-layout {
    height: 80px;
    width: 60%;
    display: flex;
    align-items: center;
}

.team-brand-layout > img{
    height: 100%;
}

.team-brand-img {
   height: 50px;
   padding: 0;
   margin: 0;
}

.team-back-btn-layout {
    height: 50px;
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;
}

.header-back-btn {
    height: 35px;
    width: 80px;
    border: 1px solid gray;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: var(--cl_grey);
    cursor: pointer;
}

.header-back-btn:hover {
    color: var(--cl_white);
    background-color: var(--cl_grey);
    border: 0
}
.header-back-btn-enviro{
    height: 33px;
    width: 150px;
    border: 1px solid gray;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    color: var(--cl_grey);
    cursor: pointer;  
    margin-right: 14px;
}

.header-back-btn-enviro:hover {
    color: var(--cl_white);
    background-color: var(--cl_grey);
    border: 0
}
/***********************
  MEMBERS LAYOUT DESIGN
***********************/
.team-main-layout {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.team-members-layout {
    width: 65%;
    height: 82vh;
    font-size: 14px;
    padding-left: 5vh;
}
.team-members-layout-main{
    width: 95%;
    height: 100%;
   
}
.team-members-layout-top{
    width: 100%;
    height: 55%;
    border-radius: 5px;
    border:solid 0.5px rgb(214, 214, 214);
    background-color: white;
}
.team-members-layout-top1{
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(211, 211, 211);
}
.employe-details{
    margin-left: 15px; 
}
.Search-an-employe{
    width: 20%;
    height: 70%;
    background-color: white;
    border-radius: 30px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Search-an-employe-input{
     width: 70%; 
     border: none;
  }
.team-members-layout-top2{
    width: 100%;
    height: 85%;
    overflow: hidden;
}
.team-members-main-layout {
    display: grid;
    background-color:white;
    grid-template-columns: repeat(5, auto);
}

.team-single-layout {
    height: 16vh;
    width: 10vw;
    margin: 0.5vw;
    cursor: pointer;
    text-decoration: none;
    border: 1px solid transparent;
}

/* .team-single-layout:hover {
    border: 1px solid var(--cl_blue);
} */
.team-single-layout:hover .team-img{
    -webkit-box-shadow: 0px 0px 32px 6px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 32px 6px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 32px 6px rgba(0,0,0,0.1);
}

.team-img-layout {
    height: 80%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-img {
    height: 80%;
    width: 60%;
    border-radius: 10px;
    resize: cover;
    object-fit: cover;
}

.team-titles {
    height: 40%;
    width: 100%;
}

.team-name {
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 85%;
    font-weight: bold;
    color: var(--cl_blue);
    word-break: break-all;
}

.team-designation {
    padding: 0;
    margin: 0;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    color: var(--cl_grey);
}

.team-members-layout-bottom{
    width: 100%;
    margin-top: 2%;
    max-height: 43%;
    border-radius: 5px;
    border:solid 0.5px rgb(214, 214, 214);
    background-color: white;
}
.team-members-layout-top21{
    width: 100%;
    height: 85%;
    overflow: hidden;
}
.certificate-main-layout-team {
    /* width: 95%;
    margin: 2.5%;
    display: inline-grid;
    grid-template-columns: auto auto auto auto ; */

    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 160px);
    padding: 15px;
}
.single-certificate-team {
    border: 1px solid #c4c3c0;
    height: 50px;
    margin: 8px 0px;
    width: 160px;
    border-radius: 5px;
    display: flex;
    text-decoration: none;
}

.main-folder-card {
    height: 42px;
    width: 160px;
    border-radius: 8px;
    border: 1px solid rgb(221, 221, 221);
    cursor: pointer;
    padding: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: gray;
    text-decoration: none;
}

.main-folder-card:hover {
    background-color: rgb(221, 221, 221);
    color: rgb(82, 81, 81);
}


.certificate-logo-layout-team {
    width: 30%;
    display: flex;
    align-items: center;
}

.certificate-logo {
    height: 40px;
    margin-left: 10px;
}

.certificate-view-layout-team {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    cursor: pointer;
    font-size: 10px;
    color: gray;
}
/***********************
  TEAM SETTING LAYOUT DESIGN
***********************/


.team-settings-layout {
    width: 30vw;
    height: 83vh;
    padding-right: 1vh;
    position: relative;

}

.team-setting-main-title {
    padding: 0;
    margin: 0;
    font-size: 14px;
    color: var(--cl_grey);
    padding-top: 5px;
    margin-left: 10px;

}

.teamDpSetting{
    position: absolute;
    z-index: 999;
    right: 10%;
    top: -30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.team-setting-main {
    width: 97%;
    background-color:white;
    border-radius: 5px;
    padding-bottom: 5px;
}

.team-setting-header {
    height: 6vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.settings-add-head {
    height: 35px;
    width: 80px;
    border-radius: 30px;
    border: 1px solid var(--cl_grey);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: var(--cl_grey);
    margin-right: 10px;
}


.team-data-left {
    width: 35%;
    font-size: 85%;
   
}
.team-data-center {
    width: 5%;
}
.team-data-right {
    width: 60%;
    display: flex;
    position: relative;
}
.team-data-driving-licence{
    width: 30px;
    height: 90%;
    font-size: 10px;
}


.team-input {
    border: 0;
    padding: 0;
    margin: 0;
    color: var(--cl_grey);
    font-size: 14px;
    font-weight: 500;
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 5px;
    border-bottom: 1px solid var(--cl_ivery);
}

.team-data-option {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: var(--cl_white);
    font-size: 14px;
    border: 0;
    color: var(--cl_grey);
    font-weight: 500;
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 5px;
    border-bottom: 1px solid var(--cl_ivery);
}

.team-input:focus {
    outline: none;
}

.team-data-option:focus {
    outline: none;
}

.team-input-id:focus {
    outline: none;
}

.team-data-noraml {
    height: 20px;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 25px;
    align-items: center;
    
}

.team-credential-layout {
    /* height: 80px; */
    background-color: var(--cl_ivery);   
    margin: 20px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    padding: 10px;
    padding-bottom: 20px;
}

.team-form-cred {
    margin-top: 0px;
}

.team-credential-form {
    margin-top: 15px;
    height: 15px;
    display: flex;
}

.team-credential-left {
    width: 35%;
    height: 20px;
    display: flex;
    align-items: flex-end;
    color: var(--cl_blue);
}

.team-credential-right {
    width: 65%;
}

.credential-input {
    width: 100%;
    border: 0;
    font-size: 14px;
    background-color: var(--cl_ivery);
    border-bottom: 1px solid grey;
}

.credential-input:focus {
    outline: none;
}

.team-add-submit-layout {
    display: flex;
    justify-content: center;
}

.team-add-btn {
    height: 35px;
    margin-top: 6px;
    width: 130px;
    border-radius: 5px;
    background-color: var(--cl_blue);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: var(--cl_white);
    cursor: pointer;
}

.team-add-btn:hover {
    border: 1px solid var(--cl_blue);
    background-color: var(--cl_white);
    color: var(--cl_blue);
}


/* ****************************** */
/* I N N E R   P A G E    S T Y L E */
/* ****************************** */

.team-detail-layout {
   height: 83vh;
   padding-left: 6vh;
   padding-right: 6vh;
}

.team-cover-img-layout {
    height: 10vh;
    display: flex;
    justify-content: flex-end;
}

.team-cover-img {
    height: 10vh;
    width: 95%;
    resize: cover;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.team-detailed-main-layout {
    height: 73vh;
    display: flex;
}
.team-detail-main-layout-left{
    width: 30%;
}
.team-detail-left-empolyee{
    width: 22vw;
    margin: 8px;
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    
}
.team-detail-left-empolyee1{
    width: max-content;
    min-width: 5vw;
    height: 8vh;
    margin-top: 5px;
}
.handling-dangerous-goods{
    width: 95%;
    height: 30px;
    border-radius: 30px;
    font-size: small;
    font-weight: 500;
    display: flex;
    margin-left: 2.5%;
    align-items: center;
    justify-content: center;
    background-color: gainsboro;
}
.handling-dangerous-goods-p{
    width: 95%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: var(--cl_blue);
}
.team-detail-left-empolyee2{
    width: 40%;
    height: 90%;
    margin-top: 5px;
}
/***********************
   Employeedetails component styles
************************/
.team-detail-left {
    width: 95%;
    margin: 8px;
    height: 65%;
    overflow: hidden;
    border-radius: 5px;
    background-color: white;
}

.team-detail-img-layout {
    height: 15vh;
    width: 15vh;
    border-radius: 60px;
    background-color: var(--cl_light_ivery);
    position: absolute;
    margin-top: -11.2vh;
    margin-left: -1vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    background-color: aqua;
}

@media only screen and (max-width:1200px) {
    .team-detail-img-layout{
        height: 12vh;
        width: 12vh;
    }
}

.team-detail-img {
    height: 100%;
    width: 100%;
    border-radius: 55px;
    object-fit: cover;
    position: relative;
}

.team-capture-layout {
    height: 36px;
    width: 36px;
    border-radius: 18px;
    background-color: var(--cl_ivery);
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #AAA6A6;
    cursor: pointer;
}

.team-capture-layout:hover {
    background-color: var(--cl_blue);
    color: var(--cl_white);
}

.team-detail-actions {
    height: 40px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.team-edit-btn {
    height: 25px;
    width: 60px;
    border-radius: 30px;
    border: 1px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    margin-right: 10px;
    cursor: pointer;
}

.team-edit-btn:hover {
    background-color: var(--cl_blue);
    color: var(--cl_white);
    border: 0;
}

.team-detail-title {
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
}

.team-main-title {
    color: var(--cl_blue);
    font-size: 22px;
    margin: 0;
    padding: 0;
    font-weight: 600;
}

.team-main-designation {
    color: var(--cl_black);
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-weight: 500;
}

.team-msg-btn-layout {
    display: flex;
    margin-top: 15px;
}

.team-msg-btn {
    height: 35px;
    width: 140px;
    border-radius: 5px;
    background-color: var(--cl_white);
    font-size: 14px;
    color: var(--cl_blue);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--cl_blue);
    cursor: pointer;
}

.team-msg-btn:hover {
    background-color: var(--cl_blue);
    color: var(--cl_white);
}


.team-detail-datas {
    height: 40vh;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 12px;
    font-weight: 500;
    color: var(--cl_grey);
}

.team-main-data {
    display: flex;
    margin-top: 10px;
}
.team-main-data-left {
    width: 40%;
    text-transform: capitalize;
}

.team-main-data-center {
    width: 10%;
}

.team-main-data-right {
    width: 55%;
    word-break: normal;
}

.team-detail-about {
    height: 160px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    color: var(--cl_grey);
    text-align: justify;
}

.Search-an-employeTabDiv{
    width: 18vw;
    height: 70%;
    background-color: white;
    border-radius: 30px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.empDetTabsL, .empDetTabsR{
    background-color: var(--cl_blue);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    border-radius: 0 20px 20px 0;
    font-size: 90%;
    color: white;
    text-align: center;
}

.empDetTabsInactive{
    background-color: var(--cl_ivery);
    color: black !important;
}

.empDetTabsL{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    border-radius: 20px 0 0 20px;
}

/***********************
   TEAM FOLDER LAYOUT/ certificate component styles
************************/
.team-detail-right {
    width: 72%;
}
.team-certificate-layout {
    height: 55%;
    margin-top: 8px;
    border-radius: 5px;
    font-size: 14px;
    background-color: white;
}

.certificate-header {
    height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    background-color: gainsboro;
    justify-content: space-between;
    align-items: center;
}

.certificate-head-layout {
    width: 60%;
    display: flex;
    align-items: center;
}

.certify-title {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-left: 10px;
    color: var(--cl_grey);
}

.certificate-action-layout {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.certificate-upload-btn {
    height: 25px;
    margin-right: 10px;
    width: 60px;
    border-radius: 30px;
    border: 1px solid grey;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: var(--cl_black);
    cursor: pointer;
}

.certificate-upload-btn:hover {
   background-color: var(--cl_blue);
   color: var(--cl_white);
   border: 0;
}

.certificate-list-layout {
    height: 100%;
    margin: 10px;
    overflow: hidden;
}

.certificate-main-layout {
    width: 100%;
    display: inline-grid;
    grid-template-columns: auto auto auto auto;
}

.single-certificate {
    border: 1px solid #c4c3c0;
    height: 50px;
    margin: 0.3vw;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.certificate-logo-layout {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.certificate-logo {
    height: 40px;
    margin-left: 10px;
}

.certificate-view-layout {
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: flex-start;
    padding-right: 10px;
    overflow: hidden;
    cursor: pointer;
    font-size: 12px;
}

.certificate-btn {
    height: 25px;
    margin-right: 10px;
    width: 60px;
    border-radius: 30px;
    border: 1px solid grey;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: var(--cl_grey);
    cursor: pointer;
}

.certificate-btn:hover {
    background-color: var(--cl_grey);
    color: var(--cl_white);
    border: 0;
 }

.team-activity-layout {
    height: 40%;
    margin-top: 8px;
    background-color: var(--cl_white);
    border-radius: 5px;
}

.team-edit-modal {
    outline: 'none'
}

.team-edit-modal-layout {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: 'none'
}

.team-edit-main {
    height: 70vh;
    width: 70vw;
}

.team-edit-settings-layout {
    width: 40vw;
    padding: 5vh;
    outline: none;
    background-color: var(--cl_white);
    border-radius: 15px;
}


.team-cerificate-modal-layout {
    width: 60vw;
    height: 60vh;
    padding: 5vh;
    outline: none;
    background-color: var(--cl_white);
    border-radius: 15px;
}

.cer-modal-header-layout {
    height: 5vh;
    display: flex;
    align-items: center;
}

.cer-modal-main-title {
    font-size: 20px;
    font-weight: bold;
    color: grey;
    margin-left: 12px;
}

.cer-modal-body-layout {
    height: 45vh;
}

.cer-main-file-layout {
    /* width: 100%; */
    display: inline-grid;
    grid-template-columns: auto auto auto auto;
}

.cer-single-file-certificate {
    border: 1px solid #c4c3c0;
    height: 70px;
    margin: 0.5vw;
    border-radius: 5px;
    cursor: pointer;
}

.cer-single-file-certificate:hover {
    background-color: rgb(231, 230, 230);
}

.cer-single-body {
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center ;
    color: grey;
}

.cer-single-header {
    height: 40%;
    width: 11.2vw;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgb(184, 184, 184);
    font-size: 14px;
}

.cer-test {
    display: block;
}

.cer-text {
    overflow: hidden;
    height: 15px;
    width: 11.2vw;
    color: black
}

.cer-modal-footer-layout {
    height: 10vh;
    display: flex;
    justify-content: space-between;
    padding-left: 2vh;
    font-size: 14px;
}

.cer-modal-footer-layout-inner {
    display: flex;
    align-items: flex-end;
    padding-left: 2vh;
    font-size: 14px;
}

.cer-footer-left {
    height: 30px;
    display: flex;
    align-items: center;
}

.cer-footer-left-file {
    display: flex;
    height: 30px;
    align-items: center;
}

.cer-file-single {
    height: 30px;
    width: 30px;
    border: 1px solid gray;
    border-radius: 2px;
    margin: 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cer-file-remove {
    position: absolute;
    cursor: pointer;
    
}

.cer-modal-footer-title {
    padding: 0;
    margin: 0;
}

.cer-footer-right {
    border: 1px solid grey;
    border-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    cursor: pointer;
}

.cer-footer-right:hover { 
    background: rgb(218, 218, 218);
}

.team-edit-setting-main {
    height: 65vh;
    width: 97%;
    background-color: var(--cl_white);
    border-radius: 5px;
    
}

.team-edit-title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    color:  var(--cl_grey);
}

.team-edit-submit-layout {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.team-edit-submit-btn {
    height: 38px;
    width: 140px;
    border-radius: 5px;
    background-color: var(--cl_blue);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: var(--cl_white);
    cursor: pointer;
}

.team-edit-submit-btn:hover {
    border: 1px solid var(--cl_blue);
    background-color: var(--cl_white);
    color: var(--cl_blue);
}

.team-data-edit-left {
    width: 30%;
}
.team-data-edit-center {
    width: 10%;
}
.team-data-edit-right {
    width: 60%;
}
/******************
   Inner page
*******************/
.team-detail-layout-inner {
    height: 83vh;
    padding-left: 6vh;
    padding-right: 6vh;
 }
.team-detail-layout-top{
    width: 99%;
    height:10%;
    display: flex;
    align-items: center;
}
.team-detail-layout-inner-top{
    width: 15%;
    height: 32px;
    border-radius: 6px;
    border: 1px solid gray;
    background-color: white;
    font-size: small;
    display: flex;
    align-items: center;
    color: gray;
    cursor: pointer;
}
.team-detail-layout-inner-search{
    width: 30%;
    height: 32px;
    border-radius: 30px;
    border: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    color: gray;
    margin-left: 30px;
}
.team-inner-search{
    border: none;
    width: 60%;
}
.team-detail-layout-inner-back{
    width: 8%;
    height: 32px;
    border-radius: 30px;
    margin-left: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
    background-color: white;
    cursor: pointer;
    font-size: 14px;
    color: rgb(0, 0, 65);
}
.team-detail-layout-inner-back:hover{
   background-color: rgb(218, 218, 218);
}
.team-detail-layout-bottom{
    width: 100%;
    /* height:85%; */
    height: 70vh;
    margin-top: 20px;
    border: 1px solid rgb(202, 202, 202);
    background-color: white;
}

.team-detail-layout-bottom-top{
    width: 100%;
    height: 10%;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    color:  black;
}

.team-inner-txt-color-blue {
    color:  #0F99D0;
    font-weight: bold;
}

.team-work-table-title1{
    width: 10%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    
}
.team-work-table-title2{
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.team-work-table-title2-nw{
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.team-work-table-title3{
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    
}
.team-work-table-title4{
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
   
}
.team-work-table-title5{
    width: 20%;
    height: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
   
}
.team-work-table-title6{
    width: 15%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.team-work-table-title7{
    width: 15%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.team-detail-layout-bottom-content{
    width: 100%;
    height: 10%;
    display: flex;
    font-size: small;
    color:  red;
  
}
.team-work-table-title2-details{
    width: 15%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}



/*****************************
  TEAM NOTIFICATION
******************************/
.notification-team{
    width: 70%;
    height: 25px;
    margin: 10px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: gray;
}
.checkicon-team{
    width: 6%;
    color: green;
}

.refetchPermsModal{
    width: 30%;
    height: 30%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.refetchPermsTitle{
    height: 30%;
    width: 100%;
    text-align: center;
    color: white;
    font-weight: 600;
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
    background-color: var(--cl_blue);
    padding: 2% 0;
    border-radius: 10px 10px 0 0;
}

.refetchPermsContent{
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    width: 100%;
}

.refetchPermsContent > div{
    width: 10%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--cl_blue);
    color: white;
    border-radius: 5px;
}